import { Component, OnInit, ErrorHandler } from '@angular/core';

import * as fromRoot from './../../../../reducers';
import { Store } from '@ngrx/store';
import { INotification } from './../../../../reducers/notification';
import { IMyProfileStatus } from "app/modules/organisation/models";
import { IUserPrincipal } from './../../../identity/identity.models'

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
    notification: INotification;

    private userPrincipal: IUserPrincipal;
    private myProfileStatus: IMyProfileStatus;

    constructor(private store: Store<fromRoot.IState>) {
        this.store.select(fromRoot.getNotification).subscribe(notification => this.notification = notification);
        this.store.select(fromRoot.getUserPrincipal).subscribe(principal => this.userPrincipal = principal);
        this.store.select(fromRoot.getMyProfileStatus).subscribe(myProfileStatus => this.myProfileStatus = myProfileStatus);
    }

    ngOnInit() {
        if (this.notification && this.notification.message && this.notification.type == 'ALERT') {
            console.error(`Error reported: `, this.notification)
            // console.error(this.notification)
        }
    }

}
