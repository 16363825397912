/// <reference types="@types/googlemaps" />
import { Component, EventEmitter, Input, Output, NgZone, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn } from "@angular/forms";
import { MapsAPILoader } from '@agm/core';

import { IAddress } from './address.models'

@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
    public latitude: number;
    public longitude: number;
    public zoom: number;

    @Input() address: IAddress;

    @ViewChild("search", {static: false})
    public searchElementRef: ElementRef;

    @Input() isReadOnly: boolean = false;
    @Input() public controlName: string;
    @Input() public formGroup: FormGroup;
    @Input() public validationMessages: any;
    @Input() public formErrors: any;
    @Input() public formModel: any;
    @Input() public errorMessage: string;
    @Output() changed = new EventEmitter<any>();

    constructor(private mapsAPILoader: MapsAPILoader, private ngZone: NgZone) { }

    ngOnInit() {
        this.formModel[this.controlName] = new FormControl(this.address.formattedAddress, [
            Validators.required
        ]);

        this.validationMessages[this.controlName] = {
            'required': this.errorMessage
        };

        this.formErrors[this.controlName] = '';
        this.formGroup.addControl(this.controlName, this.formModel[this.controlName]);

        // Set google maps defaults
        this.zoom = 4;
        this.latitude = -25.2744;
        this.longitude = 133.7751;

        // Set current position
        this.setCurrentPosition();

        // Load Places Autocomplete
        this.mapsAPILoader
            .load()
            .then(() => {
                let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
                    types: ["address"]
                });
                autocomplete.addListener("place_changed", () => {
                    this.ngZone.run(() => {
                        //get the place result
                        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

                        //verify result
                        if (place.geometry === undefined || place.geometry === null) {
                            return;
                        }

                        //set latitude, longitude and zoom
                        this.latitude = place.geometry.location.lat();
                        this.longitude = place.geometry.location.lng();
                        this.zoom = 12;

                        this.address.formattedAddress = place.formatted_address;
                        this.address.unit = null;
                        this.address.addressLine1 = null;
                        this.address.addressLine2 = null;
                        this.address.suburb = null;
                        this.address.state = null;
                        this.address.postcode = null;
                        this.address.country = null;

                        for (var i = 0; i < place.address_components.length; i++) {
                            var addressType = place.address_components[i].types[0];
                            switch (addressType) {
                                case "subpremise":
                                    this.address.unit = place.address_components[i]["short_name"];
                                    break;
                                case "street_number":
                                    this.address.addressLine1 = place.address_components[i]["short_name"];
                                    break;
                                case "route":
                                    this.address.addressLine2 = place.address_components[i]["long_name"];
                                    break;
                                case "locality":
                                    this.address.suburb = place.address_components[i]["long_name"];
                                    break;
                                case "administrative_area_level_1":
                                    this.address.state = place.address_components[i]["short_name"];
                                    break;
                                case "postal_code":
                                    this.address.postcode = place.address_components[i]["long_name"];
                                    break;
                                case "country":
                                    this.address.country = place.address_components[i]["short_name"];
                                    break;
                            }
                        }
                    });
                });
            })
            .catch(() => {
                console.warn('Unable to initialise the Google Maps loader.');
            });
    }

    private setCurrentPosition() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.latitude = position.coords.latitude;
                this.longitude = position.coords.longitude;
                this.zoom = 12;
            }, (error) => {
                console.warn(`Unable to obtain the current position from the browser: ${error.message || "No error information provided"}`);
            });
        }
    }

    public componentChanged() {
        this.address.formattedAddress = this.rebuildFormattedAddress();
        this.onChanged();
    }

    public onChanged() {
        this.changed.emit();
    }

    private rebuildFormattedAddress(): string {
        let result: string = "";

        function appendValue(value, separator: string = '', prefix: string = '') {
            var hasValue = !!value && !!value.trim();
            if (hasValue) {
                result += `${prefix}${value}${separator}`;
            }
        }

        appendValue(this.address.unit, ', ', 'Unit ');
        appendValue(this.address.addressLine1, ' ');
        appendValue(this.address.addressLine2, ', ');
        appendValue(this.address.suburb, ', ');
        appendValue(this.address.state, ' ');
        appendValue(this.address.postcode);
        result = result.trim();
        if (result.endsWith(','))
            result = result.substring(0, result.length - 1);
        return result;
    }
}
