import { Component, OnInit, Input, ViewChild } from "@angular/core";

import { INavTabItem } from "./../../../controls/models";
import { ISearchRequest } from "../../services/search-request";
import { recordEntity } from "../../models";
import { SortOrder } from "../../services/sort-criteria";
import { SearchRegisterComponent } from "app/modules/search/components/register-components/search-register/search-register.component";
import { SearchService } from "app/modules/search/services/search.service";
import { AuthService } from "app/modules/identity/services/auth.service";
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})

// this structure is a bit ugly because it was
// never designed to have the search input and the results separated,
// but no plan survives contact with the enemy and requirements have come up.
// the version of ng-bootstrap we have available is so ancient it's
// component for tabs doesn't support tab body and
// navigation controls *not* being co-located in the markup structure.

// app-search-register is the main output and searchService is the main
// driver for the lot of it. Components have been setup to be reactive(ish) to
// changes to searchService.
export class RegisterComponent {
  public searchResult: any;
  public isSearching: boolean = true;
  public isBreachSearch: boolean = false;
  public active = 0;


  public navTabItems: INavTabItem[] = [
    {
      label: "All",
      badge: null,
      target: "all",
      icon: null,
      altIcon: null,
    },
    {
      label: "Business / Trading Names",
      badge: null,
      target: "organisation",
      icon: null,
      altIcon: null,
    },
    {
      label: "Lobbyists",
      badge: null,
      target: "lobbyist",
      icon: null,
      altIcon: null,
    },
    {
      label: "Clients",
      badge: null,
      target: "client",
      icon: null,
      altIcon: null,
    },
    {
      label: "Deregistered Organisations",
      badge: null,
      target: "deregistered",
      icon: null,
      altIcon: null,
    },
  ];

  constructor(public searchService: SearchService, public authService: AuthService) {
  }

  changeEntity(entity: string) {
    if (this.searchService.isLoading) return;
    // block duplicate searches and special cases for deregistered
    // -> deregistered and orgs using the same entity type
    // -> is a real pain in the ass.
    if (
      entity !== this.searchService.searchRequest.entity ||
      (entity == "deregistered" &&
        this.searchService.searchRequest.isDeregistered === false) ||
      (entity === recordEntity["organisation"] &&
        this.searchService.searchRequest.isDeregistered === true)
    ) {
      if (entity !== "deregistered") {
        this.searchService.searchRequest.entity = recordEntity[entity];
        this.searchService.searchRequest.isDeregistered = false;
      } else {
        this.searchService.searchRequest.entity = recordEntity["organisation"];
        this.searchService.searchRequest.isDeregistered = true;
      }
      this.searchService.doSearch();
    }
  }
}
