import moment = require("moment");

export class UtilityFunctions {
  public static minStatDecDateCalculation(): moment.Moment {
    const thisYear = new Date().getFullYear();

    const today = moment(new Date());

    const magicDate = today.isSameOrAfter(moment(`${thisYear}/07/01`))
      ? moment(`${thisYear}/06/30`)
      : moment(`${thisYear - 1}/06/30`);

    // subtract 9 if 30 June is weekday else 10

    const is30JuneAWeekDay =
      magicDate.isoWeekday() !== 6 && magicDate.isoWeekday() !== 7;
    return is30JuneAWeekDay
      ? this.subtractWeekdays(magicDate, 9)
      : this.subtractWeekdays(magicDate, 10);
  }

  public static subtractWeekdays(date: moment.Moment, days): moment.Moment {
    date = date.clone(); // use a clone
    while (days > 0) {
      date = date.subtract(1, "days");
      // decrease "days" only if it's a weekday.
      if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
        days -= 1;
      }
    }
    return date;
  }
}
