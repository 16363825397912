// these guys are an array of strings with format
// joyrideStep@route/wherever
// e.g. stepTagId@path/wherever

// it'll work if you don't specify the current route
// but you'll get some weirdness navigating
// back and forth if an element exists on both
// views

// substeps are object lookups to try and keep the
// summoning code less craptacular
// not typesafe but yeehaw and/or yolo

export const TutorialDashIntroSteps = [
  "welcomeBeginStep@user/dashboard",
  "welcomeUsefulStep@user/dashboard",
  "welcomeHelpStep@user/dashboard",
  "welcomeMenuStep@user/dashboard",
];

export const TutorialDashExistingSteps = [
  "welcomeManageStep@user/dashboard",
  "welcomeReviewStep@user/dashboard",
  "welcomeUsefulStep@user/dashboard",
  "welcomeHelpStep@user/dashboard",
  "welcomeMenuStep@user/dashboard",
];

export const TutorialIntroSteps = [
  "wizardListStep@user/form",
  "portalHeaderStep@user/form",
  "backButtonStep@user/form",
  "nextButtonStep@user/form",
];

export const TutorialSoleTraderSteps = [
  "soleTraderStep@user/form",
  "saveAndCloseStep@user/form",
  "saveStep@user/form",
  "greenTickStep@user/form",
];

export const TutorialOrgDetailsSubsteps = {
  "Business details": [
    "orgDetailsHeadingStep@user/form",
    "orgDetailsRequiredStep@user/form",
    "cancelStep@user/form",
  ],
  Contact: ["subStepStep@user/form", "greenTickStep@user/form"],
  "Business address": ["subStepStep@user/form", "greenTickStep@user/form"],
  "Postal address": ["subStepStep@user/form", "greenTickStep@user/form"],
};

export const TutorialResponOfficerSubsteps = {
  "Officers list": ["roListTableStep@user/form", "roListStatusStep@user/form"],
  "Add officer/s": ["roAddTitleStep@user/form", "roAddEmailStep@user/form"],
  "Officers overview": [
    "roOverviewTableStep@user/form",
    "greenTickStep@user/form",
  ],
};

export const TutorialOwnersSubsteps = {
  "Owners list": [
    "ownerListTableStep@user/form",
    "ownerListStatusStep@user/form",
  ],
  "Owner type": ["ownerTypeStep@user/form"],
  "Add owner/s": ["ownerAddStep@user/form"],
  "Owners overview": ["ownerOverviewStep@user/form", "greenTickStep@user/form"],
};

export const TutorialLobbyistSubSteps = {
  "Lobbyists list": [
    "lobbyistListAddStep@user/form",
    "lobbyistsListNoClientsStep@user/form",
  ],
  "Add lobbyist/s": [
    "addLobbyistNameEmailStep@user/form",
    "addLobbyistStatDecStep@user/form",
  ],
  "Former details": ["lobbyistFormerRepStep@user/form"],
  "Lobbyists overview": [
    "lobbyistOverviewStep@user/form",
    "greenTickStep@user/form",
  ],
};

export const TutorialCleintSubSteps = {
  "Clients list": ["clientsListNoClientsStep@user/form"],
  "Add client/s": ["addClientNameStep@user/form"],
  "Clients overview": [
    "clientOverviewStep@user/form",
    "greenTickStep@user/form",
  ],
};
export const TutorialReviewSteps = ["reviewSubmitButtonStep@user/form"];
