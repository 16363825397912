import { FormGroup } from "@angular/forms";

export class FormUtils {
    public static validateFormGroup(formGroup: FormGroup, emit: boolean = false){
        Object.keys(formGroup.controls).map((controlName) => {
            let control = formGroup.get(controlName);
            control.markAsDirty({onlySelf: true});
            control.markAsTouched({onlySelf: true});
            if (emit) {
                control.setValue(control.value, { emit: true });
                control.updateValueAndValidity({ onlySelf: true, emitEvent: true });
            } else {
                control.updateValueAndValidity();
            }            
        });
    }
}