import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ControlsModule } from './../controls/controls.module';

import { ContentService } from './services/content.service'

import { HomePageComponent } from './components/home-page/home-page.component';
import { ContentComponent } from './components/content/content.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { DefaultComponent } from './components/default/default.component';

import { OrganisationModule } from './../organisation/organisation.module';
import { NewsComponent } from './components/news/news.component';
import { ContactComponent } from './components/contact/contact.component';
import { AttachmentComponent } from './components/attachment/attachment.component';
import { FaqsComponent } from './components/faqs/faqs.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ControlsModule
    ],
    exports: [
        HomePageComponent,
        ContentComponent,
        AttachmentComponent
    ],
    providers: [
        ContentService
    ],
    declarations: [
        HomePageComponent,
        ContentComponent,
        PageNotFoundComponent,
        DefaultComponent,
        NewsComponent,
        ContactComponent,
        AttachmentComponent,
        FaqsComponent
    ]
})
export class ContentModule { }
