import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn } from "@angular/forms";
import 'rxjs/add/operator/catch';
import { AbnService } from '../../services/abn.service';
import { IAbnResult } from '../../models';

@Component({
    selector: 'app-abn-input',
    templateUrl: './abn-input.component.html',
    styleUrls: ['./abn-input.component.scss']
})
export class AbnInputComponent implements OnInit {

    private initialAbnDisabledState?: boolean = null;
    @Input() public helpText: string;
    @Input() public model: IAbnResult | IAbnResult;
    @Input() public isAbnReadOnly: boolean = false;
    @Input() public isAbnRequired: boolean = false;
    @Input() public set isAbnDisabled(isDisabled: boolean) {
        if (this.formModel && this.formModel.abn) {
            (this.formModel.abn as FormControl)[isDisabled ? 'disable' : 'enable']();
        } else {
            this.initialAbnDisabledState = isDisabled;
        }
    };
    @Input() public showLabels: boolean = true;

    @Input() public formGroup: FormGroup;
    @Input() validationMessages: any;
    @Input() public formErrors: any;
    @Input() public formModel: any;

    @Output() public ready = new EventEmitter<boolean>();
    @Input() timeoutAfter: number = 5000;
    @Output() public timeout = new EventEmitter<boolean>();
    @Output() changed = new EventEmitter<any>();

    private previousAbn: string = '';

    constructor(private abnService: AbnService) { }

    ngOnInit() {
        this.previousAbn = this.model.abn;
        let abnValidators = [Validators.maxLength(11), Validators.minLength(11)];
        if (this.isAbnRequired) {
            abnValidators.push(Validators.required);
        }
        this.formModel.abn = new FormControl(this.model.abn, abnValidators);
        if (this.initialAbnDisabledState !== null) {
            this.isAbnDisabled = this.initialAbnDisabledState;
        }
        this.formModel.legalName = new FormControl(this.model.legalName, [Validators.required]);
        this.validationMessages = {
            abn: {
                required: 'The ABN is required and must be 11 digits in length',
                maxlength: 'The ABN is required and must be 11 digits in length',
                minlength: 'The ABN is required and must be 11 digits in length',
                pattern: 'Please enter a valid ABN (without spaces), e.g., 51824753556'
            },
            legalName: {
                required: 'The business entity name is required'
            }
        };
        this.formErrors["abn"] = '';
        this.formErrors["legalName"] = '';

        this.formGroup.valueChanges.subscribe(data => this.onValueChanged(data));
        this.formGroup.addControl("abn", this.formModel.abn);
        this.formGroup.addControl("legalName", this.formModel.legalName);
    }

    onValueChanged(data?: any) {
        for (const field of ["abn", "legalName"]) {
            // clear previous error message (if any)
            this.formErrors[field] = '';
            const control = this.formGroup.get(field);
            if (control && control.dirty && !control.valid) {
                const messages = this.validationMessages[field];
                for (const key in control.errors) {
                    this.formErrors[field] += messages[key] + ' ';
                }
            }
        }
    }

    public onAbnBlur(event) {
        if (this.previousAbn != this.model.abn) {
            if (this.abnService.isValidAbn(this.model.abn)) {
                this.previousAbn = this.model.abn;
                this.ready.emit(false);
                var timer = this.timeoutAfter ? setTimeout(() => { // Time out after 5 seconds.
                    if (timer) {
                        timer = null;
                        this.timeout.emit();
                    }
                }, this.timeoutAfter) : null;
                this.abnService.lookup(this.model.abn)
                    .subscribe(abnResult => {
                        if (timer) {
                            clearTimeout(timer);
                            timer = null;
                        }
                        this.model.abn = abnResult.abn;
                        this.model.legalName = abnResult.legalName || this.model.legalName;
                        this.model.tradingName = abnResult.tradingName || this.model.tradingName;
                        this.ready.emit(true);
                    });
            }
        }
    }

    public onChanged() {
        this.changed.emit();
    }
}
