import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormGroup, FormArray, AbstractControl } from "@angular/forms";
import { Subject } from "rxjs";
import { GoogleAnalyticsService } from "../../../../../services/google-analytics.service";
import {
  IFormSubstep,
  RegistrationSubsteps,
} from "app/modules/organisation/models";

@Component({
  selector: "app-step-clients",
  templateUrl: "./step-clients.component.html",
  styleUrls: ["./step-clients.component.scss"],
})
export class StepClientsComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() form: FormGroup;
  @Input() clientsAdd: VoidFunction;
  @Input() clientsRemove: (clientId: string) => void;
  @Input() onSaved: Subject<void>;

  @Input() activeSubstep: RegistrationSubsteps;
  @Input() onSubstepChange: Subject<RegistrationSubsteps>;

  @Output() clientStepChanged = new EventEmitter<IFormSubstep>();

  public clientId: string = null;
  public clientTotal: number = -1;
  private FOR_DEREGISTRATION = 981660001; // oh no, another hard-coded constant

  private clientDescription: string = null;
  private unsubscribe$: Subject<void> = new Subject();

  @Input() sortList: (
    list: AbstractControl[],
    column: string,
    isAsc: boolean
  ) => void;

  public get clientForm(): AbstractControl {
    var clientCollection = this.form;

    if (this.clientId == null) {
      return clientCollection;
    } else {
      var clientList = <FormArray>clientCollection.get("clients");
      var client = clientList.controls.find(
        (c) => c.get("id").value == this.clientId
      );

      if (client == null) {
        // id has changed because new record was saved
        // try find by description
        client = clientList.controls.find(
          (c) => c.get("description").value == this.clientDescription
        );

        // otherwise, new client should be at end of list
        if (client == null) {
          client = clientList.controls[clientList.length - 1];
        }

        this.clientId = client.get("id").value;
      }

      this.clientDescription = client.get("description").value;
      client
        .get("description")
        .valueChanges.takeUntil(this.unsubscribe$)
        .subscribe(
          () => (this.clientDescription = client.get("description").value)
        );

      return client;
    }
  }

  constructor(private googleAnalyticsService: GoogleAnalyticsService) {}

  ngOnInit() {
    this.onSubstepChange.subscribe((newSubstep) => {
      this.activeSubstep = newSubstep;
      if (this.activeSubstep === RegistrationSubsteps["Clients list"]) {
        this.clearSelection();
        this.setHasNoClients();
      }
    });

    this.setHasNoClients();

    this.onSaved.subscribe(() => {
      this.sortList(
        (<FormArray>this.form.get("clients")).controls,
        "statusDescription",
        true
      );
    });

    this.googleAnalyticsService.logPageVisit("step-clients");
  }

  ngAfterViewInit() {
    this.setHasNoClients();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectClient(clientId: string) {
    this.unsubscribe$.next();

    this.clientId = clientId;
    (<FormArray>this.form.get("clients")).controls
      .find((c) => c.get("id").value == this.clientId)
      .patchValue({
        status: "Draft",
        statusDescription: "Not yet submitted",
      });

    this.activeSubstep = RegistrationSubsteps["Add client/s"];
    this.clientStepChanged.emit({
      name: this.activeSubstep,
      form: this.clientForm,
    });
  }

  selectNewClient() {
    this.unsubscribe$.next();

    var clients = <FormArray>this.form.get("clients");
    this.clientId = clients.controls[clients.length - 1].get("id").value;

    this.activeSubstep = RegistrationSubsteps["Add client/s"];
    this.clientStepChanged.emit({
      name: this.activeSubstep,
      form: this.clientForm,
    });
  }

  clearSelection() {
    this.clientId = null;
  }

  public sortColumns = {
    description: true,
    statusDescription: true,
  };

  sort(column: string) {
    this.sortColumns[column] = !this.sortColumns[column];
    this.sortList(
      (<FormArray>this.form.get("clients")).controls,
      column,
      this.sortColumns[column]
    );
  }

  setHasNoClients() {
    this.clientTotal = (<FormArray>this.form.get("clients")).controls.filter(
      (client) => {
        return (
          client.get("forDeletion").value !== true &&
          client.get("submissionReason").value !== this.FOR_DEREGISTRATION
        );
      }
    ).length;

    if (this.clientTotal === 0) {
      this.form.get("hasNoClients").enable();
    } else {
      this.form.get("hasNoClients").patchValue(false);
      this.form.get("hasNoClients").disable();
    }
  }

  public getStatusDescription(client: AbstractControl): string {
    if (client.get("submissionReason").value == this.FOR_DEREGISTRATION) {
      return "Deregistered - Not yet submitted";
    } else {
      return client.get("statusDescription").value;
    }
  }

  public willDeregister(client: AbstractControl): boolean {
    var desc = this.getStatusDescription(client);

    return (
      desc == "Deregistered - Not yet submitted" ||
      desc == "Submitted for removal"
    );
  }
}
