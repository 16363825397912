import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Http } from '@angular/http';
import { MockBackend, MockConnection } from "@angular/http/testing";

import 'rxjs/Rx';
import { Observable } from "rxjs";

export const REAL_HTTP = new InjectionToken("real http service");

//Router Services
import { IRequestRouter } from './request-router';
import { PassThroughService } from './pass-through.service';
import { AuthControllerBackendService } from './auth-controller-backend.service';
import { ConfigControllerBackendService } from './config-controller-backend.service';
import { ReportingControllerBackendService } from './reporting-controller-backend.service';
import { ContactControllerBackendService } from './contact-controller-backend.service';
import { MyControllerBackendService } from './my-controller-backend.service';
import { ContentControllerBackendService } from './content-controller-backend.service';
import { AbnControllerBackendService } from './abn-controller-backend.service';

const ISENABLED: boolean = true;

@Injectable()
export class MockBackendService {

    constructor(mockBackend: MockBackend,
        @Inject(REAL_HTTP) realHttp: Http,
        private passThroughRouter: PassThroughService,
        private authControllerRouter: AuthControllerBackendService,
        private configControllerBackendService: ConfigControllerBackendService,
        private reportingControllerRouter: ReportingControllerBackendService,
        private contactControllerRouter: ContactControllerBackendService,
        private myControllerRouter: MyControllerBackendService,
        private contentControllerRouter: ContentControllerBackendService,
        private abnControllerRouter: AbnControllerBackendService) {
        mockBackend.connections
            .flatMap((connection: MockConnection) => { return this.routeRequests(connection, realHttp)}).subscribe();
    }

  private routeRequests(connection: MockConnection, realHttp: Http) {
        if (ISENABLED) {
            let url = connection.request.url;

            console.log('Begin Mocking call to: ' + url);

            try {
                if (!!url) {
                    if (url.startsWith("/api")) {
                        if (url.startsWith("/api/user")) {
                            return this.authControllerRouter.routeRequest(connection, realHttp);
                        }

                        if (url.startsWith("/api/configuration")) {
                            return this.configControllerBackendService.routeRequest(connection, realHttp);
                        }
                    }

                    //TODO: Get this URL from config
                    let organisationApi = "http://localhost:9000/";
                    if (url.startsWith(organisationApi)) {
                        url = url.substring(organisationApi.length);
                        if (url.startsWith("lookup/abn")) {
                            return this.abnControllerRouter.routeRequest(connection, realHttp);
                        }

                        if (url.startsWith("content")) {
                            return this.contentControllerRouter.routeRequest(connection, realHttp);
                        }

                        if (url.startsWith("search")) {
                            return this.reportingControllerRouter.routeRequest(connection, realHttp);
                        }

                        if (url.startsWith("contact")) {
                            return this.contactControllerRouter.routeRequest(connection, realHttp);
                        }


                        if (url.startsWith("my")) {
                            return this.myControllerRouter.routeRequest(connection, realHttp);
                        }
                    }
                }
            } catch (ex) {
                console.log((<Error>ex).message);
            }


            return this.passThroughRouter.routeRequest(connection, realHttp);
        }

        return this.passThroughRouter.routeRequest(connection, realHttp);
    }
}
