import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormGroup, FormArray, AbstractControl } from "@angular/forms";
import { Subject } from "rxjs";
import { GoogleAnalyticsService } from "../../../../../services/google-analytics.service";
import {
  IFormSubstep,
  RegistrationSubsteps,
} from "app/modules/organisation/models";

@Component({
  selector: "app-step-owners",
  templateUrl: "./step-owners.component.html",
  styleUrls: ["./step-owners.component.scss"],
})
export class StepOwnersComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() ownersAdd: VoidFunction;
  @Input() ownersRemove: (ownerId: string) => void;
  @Input() onSaved: Subject<void>;
  @Input() isSoleTrader: boolean = false;

  @Input() activeSubstep: RegistrationSubsteps;
  @Input() onSubstepChange: Subject<string>;

  @Output() ownerStepChanged = new EventEmitter<IFormSubstep>();

  public ownerId: string = null;
  private ownerDescription: string = null;
  private unsubscribe$: Subject<void> = new Subject();

  private FOR_DEREGISTRATION = 981660001; // oh no, another hard-coded constant

  @Input() sortList: (
    list: AbstractControl[],
    column: string,
    isAsc: boolean
  ) => void;

  constructor(private googleAnalyticsService: GoogleAnalyticsService) {}

  get ownerForm(): AbstractControl {
    var ownerCollection = <FormArray>this.form.get("owners");

    if (this.ownerId === null) {
      return ownerCollection;
    } else {
      var owner = ownerCollection.controls.find(
        (o) => o.get("id").value === this.ownerId
      );

      if (owner === null) {
        // id has changed because new record was saved
        // try find by description
        owner = ownerCollection.controls.find(
          (o) => o.get("description").value === this.ownerDescription
        );

        // otherwise, new owner should be at end of list
        if (owner === null) {
          owner = ownerCollection.controls[ownerCollection.length - 1];
        }

        this.ownerId = owner.get("id").value;
      }

      this.ownerDescription = owner.get("description").value;
      owner
        .get("description")
        .valueChanges.takeUntil(this.unsubscribe$)
        .subscribe(
          () => (this.ownerDescription = owner.get("description").value)
        );

      switch (this.activeSubstep) {
        case RegistrationSubsteps["Owners list"]:
        case RegistrationSubsteps["Owners overview"]:
          return owner;
        case RegistrationSubsteps["Owner type"]:
          return owner.get("ownerType");
        case RegistrationSubsteps["Add owner/s"]:
          return owner.get("ownerDetails");
      }
    }
  }

  ngOnInit() {
    this.onSubstepChange.subscribe((newSubstep) => {
      this.activeSubstep = RegistrationSubsteps[newSubstep];
      if (this.activeSubstep === RegistrationSubsteps["Owners list"]) {
        this.clearSelection();
      }
    });

    this.onSaved.subscribe(() => {
      this.sortList(
        (<FormArray>this.form.get("owners")).controls,
        "statusDescription",
        true
      );
    });

    this.googleAnalyticsService.logPageVisit("step-owners");
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectOwner(ownerId: string) {
    this.unsubscribe$.next();
    this.ownerId = ownerId;
    this.ownerForm.patchValue({
      status: "Draft",
      statusDescription: "Not yet submitted",
    });

    this.activeSubstep = RegistrationSubsteps["Owner type"];
    this.ownerStepChanged.emit({
      name: this.activeSubstep,
      form: this.ownerForm,
    });
  }

  selectNewOwner() {
    this.unsubscribe$.next();
    var owners = <FormArray>this.form.get("owners");
    this.ownerId = owners.controls[owners.length - 1].get("id").value;

    this.activeSubstep = RegistrationSubsteps["Owner type"];
    this.ownerStepChanged.emit({
      name: this.activeSubstep,
      form: this.ownerForm,
    });
  }

  clearSelection() {
    this.ownerId = null;
  }

  public sortColumns = {
    description: true,
    statusDescription: true,
  };

  sort(column: string) {
    this.sortColumns[column] = !this.sortColumns[column];
    this.sortList(
      (<FormArray>this.form.get("owners")).controls,
      column,
      this.sortColumns[column]
    );
  }

  public getStatusDescription(owner: AbstractControl): string {
    if (owner.get("submissionReason").value === this.FOR_DEREGISTRATION) {
      return "Deregistered – Not yet submitted";
    } else {
      return owner.get("statusDescription").value;
    }
  }

  public willDeregister(owner: AbstractControl): boolean {
    var desc = this.getStatusDescription(owner);

    return (
      desc === "Deregistered – Not yet submitted" ||
      desc === "Submitted for removal"
    );
  }
}
