import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, ResponseContentType } from '@angular/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';

import * as fromRoot from './../../../reducers';
import { Store } from '@ngrx/store';

import { IAddress } from './../components/address/address.models';
import { IFormSubmitResponse } from './form-submit-response';

@Injectable()
export class ContactService {
    private config: any;

    constructor(private store: Store<fromRoot.IState>, private http: Http) {
        this.store.select(fromRoot.getConfig).subscribe(config => this.config = config);
    }

    public submit(model: IContactModel): Observable<IContactResponse> {
        let headers = new Headers({ 'Content-Type': 'application/json' });
        let options = new RequestOptions({ headers: headers });

        return this.http.post(this.config.api.organisation + "messaging/contact", model, options)
            .map(response => response.json())
            .map(response => <IContactResponse>response);
    }
}

export interface IContactModel {
    fullName: string;
    email: string;
    phone: string;
    address: IAddress;
    isResponseRequested: boolean;
    message: string;
    recaptchaResponse: string;
}

export interface IContactResponse extends IFormSubmitResponse {
    submitStatus: string;
    ticketNumber: string;
}