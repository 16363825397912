import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs/Observable";
import "rxjs/Rx";

import * as fromRoot from "./../../../reducers";
import { Store } from "@ngrx/store";

import { IConfiguration } from "./../../../modules/configuration/reducers";
import { map } from "rxjs/operators";

@Injectable()
export class StatisticsService {
  private config: IConfiguration;
  clients$: Observable<IStatistics>;
  lobbyists$: Observable<IStatistics>;
  formerReps$: Observable<IStatistics>;
  register$: Observable<IStatistics>;
  top$: Observable<IStatistics>;
  orgsByYear$: Observable<IStatistics>;

  constructor(private store: Store<fromRoot.IState>, private http: HttpClient) {
    this.store.select(fromRoot.getConfig).subscribe((config) => {
      this.config = config;
    });

    this.clients$ = this.http
      .get(this.config.api.organisation + "statistics/clients")
      .pipe(map((response: any) => <IStatistics>response));

    this.lobbyists$ = this.http
      .get(this.config.api.organisation + "statistics/lobbyists")
      .pipe(map((response: any) => <IStatistics>response));

    this.formerReps$ = this.http
      .get(this.config.api.organisation + "statistics/formerReps")
      .pipe(map((response: any) => <IStatistics>response));

    this.register$ = this.http
      .get(this.config.api.organisation + "statistics/register")
      .pipe(map((response: any) => <IStatistics>response));

    this.top$ = this.http
      .get(this.config.api.organisation + "statistics/top")
      .pipe(map((response: any) => <IStatistics>response));

    this.orgsByYear$ = this.http
      .get(this.config.api.organisation + "statistics/orgsByYear")
      .pipe(map((response: any) => <IStatistics>response));
  }
}

export interface IDataPoint {
  organisationId: string;
  displayName: string;
  category: string;
  description: string;
  descriptionCaption: string;
}

export interface IStatistics {
  categories: string[];
  data: IDataPoint[];
}
