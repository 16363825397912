import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  Routes,
  Router,
  NavigationEnd,
  ActivatedRoute,
  RouterModule,
} from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { PopoverModule } from "ngx-bootstrap/popover";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { NgxEchartsModule } from "ngx-echarts";

import { WizardModule } from "./../wizard/wizard.module";
import { ContentModule } from "./../content/content.module";
import { orgRoutes } from "./organisation.module.routing";

import { ProfileComponent } from "./components/profile/profile.component";

import { ControlsModule } from "./../controls/controls.module";
import { SearchModule } from "./../search/search.module";
import { RegisterComponent } from "./components/register/register.component";

import { RecaptchaModule } from "ng-recaptcha";
import { RecaptchaFormsModule } from "ng-recaptcha";

import { SignInComponent } from "./components/signin/signin.component";
import { SignInFailedComponent } from "./components/signin-failed/signin-failed.component";

//SERVICES
import { ReportingService } from "./services/reporting.service";
import { MyOrganisationService } from "./services/my-organisation.service";
import { GoogleAnalyticsService } from "../../services/google-analytics.service";
import { CookieService } from "../../services/cookie.service";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { RegistrationFormComponent } from "./components/registration-form/registration-form/registration-form.component";
import { ValidationSummaryComponent } from "./components/registration-form/validation-summary/validation-summary.component";
import { StepIntroductionComponent } from "./components/registration-form/step-introduction/step-introduction.component";
import { StepOrganisationDetailsComponent } from "./components/registration-form/step-organisation-details/step-organisation-details.component";
import { FieldValidationMessagesComponent } from "./components/registration-form/field-validation-messages/field-validation-messages.component";
import { RegistrationFormService } from "./services/registration-form.service";
import { StepResponsibleOfficerComponent } from "./components/registration-form/step-responsible-officer/step-responsible-officer.component";
import { StepOwnersComponent } from "./components/registration-form/step-owners/step-owners.component";
import { StepLobbyistsComponent } from "./components/registration-form/step-lobbyists/step-lobbyists.component";
import { StepClientsComponent } from "./components/registration-form/step-clients/step-clients.component";
import { StepReviewSubmitComponent } from "./components/registration-form/step-review-submit/step-review-submit.component";
import { StepSoleTraderComponent } from "./components/registration-form/step-sole-trader/step-sole-trader.component";
import { YesNoBlankPipe } from "../pipes/yes-no-blank-pipe";
import { NgbDatePipe } from "../pipes/ngb-date-pipe";
import { BlankSubstitutePipe } from "../pipes/blank-substitute-pipe";
import { PrintComponent } from "./components/registration-form/print-control/print.component";
import { ChecklistComponent } from "./components/registration-form/checklist/checklist.component";
import { PrintService } from "./services/print.service";
import { ToastService } from "./services/toast.service";
import { NotifyComponent } from "./components/notify/notify.component";
import { DeregisterOrganisationComponent } from "./components/registration-form/deregister-organisation/deregister-organisation.component";
import { ResetFormComponent } from "./components/registration-form/reset-form/reset-form.component";
import { ActivateOrganisationComponent } from "./components/registration-form/activate-organisation/activate-organisation.component";
import { ServerNotifyComponent } from "./components/server-notify/server-notify.component";
import { BreachService } from "./services/breach.service";
import { BreachComponent } from "./components/breach/breach/breach.component";
import { StepReporterDetailsComponent } from "./components/breach/step-reporter-details/step-reporter-details.component";
import { StepSelectEntityComponent } from "./components/breach/step-select-entity/step-select-entity.component";
import { StepSelectOrganisationComponent } from "./components/breach/step-select-organisation/step-select-organisation.component";
import { StepSelectEntityTypeComponent } from "./components/breach/step-select-entity-type/step-select-entity-type.component";
import { StepBreachDetailsComponent } from "./components/breach/step-breach-details/step-breach-details.component";
import { StatisticsComponent } from "./components/statistics/statistics.component";
import { StatisticsService } from "./services/statistics.service";
import { HistoryService } from "../history/services/history.service";
import { HistoryComponent } from "../history/components/history/history.component";
import { HistoryModule } from "../history/history.module";
import { PipeModule } from "../pipes/pipes.module";
import { JoyrideModule } from "ngx-joyride";

export function chartModule(): any {
  return import("echarts");
}

@NgModule({
  imports: [
    orgRoutes,
    CommonModule,
    HistoryModule,
    FormsModule,
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    AccordionModule.forRoot(),
    TooltipModule.forRoot(),
    NgbModule,
    JoyrideModule.forRoot(),
    ControlsModule,
    SearchModule,
    WizardModule,
    ContentModule,
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    NgxEchartsModule.forRoot({
      echarts: chartModule,
    }),
    PipeModule.forRoot(),
  ],
  exports: [],
  declarations: [
    ProfileComponent,
    RegisterComponent,
    StepIntroductionComponent,
    ValidationSummaryComponent,
    StepOrganisationDetailsComponent,
    FieldValidationMessagesComponent,
    DashboardComponent,
    RegistrationFormComponent,
    StepResponsibleOfficerComponent,
    StepOwnersComponent,
    StepLobbyistsComponent,
    StepClientsComponent,
    StepReviewSubmitComponent,
    StepSoleTraderComponent,
    PrintComponent,
    ChecklistComponent,
    NotifyComponent,
    DeregisterOrganisationComponent,
    ActivateOrganisationComponent,
    ResetFormComponent,
    ServerNotifyComponent,
    BreachComponent,
    StepReporterDetailsComponent,
    StepSelectEntityTypeComponent,
    StepSelectEntityComponent,
    StepSelectOrganisationComponent,
    StepBreachDetailsComponent,
    StatisticsComponent,
    SignInComponent,
    SignInFailedComponent,
  ],
  providers: [
    ReportingService,
    MyOrganisationService,
    RegistrationFormService,
    GoogleAnalyticsService,
    PrintService,
    ToastService,
    CookieService,
    BreachService,
    StatisticsService,
    HistoryService,
  ],
})
export class OrganisationModule {}
