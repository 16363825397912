import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ILookup } from 'app/modules/organisation/services/registration-form.service';
import { NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from 'app/modules/organisation/components/registration-form/step-lobbyists/step-lobbyists.component';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/reducers';

@Component({
  selector: 'app-step-breach-details',
  templateUrl: './step-breach-details.component.html',
  styleUrls: ['./step-breach-details.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
  ]
})
export class StepBreachDetailsComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() relations: ILookup[] = [];
  @Input() codes: ILookup[] = [];
  @Input() codeParts: ILookup[] = [];
  @Input() activeSubstep: string;
  @Input() onSubstepChange: Subject<string>;
  @Input() isAnonymous: boolean;

  public config: any;

  constructor(private store: Store<fromRoot.IState>) {
    this.store.select(fromRoot.getConfig).subscribe(config => this.config = config);
  }

  get yesterday(): NgbDateStruct {
    const d = new Date();
    d.setDate(d.getDate() - 1);

    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  };

  ngOnInit() {
    this.onSubstepChange.subscribe(newSubstep => {
      this.activeSubstep = newSubstep;
    });

  }

  resolved(captchaResponse: string) {
    this.form.get('furtherContact.recaptchaResponse').setValue(captchaResponse);
  }
}
