import { Component, OnInit, Input } from '@angular/core';
import { INavTabItem } from "./../../models"

@Component({
    selector: 'app-nav-tabs',
    templateUrl: './nav-tabs.component.html',
    styleUrls: ['./nav-tabs.component.scss']
})
export class NavTabsComponent implements OnInit {
    
    @Input() tabItems: INavTabItem[];

    constructor() { }

    ngOnInit() { }

}
