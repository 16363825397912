import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { SearchService } from "app/modules/search/services/search.service";

@Component({
  selector: "app-search-register",
  templateUrl: "./search-register.component.html",
  styleUrls: ["./search-register.component.scss"],
})
export class SearchRegisterComponent implements OnInit {
  isSearching: boolean = true;
  constructor(public searchService: SearchService) {}

  ngOnInit() {
    this.searchService.isSearching.subscribe((val) => (this.isSearching = val));
  }
}
