import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MyOrganisationService } from "app/modules/organisation/services/my-organisation.service";
import { IMyLobbyist } from './../../../models';
import { RegistrationFormService, IRegistrationFormData } from 'app/modules/organisation/services/registration-form.service';
import { Observable } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import * as fromDashboardNotifications from 'app/reducers/dashboard-notifications';
import { Store } from '@ngrx/store';
import * as fromRoot from './../../../../../reducers';
import { Router } from '@angular/router';

@Component({
  selector: 'app-deregister-organisation',
  templateUrl: './deregister-organisation.component.html',
  styleUrls: ['./deregister-organisation.component.scss']
})
export class DeregisterOrganisationComponent implements OnInit {

  @Input() activeModal: NgbActiveModal;
  @Input() currentOrganisation: IRegistrationFormData;
  @Output() deregisterSelected = new EventEmitter<IRegistrationFormData>();

  private lobbyists: any[];
  public isLoading: boolean;
  public isDeregistering: boolean;

  constructor(private orgService: MyOrganisationService,
    private registrationFormService: RegistrationFormService,
    private store: Store<fromRoot.IState>,
    private router: Router) {
    this.isLoading = true;
  }

  ngOnInit() {
    if (this.currentOrganisation == null) {
      this.orgService.getMyLobbyists().then(myLobbyists => {
        this.lobbyists = myLobbyists;
        this.isLoading = false;
      });
    } else {
      this.lobbyists = this.currentOrganisation.lobbyists.lobbyists;
      this.isLoading = false;
    }

  }

  deregisterOrganisation() {
    this.isDeregistering = true;

    if (this.currentOrganisation == null) {
      this.registrationFormService.formData$.pipe(
        take(1),
        tap(orgData => this.registrationFormService.deregisterMyOrganisation(orgData).subscribe(orgData => {
          this.notifySubscribers(orgData);
        }))).subscribe();
    } else {
      this.registrationFormService.deregisterMyOrganisation(this.currentOrganisation).subscribe(orgData => {
        this.notifySubscribers(orgData);
      });
    }

  }

  notifySubscribers(orgData: IRegistrationFormData) {
    this.store.dispatch({
      type: fromDashboardNotifications.SUCCESS_NOTIFICATION,
      payload: { message: "Thank you for submitting your organisation for deregistration. You will be notified once your changes have been actioned." }
    });

    this.deregisterSelected.next(orgData);
    this.activeModal.close();
    this.isDeregistering = false;
    this.router.navigateByUrl('/user/dashboard');
  }
}
