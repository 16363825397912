import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn } from "@angular/forms";

@Component({
    selector: 'app-full-name',
    templateUrl: './full-name.component.html',
    styleUrls: ['./full-name.component.scss']
})
export class FullNameComponent implements OnInit {
    @Input() public firstName: string;
    @Input() public lastName: string;

    @Output() changed = new EventEmitter<any>();
    @Output() firstNameChange: EventEmitter<string> = new EventEmitter(); 
    @Output() lastNameChange: EventEmitter<string> = new EventEmitter(); 
       
    @Input() public readonly: boolean = false;

    @Input() public formGroup: FormGroup;
    @Input() validationMessages: any;
    @Input() public formErrors: any;
    @Input() public formModel: any;

    @Input() public key: string;

    public firstNameKey = "firstName";
    public lastNameKey = "lastName";

    constructor() { }

    ngOnInit() {
        if(this.key){
            this.firstNameKey = this.firstNameKey + "-" + this.key
        }

        if(this.key){
            this.lastNameKey = this.lastNameKey + "-" + this.key
        }

        this.formModel[this.firstNameKey] = new FormControl(this.firstName,
            [Validators.required, Validators.maxLength(50)]);
        this.formModel[this.lastNameKey] = new FormControl(this.lastName,
            [Validators.required, Validators.maxLength(50)]);

        this.validationMessages[this.firstNameKey] = {
            'required': 'First name is required (maximum 50 characters)'
        };

        this.validationMessages[this.lastNameKey] = {
            'required': 'Last name is required (maximum 50 characters)'
        };

        this.formErrors[this.firstNameKey] = '';
        this.formErrors[this.lastNameKey] = '';

        this.formGroup.addControl(this.firstNameKey, this.formModel[this.firstNameKey])
        this.formGroup.addControl(this.lastNameKey, this.formModel[this.lastNameKey])

        this.formGroup.valueChanges.subscribe(data => this.onValueChanged(this.formGroup, data));
    }

    onValueChanged(form: FormGroup, data?: any) {
        this.firstNameChange.emit(data.firstName);
        this.lastNameChange.emit(data.lastName);
    }

    public onChanged(e: any) {
        this.changed.emit();
    }
}
