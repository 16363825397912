import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, ResponseContentType } from '@angular/http';

import * as fromRoot from './../../../reducers';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';

import { IAbnResult } from './../models';

@Injectable()
export class AbnService {
    private config: any;

    constructor(private store: Store<fromRoot.IState>, private http: Http) {
        this.store.select(fromRoot.getConfig).subscribe(config => this.config = config);
    }

    public lookup(abn: string): Observable<IAbnResult> {
        return this.http.get(this.config.api.organisation + "lookup/abn/" + abn)
            .map(response => response.json())
            .map(response => <IAbnResult>response);
    }

    public format(str: string): string {
        if (str) {
            if (this.isValidAbn(str)) {
                return str.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            } else if (this.isValidAcn(str)) {
                return str.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }
        }

        return str;
    }

    public isValidAbn(str: string): boolean {
        if (!str) {
            return false;
        }

        str = str.replace(/[ _]/g, '');

        if (!str || str.length !== 11) {
            return false;
        }
        var weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19],
            checksum = str.split('').map(Number).reduce(
                function (total, digit, index) {
                    if (!index) {
                        digit--;
                    }
                    return total + (digit * weights[index]);
                },
                0
            );

        if (!checksum || checksum % 89 !== 0) {
            return false;
        }

        return true;
    }

    public isValidAcn(str: string): boolean {
        if (!str) {
            return false;
        }

        // Strip off white space
        if (typeof str === 'string') {
            str = str.replace(/\s/g, '').replace(/-/g, '');
        }

        if (str.length != 9 || isNaN(parseInt(str)))
            return false;

        var weighting = [8, 7, 6, 5, 4, 3, 2, 1];
        var tally = 0;
        for (var i = 0; i < weighting.length; i++) {
            tally += (parseInt(str.charAt(i)) * weighting[i]);
        }

        var check = 10 - (tally % 10);
        check = check == 10 ? 0 : check;

        return check == +str[8];
    }
}
