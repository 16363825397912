import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {
    @Input() public message: string = "Loading! Please wait...";

  constructor() { }

  ngOnInit() {
  }

}
