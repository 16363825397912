import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

import { IWizardState, IWizardStep } from './../../models'
import { FormUtils } from 'app/modules/organisation/helpers';

@Component({
    selector: 'app-wizard-actions',
    templateUrl: './wizard-actions.component.html',
    styleUrls: ['./wizard-actions.component.scss']
})
export class WizardActionsComponent implements OnInit {

    @Input() state: IWizardState<any>;
    @Input() onStepValidate: () => boolean;
    @Output() stepChanged = new EventEmitter<number>();
    @Output() finished = new EventEmitter<any>();

    constructor() { }

    ngOnInit() { }

    nextStep(event) {
        event.preventDefault();
        let currentIndex = this.state.currentStepIndex;
        let nextIndex = currentIndex < this.state.steps.length - 1 ? currentIndex + 1 : currentIndex;
        let currentStep = this.state.currentStep;
        if (!currentStep.mustValidate || (this.onStepValidate && this.onStepValidate())) {
            this.stepChanged.emit(nextIndex);
        }
    }

    previousStep(event) {
        event.preventDefault();
        event.preventDefault();
        let currentIndex = this.state.currentStepIndex;
        let nextIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        this.stepChanged.emit(nextIndex);
    }

    finish(event) {
        event.preventDefault();
        let currentStep = this.state.steps[this.state.steps.length - 1];
        if (!currentStep.mustValidate || (this.onStepValidate && this.onStepValidate())) {
            this.state.isInProgress = true;
            this.finished.emit(this.state.model);
        }
    }

    validateFormGroup(formGroup: FormGroup){
        FormUtils.validateFormGroup(formGroup, true);
    }
}
