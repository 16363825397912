import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfigService } from './services/config.service';
import { AuthService } from '../identity/services/auth.service';

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        ConfigService,
        { provide: APP_INITIALIZER, useFactory: initConfiguration, deps: [ConfigService], multi: true }
    ],
    declarations: []
})
export class ConfigurationModule { }

export function initConfiguration(configService: ConfigService) {
    return () => {
        configService.load(); // + any other services...
        
    }
}