import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromRoot from './../../../../../reducers';

@Component({
  selector: 'app-reset-form',
  templateUrl: './reset-form.component.html',
  styleUrls: ['./reset-form.component.scss']
})
export class ResetFormComponent implements OnInit {

  @Input() activeModal: NgbActiveModal;
  @Input() currentForm: FormGroup; 

  private formData: any;

  
  constructor(private store: Store<fromRoot.IState>) {
    this.store.select(fromRoot.getSelectedFormData).subscribe(formData => this.formData = formData);
  }

  ngOnInit() {
  }

  resetForm() {
    // statDecUpload form control is disabled, it will be missing from lobbyist form data
    if (this.formData.hasOwnProperty("statDecPreviouslyUploaded") && !this.formData.hasOwnProperty("statDecUpload")) {
      this.formData = { ...this.formData, statDecUpload: [] };
    }

    this.currentForm.reset(this.formData);
    this.activeModal.close(this.currentForm);
  }
}
