import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import * as moment from "moment";

import {
  ReportingService,
  IOrganisationProfile,
} from "./../../services/reporting.service";

import { INavTabItem } from "./../../../controls/models";
import { IStakeholder } from "../../models";

import { NgbTabChangeEvent, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ILookup } from "../../services/registration-form.service";
import {
  IOrganisationHistory,
  HistoryService,
} from "app/modules/history/services/history.service";
import { Store } from "@ngrx/store";
import * as fromRoot from "./../../../../reducers";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  private profile: IOrganisationProfile;
  private registeredOn: string;

  public isLoading: boolean;
  private hasStakeholderShortNames: boolean;
  private hasStakeholderLongNames: boolean;

  public navTabItems: INavTabItem[];
  public historyYears: ILookup[];

  @ViewChild("modalLoading", { read: false, static: false })
  modalLoading: ElementRef;
  @ViewChild("modalHistory", { read: false, static: false })
  modalHistory: ElementRef;

  public id: string;
  public history: IOrganisationHistory;
  public selectedYear: string;
  public sortColumns: Record<string, Record<string, Boolean>>;

  constructor(
    private route: ActivatedRoute,
    private reportingService: ReportingService,
    private historyService: HistoryService,
    private modalService: NgbModal,
    private store: Store<fromRoot.IState>
  ) {
    this.sortColumns = {
      org: {},
      lob: {},
      cli: {},
      own: {},
    };
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params["id"]; // (+) converts string 'id' to a number

      this.isLoading = true;
      this.reportingService
        .getOrganisationProfile(this.id)
        .subscribe((profile) => {
          this.profile = profile;
          this.registeredOn = moment(profile.summary.registeredOn).format(
            "LLLL"
          );
          this.hasStakeholderShortNames =
            this.profile.stakeholders.filter(
              (x) => !this.isStakeholderLongName(x)
            ).length > 0;
          this.hasStakeholderLongNames =
            this.profile.stakeholders.filter((x) =>
              this.isStakeholderLongName(x)
            ).length > 0;

          this.navTabItems = [
            {
              label: "Registered Lobbyists",
              badge: null,
              target: "lobbyists",
              icon: null,
              altIcon: null,
            },
            {
              label: "Client Details",
              badge: null,
              target: "clients",
              icon: null,
              altIcon: null,
            },
            {
              label: "Owner Details",
              badge: null,
              target: "stakeholders",
              icon: null,
              altIcon: null,
            },
          ];

          this.isLoading = false;
        });
    });
  }

  private isStakeholderLongName(stakeholder: IStakeholder): boolean {
    return !!stakeholder.firstName && !!stakeholder.lastName;
  }

  public beforeChange($event: NgbTabChangeEvent) {
    if ($event.nextId === "history") {
      $event.preventDefault();
      this.modalService.open(this.modalLoading, {
        centered: true,
        backdrop: "static",
        keyboard: false,
      });
      this.historyService.getOrganisationHistory(this.profile.summary.id);
    }
  }
}
