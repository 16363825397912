import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import * as fromRoot from "./../../../reducers";
import { Store } from "@ngrx/store";

import { Observable } from "rxjs/Observable";
import "rxjs/Rx";

import { IUserPrincipal } from "./../../identity/identity.models";
import { IConfiguration } from "./../../../modules/configuration/reducers";

@Injectable()
export class ConfigService {
  constructor(
    private store: Store<fromRoot.IState>,
    private http: HttpClient
  ) { }

  load() {
    return new Promise((resolve, reject) => {
      this.http
        .get<IConfiguration>("/api/configuration")
        .subscribe((config) => {
          // console.log(`debuglog: got new config ${JSON.stringify(config)}`);
          this.store.dispatch({
            type: "CONFIGURATIONLOADED",
            payload: config,
          });

          Observable.of(<IUserPrincipal>{
            isAuthenticated: false,
            email: null,
            displayName: null,
            accessToken: null,
            earsUpn: null,
          })

          resolve(true);
        });
    });
  }
}
