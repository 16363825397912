import { Injectable } from "@angular/core";
import {
  Http,
  Response,
  Headers,
  RequestOptions,
  ResponseContentType,
} from "@angular/http";

import { HttpClient, HttpHeaders } from "@angular/common/http";

import * as fromRoot from "./../../../reducers";
import { Store } from "@ngrx/store";

import { Observable } from "rxjs/Observable";
import "rxjs/Rx";

import { IOfficerEmailResult } from "./../models";
import { AuthService } from "app/modules/identity/services/auth.service";
import { IHasSeenTurtorialResponse } from "app/modules/organisation/models";
import { map } from "rxjs/operators";

@Injectable()
export class OfficerService {
  private config: any;

  constructor(
    private store: Store<fromRoot.IState>,
    private http: HttpClient,
    private authService: AuthService
  ) {
    this.store
      .select(fromRoot.getConfig)
      .subscribe((config) => (this.config = config));
  }

  public lookup(
    email: string,
    officerId: string
  ): Observable<IOfficerEmailResult> {
    var headers = this.authService.createAuthorizationHttpHeaders();
    var options = { headers: headers };

    return this.http
      .get(
        this.config.api.organisation +
          "my/lookup/email?email=" +
          email +
          "&officerId=" +
          officerId,
        options
      )
      .map((response) => <IOfficerEmailResult>response);
  }

  public saveTutorialStatus(
    officerId: string
  ): Observable<IHasSeenTurtorialResponse> {
    const url = this.config.api.organisation + "my/updatetutorialstatus";

    const body = {
      Id: officerId,
    };
    const headers = this.authService.createAuthorizationHttpHeaders();
    const options = {
      headers,
      reportProgress: true,
    };

    return this.http.post(url, body, options).pipe(
      map((response: any) => {
        return <IHasSeenTurtorialResponse>response;
      })
    );
  }

  public saveBeginNowStatus(
    officerId: string
  ): Observable<IHasSeenTurtorialResponse> {
    const url = this.config.api.organisation + "my/updatetutorialstatus";

    const body = {
      Id: officerId,
    };
    const headers = this.authService.createAuthorizationHttpHeaders();
    const options = {
      headers,
      reportProgress: true,
    };

    return this.http.post(url, body, options).pipe(
      map((response: any) => {
        return <IHasSeenTurtorialResponse>response;
      })
    );
  }
}
