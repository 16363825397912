import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import * as fromRoot from './../../../../reducers';
import { Store } from '@ngrx/store';

import { IUserPrincipal } from './../../../identity/identity.models'
import { AuthGuard } from 'app/modules/identity/gaurds/auth.gaurd';


@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  private config: any;
  private userPrincipal: IUserPrincipal;

  constructor(private store: Store<fromRoot.IState>, private router: Router) {
    this.store.select(fromRoot.getConfig).subscribe(config => this.config = config);
    this.store.select(fromRoot.getUserPrincipal).subscribe(principal => {
      this.userPrincipal = principal;
      if (this.userPrincipal.isAuthenticated) {
        this.router.navigate(['/user/dashboard']);
      }

    });
  }

  ngOnInit() { }
}
