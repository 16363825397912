import { Injectable, ErrorHandler } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from './../reducers';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {

    constructor(private store: Store<fromRoot.IState>) {
        super();
    }

    public handleError(error: any): void {
        this.store.dispatch({
            type: "ALERT",
            payload: { message: error.toString(), type: "ALERT", timeout: 0 }
        });

        super.handleError(error);
    }
}
