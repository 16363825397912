import { Injectable } from '@angular/core';
import { Subject } from "rxjs";

@Injectable()
export class PrintService {
  private printRequestedSource = new Subject<string>();

  printRequested$ = this.printRequestedSource.asObservable();

  requestPrint(msg: string) {
    this.printRequestedSource.next(msg);
  }
}
