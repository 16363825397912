import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { ISearchResponse } from "../../../../organisation/services/reporting.service";
import { ISearchRequest } from "../../../../organisation/services/search-request";
import { SearchService } from "../../../services/search.service";

@Component({
  selector: "app-results-selector",
  templateUrl: "./results-selector.component.html",
  styleUrls: ["./results-selector.component.scss"],
})
export class ResultsSelectorComponent implements OnInit {
  onSelect(newVal: number) {
    this.selectedOption = newVal;
    this.searchService.searchRequest.count = newVal;
    this.searchService.doSearch();
  }

  public viewOptions = [
    {
      name: "10",
      value: 10,
    },
    {
      name: "50",
      value: 50,
    },
    {
      name: "100",
      value: 100,
    },
  ];

  public selectedOption: number = 10;

  constructor(public searchService: SearchService) {}

  private isSearching: boolean;

  ngOnInit() {
    this.searchService.isSearching.subscribe((val) => (this.isSearching = val));
  }
}
