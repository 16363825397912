import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { GoogleAnalyticsService } from '../../../../../services/google-analytics.service';
import { RegistrationFormService } from 'app/modules/organisation/services/registration-form.service';

@Component({
  selector: 'app-step-organisation-details',
  templateUrl: './step-organisation-details.component.html',
  styleUrls: ['./step-organisation-details.component.scss']
})
export class StepOrganisationDetailsComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() activeSubstep: string;
  @Input() onSubstepChange: Subject<string>;

  constructor(private googleAnalyticsService: GoogleAnalyticsService,
    private registrationFormService: RegistrationFormService) { }

  public isEditable() {
    return this.form.get('status.status').value == "Draft";
  }

  public enableEditing() {
    this.form.patchValue({
      status: { status: 'Draft' }
    });
  }

  public get orgForm(): AbstractControl {
    switch (this.activeSubstep) {
      case "Business details":
      default:
        return this.form.get("businessDetails");
      case "Contact":
        return this.form.get("contactDetails");
      case "Business address":
        return this.form.get("businessEntityPhysicalAddress");
      case "Postal address":
        return this.form.get("businessEntityPostalAddress");
    }
  }

  ngOnInit() {
    this.onSubstepChange.subscribe(newSubstep => {
      this.activeSubstep = newSubstep;
      this.registrationFormService.selectRecord(this.orgForm.value);
    });

    this.registrationFormService.selectRecord(this.orgForm.value);

    this.googleAnalyticsService.logPageVisit("step-organisational-details");
  }
}
