import { Component, OnInit, Input } from '@angular/core';

import { AbnService } from './../../services/abn.service';

@Component({
    selector: 'app-abn-display',
    templateUrl: './abn-display.component.html',
    styleUrls: ['./abn-display.component.scss']
})
export class AbnDisplayComponent implements OnInit {
    formattedABN: string;

    @Input()
    set ABN(abn: string) {
        this.formattedABN = this.abnService.format(abn);
    }

    constructor(private abnService: AbnService) { }

    ngOnInit() {
        
    }

}
