import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/modules/identity/services/auth.service';

@Component({
  selector: 'app-master-layout',
  templateUrl: './master-layout.component.html',
  styleUrls: ['./master-layout.component.scss']
})
export class MasterLayoutComponent implements OnInit {

  constructor(authService: AuthService) { 
    // authService.handlePageLoadEvent()
  }

  ngOnInit() {
  }

}
