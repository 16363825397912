import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { ControlsModule } from "./../controls/controls.module";

import { SearchService } from "./services/search.service";
import { SearchComponent } from "./components/search/search.component";
import { SearchBarComponent } from "./components/search-bar/search-bar.component";
import { PaginatorComponent } from "./components/paginator/paginator.component";
import { ResultComponent } from "./components/result/result.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HistoryModule } from "../history/history.module";
import { SearchRegisterComponent } from "./components/register-components/search-register/search-register.component";
import { SearchBarRegisterComponent } from "./components/register-components/search-bar-register/search-bar-register.component";
import { ResultsSelectorComponent } from "./components/register-components/results-selector/results-selector.component";
import { RegisterResultComponent } from "./components/register-components/result-register/result-register.component";
import { RegisterPaginatorComponent } from "./components/register-components/paginator-register/paginator-register.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ControlsModule,
    NgbModule,
    HistoryModule,
  ],
  exports: [
    SearchComponent,
    SearchBarRegisterComponent,
    SearchRegisterComponent,
    SearchBarComponent,
    PaginatorComponent,
    ResultComponent,
    ResultsSelectorComponent,
    RegisterResultComponent,
    RegisterPaginatorComponent,
  ],
  declarations: [
    SearchComponent,
    SearchBarRegisterComponent,
    SearchRegisterComponent,
    SearchBarComponent,
    PaginatorComponent,
    ResultComponent,
    ResultsSelectorComponent,
    RegisterResultComponent,
    RegisterPaginatorComponent,
  ],
  providers: [SearchService],
})
export class SearchModule {}
