import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Output,
  ViewChild,
  EventEmitter,
} from "@angular/core";
import { FormGroup, FormArray, AbstractControl } from "@angular/forms";
import { NgbDateStruct, NgbAccordion } from "@ng-bootstrap/ng-bootstrap";
import * as fromRoot from "./../../../../../reducers";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-step-review-submit",
  templateUrl: "./step-review-submit.component.html",
  styleUrls: ["./step-review-submit.component.scss"],
})
export class StepReviewSubmitComponent implements OnInit, AfterViewInit {
  @Input() form: FormGroup;
  @Input() navigateToStep: (step: FormGroup) => void;
  @Input() originalForm: FormGroup;
  @Input() isInReportingPeriod: boolean;
  @Input() isDeregistered: boolean;

  @Output() hasNoChanges = new EventEmitter<Boolean>();

  private FOR_DEREGISTRATION = 981660001; // ugh

  @ViewChild("a", { read: false, static: true }) reviewAccordion: NgbAccordion;

  constructor(private store: Store<fromRoot.IState>) {}

  ngOnInit() {
    var result = this.detailsHaveNotChanged();
    this.hasNoChanges.emit(result);
  }

  ngAfterViewInit() {
    // ensure all sections are expanded
    // use setTimeout to avoid ExpressionChangedAfter(ad.) error
    setTimeout(() => {
      this.reviewAccordion.expandAll();
    });
  }

  isNewRegistration(): boolean {
    return this.originalForm.get("organisationDetails").get("id").value == null;
  }

  hasChanged(controlPath: string): boolean {
    if (this.isNewRegistration()) {
      // not approved yet, nothing to compare to
      return false;
    }

    return (
      this.form.get(controlPath).value !==
      this.originalForm.get(controlPath).value
    );
  }

  hasItemChanged(
    masterItem: FormGroup,
    fieldPath: string,
    controlPath: string
  ) {
    if (this.isNewRegistration()) {
      return false;
    }

    // no public item for responsible officers
    if (!masterItem.get("publicId")) {
      return false;
    }

    // always highlight new item
    if (masterItem.get("publicId").value == null) {
      return true;
    }

    const publicItem: AbstractControl = (<FormArray>(
      this.originalForm.get(controlPath)
    )).controls.filter(
      (p) => p.get("id").value === masterItem.get("publicId").value
    )[0];

    if (publicItem == null) {
      return true;
    }

    var masterValue = masterItem.get(fieldPath).value;
    var publicValue = publicItem.get(fieldPath).value;

    return this.compareValues(masterValue, publicValue);
  }

  compareValues(masterValue: object, publicValue: object) {
    if (masterValue == null && publicValue == null) {
      return false;
    } else if (masterValue == null || publicValue == null) {
      return true;
    }

    if (masterValue.hasOwnProperty("day")) {
      const ngbMasterValue: NgbDateStruct = <NgbDateStruct>masterValue;
      const ngbPublicValue: NgbDateStruct = <NgbDateStruct>publicValue;

      return !(
        ngbMasterValue.day == ngbPublicValue.day &&
        ngbMasterValue.month == ngbPublicValue.month &&
        ngbMasterValue.year == ngbPublicValue.year
      );
    } else {
      return masterValue !== publicValue;
    }
  }

  changedRecords(controlPath: string): AbstractControl[] {
    // console.log(`DEBUGLOG: controlPath ${controlPath}`);

    // get list of public IDs
    const publicList: FormArray = <FormArray>this.originalForm.get(controlPath);

    // debug
    // publicList.controls.forEach((control) => {
    //   console.log(`DEBUGLOG: public.value ${JSON.stringify(control.value)}`);
    // });

    var publicItemIds = publicList.controls.map((i) => i.get("id").value);

    // get records in form that are in that list
    const masterList: FormArray = <FormArray>this.form.get(controlPath);
    var masterItems = masterList.controls.filter(
      (m) => publicItemIds.indexOf(m.get("publicId").value) != -1
    );

    // debug
    // masterList.controls.forEach((control) => {
    //   console.log(`DEBUGLOG: master.value ${JSON.stringify(control.value)}`);
    // });

    // but only those where at least one field has changed
    if (publicItemIds.length > 0) {
      return masterItems.filter(
        (m) =>
          m.get("submissionReason").value !== this.FOR_DEREGISTRATION &&
          this.originalHasChanged(
            <FormGroup>m,
            publicList.controls[
              publicList.controls.findIndex(
                ((p) => p.get("id").value === m.get("publicId").value) as any
              )
            ]
          )
      );
    } else {
      return null;
    }
  }

  originalHasChanged(masterItem: FormGroup, publicItem: AbstractControl) {
    const formGroupNames = [
      "lobbyistDetails",
      "formerDetails",
      "ownerType",
      "ownerDetails",
    ];
    var result = false;
    for (var formGroupName in formGroupNames) {
      if (masterItem.get(formGroupName)) {
        result =
          result ||
          this.originalHasChanged(
            <FormGroup>masterItem.get(formGroupName),
            publicItem.get(formGroupName)
          );
      }
    }

    const excludedFields: string[] = [
      "id",
      "publicId",
      "status",
      "submissionReason",
      "statDecUpload",
      "statDecPreviouslyUploaded",
      "description",
      "cessationDate",
      "statusDescription",
    ];
    const fieldNames: string[] = Object.keys(masterItem.controls).filter(
      (k) => excludedFields.indexOf(k) == -1 && formGroupNames.indexOf(k) == -1
    );
    //fieldNames.forEach(f => { if (masterItem.get(f).value !== publicItem.get(f).value) console.log(f + " was:" + masterItem.get(f).value + " is:" + publicItem.get(f).value) });
    return (
      result ||
      fieldNames.some(((f) =>
        this.compareValues(
          masterItem.get(f).value,
          publicItem.get(f).value
        )) as any)
    );
  }

  newRecords(controlPath: string): AbstractControl[] {
    // records with no public ID are new
    const masterList: FormArray = <FormArray>this.form.get(controlPath);
    var masterItems = masterList.controls.filter(
      (m) => m.get("publicId").value == null
    );

    return masterItems;
  }

  removedRecords(controlPath: string): AbstractControl[] {
    // get list of IDs from new form
    const masterList: FormArray = <FormArray>this.form.get(controlPath);
    var masterItemIds = masterList.controls
      .map((i) => i.get("publicId").value)
      .filter((f) => f != null);

    // get original records that aren't in list of IDs from new form
    const publicList: FormArray = <FormArray>this.originalForm.get(controlPath);
    var removed = publicList.controls.filter(
      (p) => masterItemIds.indexOf(p.get("id").value) == -1
    );

    // and those that are pending removal
    var pending = masterList.controls.filter(
      (m) => m.get("submissionReason").value === this.FOR_DEREGISTRATION
    );

    return removed.concat(pending);
  }

  public detailsHaveNotChanged(): boolean {
    // org details
    var orgFields = [
      "businessDetails.abn",
      "businessDetails.alternateBusinessNumberDescription",
      "businessDetails.alternateBusinessNumber",
      "businessDetails.businessEntityName",
      "businessDetails.hasTradingName",
      "businessDetails.tradingName",
      "contactDetails.primaryPhoneNumber",
      "contactDetails.secondaryPhoneNumber",
      "contactDetails.website",
      "businessEntityPhysicalAddress.addressLine3",
      "businessEntityPhysicalAddress.addressLine1",
      "businessEntityPhysicalAddress.addressLine2",
      "businessEntityPhysicalAddress.suburb",
      "businessEntityPhysicalAddress.state",
      "businessEntityPhysicalAddress.postcode",
      "businessEntityPhysicalAddress.country",
      "businessEntityPostalAddress.addressLine3",
      "businessEntityPostalAddress.addressLine1",
      "businessEntityPostalAddress.addressLine2",
      "businessEntityPostalAddress.suburb",
      "businessEntityPostalAddress.state",
      "businessEntityPostalAddress.postcode",
      "businessEntityPostalAddress.country",
    ];
    var masterOrg = this.form.get("organisationDetails");
    var publicOrg = this.originalForm.get("organisationDetails");

    var orgChanged = orgFields.some(((f) =>
      this.compareValues(
        masterOrg.get(f).value,
        publicOrg.get(f).value
      )) as any);

    // responsible officer

    var officerFields = [
      "title",
      "firstName",
      "lastName",
      "email",
      "primaryPhoneNumber",
      "secondaryPhoneNumber",
    ];
    var masterOfficer = this.form.get("responsibleOfficer");
    var publicOfficer = this.originalForm.get("responsibleOfficer");

    var officerChanged = officerFields.some(((f) =>
      this.compareValues(
        masterOfficer.get(f).value,
        publicOfficer.get(f).value
      )) as any);

    // lobbyists

    var lobbyistsChanged = this.anyChanges("lobbyists.lobbyists");

    // clients

    var clientsChanged = this.anyChanges("clients.clients");

    // owners

    var ownersChanged = this.anyChanges("owners.owners");

    var result = !(
      orgChanged ||
      officerChanged ||
      lobbyistsChanged ||
      clientsChanged ||
      ownersChanged
    );

    return result;
  }

  anyChanges(controlPath: string): boolean {
    var changedRecs = this.changedRecords(controlPath);
    var newRecs = this.newRecords(controlPath);
    var removedRecs = this.removedRecords(controlPath);

    return (
      (changedRecs != null && changedRecs.length > 0) ||
      (newRecs != null && newRecs.length > 0) ||
      (removedRecs != null && removedRecs.length > 0)
    );
  }

  printReview(a: any) {
    a.expandAll();
    setTimeout(() => window.print());
    setTimeout(() => a.collapseAll());
  }
}
