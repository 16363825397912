import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import * as fromRoot from './../../../reducers';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';

import { IUserPrincipal } from './../identity.models';

@Injectable()
export class AuthGuard implements CanActivate {
  private principal: IUserPrincipal;
  private config: any;

  constructor(private store: Store<fromRoot.IState>, private router: Router) {
    this.store.select(fromRoot.getUserPrincipal).subscribe(principal => this.principal = principal);
    this.store.select(fromRoot.getConfig).subscribe(config => this.config = config);
  }


  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var isAuthenticated = false;
    if (this.principal.isAuthenticated && this.principal.accessToken) {
      var expiration: Date = new Date(this.principal.accessToken.expiresOn);
      var now: Date = new Date();
      isAuthenticated = now <= expiration;
    }

    // User is not currently authenticated so so redirect to login page with the return url and return false
    if (!isAuthenticated) {
      this.router.navigate(['/user/signin']);
      return false;
    }
    return true;
  }
}