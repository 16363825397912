import { Component, OnInit } from '@angular/core';
import { IConfiguration } from 'app/modules/configuration/reducers';
import { Store } from '@ngrx/store';
import * as fromRoot from './../../../../reducers';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public config: IConfiguration;

  constructor(private store: Store<fromRoot.IState>) {
    this.store.select(fromRoot.getConfig).subscribe(config => this.config = config);
  }

  ngOnInit() {
  }

}
