import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MockBackend } from "@angular/http/testing";
import { BaseRequestOptions, HttpModule } from '@angular/http';
import { HttpClientModule, HttpXhrBackend } from '@angular/common/http'

import { NgxLoremIpsumModule } from 'ngx-lorem-ipsum';

import { AuthHttp } from "./auth-http"

import { MockBackendService } from './services/mock-backend.service';
import { PassThroughService } from './services/pass-through.service';
import { MockDataService } from './services/mock-data.service';
import { AuthControllerBackendService } from './services/auth-controller-backend.service';
import { ConfigControllerBackendService } from './services/config-controller-backend.service';
import { ReportingControllerBackendService } from './services/reporting-controller-backend.service';
import { ContactControllerBackendService } from './services/contact-controller-backend.service';
import { MyControllerBackendService } from './services/my-controller-backend.service';
import { ContentControllerBackendService } from './services/content-controller-backend.service';
import { AbnControllerBackendService } from './services/abn-controller-backend.service';

export const IS_REALHTTP_ENABLED: boolean = true;

export function httpFactory(xhrBackend, mockBackend, options) {
    if (IS_REALHTTP_ENABLED) {
        return new AuthHttp(xhrBackend, options);
    }

    return new AuthHttp(mockBackend, options);
}

export function realHttpFactory(xhrBackend, options) {
    return new AuthHttp(xhrBackend, options);
}

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        HttpModule,
        NgxLoremIpsumModule
    ],
    providers: [
        // Disable mock HTTP stuff - seems broken after angular upgrade
        //{ provide: REAL_HTTP, useFactory: realHttpFactory, deps: [ConnectionBackend, BaseRequestOptions] },
        //{ provide: Http, useFactory: httpFactory, deps: [ConnectionBackend, MockBackend, BaseRequestOptions] },
        MockBackend,
        HttpXhrBackend,
        BaseRequestOptions,
        MockBackendService,
        PassThroughService,
        MockDataService,
        AuthControllerBackendService,
        ConfigControllerBackendService,
        ReportingControllerBackendService,
        ContactControllerBackendService,
        MyControllerBackendService,
        ContentControllerBackendService,
        AbnControllerBackendService
    ]
})
export class MockHttpModule {
    constructor() { }
}
