import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

interface CrossFieldErrorMapping {
  formGroup: FormGroup,
  errorKey: string,
}

@Component({
  selector: 'app-field-validation-messages',
  templateUrl: './field-validation-messages.component.html',
  styleUrls: ['./field-validation-messages.component.scss']
})
export class FieldValidationMessagesComponent implements OnInit {

  @Input() field: AbstractControl;

  @Input() crossField: CrossFieldErrorMapping[];

  get hasErrorsAndIsTouched() {
    if (this.field.touched) {
      if (this.field.invalid) {
        return true;
      }

      if (this.crossField) {
        for (const mapping of this.crossField) {
          if (mapping.formGroup.invalid && mapping.formGroup.touched) {
            if (mapping.formGroup.errors != null && mapping.formGroup.errors[mapping.errorKey]) {
              return true;
            }
          }
        }
      }
    }

    return false;
  }

  constructor() { }

  ngOnInit() {
  }

}
