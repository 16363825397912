import { Component, OnInit } from '@angular/core';

import * as fromRoot from './../../../../reducers';
import { Store } from '@ngrx/store';

import { ReportingService } from './../../../organisation/services/reporting.service';
import { INotification } from "./../../../../reducers/notification";
import { CookieService } from "./../../../../services/cookie.service";

import * as moment from 'moment';

@Component({
  selector: 'app-server-notify',
  templateUrl: './server-notify.component.html',
  styleUrls: ['./server-notify.component.scss']
})
export class ServerNotifyComponent implements OnInit {

  public notification: INotification;

  constructor(private cookieService: CookieService, private store: Store<fromRoot.IState>, private reportingService: ReportingService) {
    this.reportingService.loadCurrentNotifications();
  }

  ngOnInit() {
    this.store.select(fromRoot.getNotification).subscribe(notification => {
      this.notification = notification;
    });
  }

  get showNotification(): boolean {
    return this.notification != null && this.notification.message != null && this.notification.type == "NOTIFY" && !this.cookieService.check("HideLobbyistOutageNotification");
  }

  hideNotification() {
    var currentDomain = window.location.hostname;
    if (currentDomain.indexOf("lobbyists.ag.gov.au") != -1) {
      currentDomain = ".lobbyists.ag.gov.au";
    } else if (currentDomain.indexOf(".ag.gov.au") != -1) {
      currentDomain = ".ag.gov.au";
    }

    this.cookieService.set("HideLobbyistOutageNotification", "true", moment(this.notification.endDate).toDate(), "/", currentDomain, false);
  }

}
