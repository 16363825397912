import { Component, OnInit } from '@angular/core';
import { ContentService } from "./../../services/content.service";
import { IFaqItem } from './../../content.models'

@Component({
    selector: 'app-faqs',
    templateUrl: './faqs.component.html',
    styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {

    public faqs: IFaqItem[] = [];
    public isLoading: boolean;

    constructor(private contentService: ContentService) { }

    ngOnInit() {
        this.isLoading = true;
        this.contentService.getFaqs().subscribe(faqs => {
            this.isLoading = false;
            this.faqs = faqs;
        });
    }

}
