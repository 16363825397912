import { Injectable } from "@angular/core";
import {
  Http,
  Response,
  Headers,
  RequestOptions,
  ResponseContentType,
} from "@angular/http";

import { Observable } from "rxjs/Observable";
import "rxjs/Rx";

import * as fromRoot from "./../../../reducers";
import { Store } from "@ngrx/store";

import { IContent, INewsItem, IFaqItem } from "./../content.models";

@Injectable()
export class ContentService {
  private config: any;

  constructor(private store: Store<fromRoot.IState>, private http: Http) {
    this.store
      .select(fromRoot.getConfig)
      .subscribe((config) => (this.config = config));
  }

  public getContent(id: string): Observable<IContent> {
    return this.http
      .get(this.config.api.content + "content/" + id)
      .map((response) => response.json())
      .map((content) => <IContent>content);
  }

  public getNews(): Observable<INewsItem[]> {
    return this.http
      .get(this.config.api.content + "content/news")
      .map((response) => response.json())
      .map((content) => <INewsItem[]>content);
  }

  public getFaqs(): Observable<IFaqItem[]> {
    return this.http
      .get(this.config.api.content + "content/faqs")
      .map((response) => response.json())
      .map((content) => <IFaqItem[]>content);
  }

  public initDocumentDownload(id: string, type: string) {
    let actionUrl =
      this.config.api.content + "content/" + id + "/documents/" + type;
    return this.http
      .get(actionUrl, { responseType: ResponseContentType.Blob })
      .map(
        (response) =>
          new Blob([response.blob()], {
            type: response.headers.get("Content-Type"),
          })
      );
  }
}
