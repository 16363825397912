import { Action } from '@ngrx/store';

const initialState: Object = new Object();

export const FORM_SELECTED = 'FORM_SELECTED';

export function reducer(state: any = initialState, action: UnsafeAction) {
  switch (action.type) {
    case FORM_SELECTED:
      if(action.payload == null) {
        state = { ...initialState };         
      } else {
        state = { ...action.payload };
      }
      
      return state;
    default:
      return state;
  }
}

export class UnsafeAction implements Action {
  payload?: any;
  type: string;
}
