import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, AbstractControl, FormArray } from "@angular/forms";
import { ILookup } from "app/modules/organisation/services/registration-form.service";
import { GoogleAnalyticsService } from "../../../../../services/google-analytics.service";
import { Subject, Observable } from "rxjs";
import {
  IFormSubstep,
  RegistrationSubsteps,
} from "app/modules/organisation/models";
import { Store } from "@ngrx/store";
import * as fromRoot from "./../../../../../reducers";

@Component({
  selector: "app-step-responsible-officer",
  templateUrl: "./step-responsible-officer.component.html",
  styleUrls: ["./step-responsible-officer.component.scss"],
})
export class StepResponsibleOfficerComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() officersAdd: VoidFunction;
  @Input() officersRemove: (clientId: string) => void;
  @Input() titles: ILookup[] = [];
  @Input() onSaved: Subject<void>;

  @Input() activeSubstep: RegistrationSubsteps;
  @Input() onSubstepChange: Subject<string>;

  @Output() officerStepChanged = new EventEmitter<IFormSubstep>();

  @Output() resendActivationEmail = new EventEmitter<FormGroup>();

  public officerId: string = null;
  public officerTotal: number = -1;

  private officerDescription: string = null;
  private unsubscribe$: Subject<void> = new Subject();

  @Input() sortList: (
    list: AbstractControl[],
    column: string,
    isAsc: boolean
  ) => void;

  public get officerForm(): AbstractControl {
    var officerCollection = this.form;

    if (this.officerId == null) {
      return officerCollection;
    } else {
      var officerList = <FormArray>officerCollection.get("responsibleOfficers");
      var officer = officerList.controls.find(
        (c) => c.get("id").value == this.officerId
      );

      if (officer == null) {
        // id has changed because new record was saved
        // try find by description
        officer = officerList.controls.find(
          (c) => c.get("description").value == this.officerDescription
        );

        // otherwise, new client should be at end of list
        if (officer == null) {
          officer = officerList.controls[officerList.length - 1];
        }

        this.officerId = officer.get("id").value;
      }

      this.officerDescription = officer.get("description").value;
      officer
        .get("description")
        .valueChanges.takeUntil(this.unsubscribe$)
        .subscribe(
          () => (this.officerDescription = officer.get("description").value)
        );

      return officer;
    }
  }

  public get currentOfficerId(): string {
    return this.form.parent.get("responsibleOfficer").get("id").value;
  }

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private store: Store<fromRoot.IState>
  ) {}

  public isEditable() {
    return this.form.get("status.status").value == "Draft";
  }

  public enableEditing() {
    this.form.patchValue({
      status: { status: "Draft" },
    });
  }

  ngOnInit() {
    this.onSubstepChange.subscribe((newSubstep) => {
      this.activeSubstep = RegistrationSubsteps[newSubstep];
      if (this.activeSubstep === RegistrationSubsteps["Officers list"]) {
        this.clearSelection();
      }
    });

    this.onSaved.subscribe(() => {
      this.sortList(
        (<FormArray>this.form.get("responsibleOfficers")).controls,
        "description",
        true
      );
    });

    this.googleAnalyticsService.logPageVisit("step-responsible-officer");
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectOfficer(officerId: string) {
    this.unsubscribe$.next();

    this.officerId = officerId;
    var selectedOfficer = (<FormArray>(
      this.form.get("responsibleOfficers")
    )).controls.find((c) => c.get("id").value == this.officerId);

    selectedOfficer.get("status").patchValue({
      status: "Draft",
      statusDescription: "Not yet submitted",
    });

    const loggedInOfficerId = this.store
      .select(fromRoot.getUserPrincipal)
      .subscribe((principal) => {
        return principal.hasSeenTutorial;
      });

    if (selectedOfficer.get("id").value === loggedInOfficerId) {
      selectedOfficer.get("hasSeenTutorial").patchValue(true);
    }

    if (
      selectedOfficer.get("sendNotifications").value &&
      this.lastOfficerReceivingNotifications
    ) {
      selectedOfficer.get("sendNotifications").disable({ onlySelf: true });
    }

    this.activeSubstep = RegistrationSubsteps["Add officer/s"];
    this.officerStepChanged.emit({
      name: this.activeSubstep,
      form: this.officerForm,
    });
  }

  selectNewOfficer() {
    this.unsubscribe$.next();

    var officers = <FormArray>this.form.get("responsibleOfficers");
    this.officerId = officers.controls[officers.length - 1].get("id").value;

    this.activeSubstep = RegistrationSubsteps["Add officer/s"];
    this.officerStepChanged.emit({
      name: this.activeSubstep,
      form: this.officerForm,
    });
  }

  clearSelection() {
    this.officerId = null;
  }

  public sortColumns = {
    description: true,
    email: true,
  };

  sort(column: string) {
    this.sortColumns[column] = !this.sortColumns[column];
    this.sortList(
      (<FormArray>this.form.get("responsibleOfficers")).controls,
      column,
      this.sortColumns[column]
    );
  }

  getStatusDescription(officer: AbstractControl) {
    var status = officer.get("emailVerificationStatus").value;

    switch (status) {
      case "Accepted":
        return "Accepted";
      case "Expired":
        return "Expired";
      case "Confirmationpending":
        return "Confirmation pending";
    }
  }

  resend(officer: FormGroup) {
    this.resendActivationEmail.emit(officer);
  }

  get lastOfficerReceivingNotifications(): Boolean {
    return (
      (<FormArray>this.form.get("responsibleOfficers")).controls.filter(
        (l) =>
          !(l.get("forDeletion") && l.get("forDeletion").value) &&
          l.get("sendNotifications").value
      ).length == 1
    );
  }

  canDelete(officer: FormGroup) {
    return (
      officer.get("id").value != this.currentOfficerId &&
      this.getStatusDescription(officer) != "Confirmation pending"
    );
  }
}
