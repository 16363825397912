import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from "./../../services/content.service";
import { IContent, IAttachment, IDocument } from './../../content.models'

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss']
})

export class ContentComponent implements OnInit {
    public content: IContent = { title: "", markup: "", type: "" };
    public isLoading: boolean;

    @Input() id: string;
    
    constructor(private route: ActivatedRoute, private contentService: ContentService) { }

    ngOnInit() {
        if (!this.id) {
            this.id = this.route.snapshot.data['id'];
        }

        this.isLoading = true;
        this.contentService.getContent(this.id).subscribe(content => {
            this.isLoading = false;
            this.content = content;
        });
    }

    
}