import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';

import * as fromRoot from './../../../reducers';
import { Store } from '@ngrx/store';

import { IConfiguration } from './../../../modules/configuration/reducers'
import { ILookup, IStatDecFile } from './registration-form.service';
import { map } from 'rxjs/operators';
import { IContactResponse } from 'app/modules/controls/services/contact.service';

@Injectable()
export class BreachService {
    private config: IConfiguration;
  titles$: Observable<ILookup[]>;
  relations$: Observable<ILookup[]>;
  codes$: Observable<ILookup[]>;
  codeParts$: Observable<ILookup[]>;

  constructor(private store: Store<fromRoot.IState>, private http: HttpClient) {
    this.store.select(fromRoot.getConfig).subscribe(config => {
      this.config = config;
    });

    this.titles$ = this.http.get(this.config.api.organisation + "my/lookup/titles").pipe(
      map((response: any) => <ILookup[]>response));

    this.relations$ = this.http.get(this.config.api.organisation + "my/lookup/relationshipToBreach").pipe(
      map((response: any) => <ILookup[]>response));

    this.codes$ = this.http.get(this.config.api.organisation + "my/lookup/codeOfConductBreached").pipe(
      map((response: any) => <ILookup[]>response));

    this.codeParts$ = this.http.get(this.config.api.organisation + "my/lookup/codeOfConductPartBreached").pipe(
      map((response: any) => <ILookup[]>response));
  }

  public submitBreachReport(request: IBreachReport, attachments: IStatDecFile[]): Observable<IContactResponse> {
    var formData = new FormData;
    for (let attachment of attachments) {
      formData.append(`attachments[${attachment.lobbyistIndex}]`, attachment.blob, attachment.fileName);
    }

    formData.append('data', JSON.stringify(request));

    return this.http.post(this.config.api.organisation + "breach", formData)
      .map(response => <IContactResponse>response);
    }

}

export interface IBreachReport {
    reporterDetails: {
      isAnonymous: boolean,
      title: string,
      firstName: string,
      lastName: string,
      email: string,
      primaryPhoneNumber: string
  },

  selectedEntityType: {
    isLobbyist: boolean
  },

  selectedEntity: {
    organisation: {
      organisationId: string,
      organisationName: string
    },
    lobbyists: {
      identifyLobbyists: boolean,
      lobbyists: [],
    }
  },

  breachDetails: {
    basicDetails: {
      dateOfBreach: string,
      dateNotKnown: boolean,
      relationshipToBreach: string,
      relationshipToBreachOther: string,
      locationOfBreach: string,
      howBecameAwareOfBreach: string
    },
    breachDetails: {
      codeOfConductSectionBreached: string,
      codeOfConductSectionBreachedOther: string,
      descriptionOfBreach: string,
      attachments: []
    },
    furtherContact: {
      furtherContact: boolean,
      recaptchaResponse: string
    }
  }

}


