import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, ResponseContentType } from '@angular/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';

@Injectable()
export class DocumentService {

    constructor(private http: Http) { }

  public initDocumentDownload(documentUrl: string) {
      return this.http.get(documentUrl, { responseType: ResponseContentType.Blob })
          .map(response => new Blob([response.blob()], { type: response.headers.get('Content-Type') }));
  }
}
