import { Injectable, isDevMode, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
    Http,
    Response,
    Headers,
    RequestOptions,
    ResponseContentType,
    RequestOptionsArgs,
} from "@angular/http";

import { Observable } from "rxjs/Observable";
import "rxjs/Rx";

import * as fromRoot from "../../../reducers";
import { Store } from "@ngrx/store";

import { IUserPrincipal, IUserProfile } from "../identity.models";

import {
    ReportingService,
    IOrganisationSummary,
} from "../../organisation/services/reporting.service";
import { AuthGuard } from "../gaurds/auth.gaurd";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ɵangular_packages_platform_browser_dynamic_platform_browser_dynamic_a } from "@angular/platform-browser-dynamic";

// MSAL Authentication
import { PublicClientApplication, SilentRequest, AuthenticationResult, Configuration, LogLevel, AccountInfo, InteractionRequiredAuthError, RedirectRequest, PopupRequest, EndSessionRequest, SsoSilentRequest } from "@azure/msal-browser";
import { msalConfig, appConfig } from "../config/auth.config";
import { resolve } from "url";
import { async } from "@angular/core/testing";
import { IBearerToken } from "../identity.models";
import { ofType } from "@ngrx/effects";
import { map, take } from "rxjs/operators";
import { pipe } from "rxjs/Rx";
import { access } from "fs";
import { IConfiguration } from "app/modules/configuration/reducers";
import { GUID } from "app/utils/guid";

export const loginRequest = {
    scopes: ['openid', 'profile'],
};

@Injectable()
export class AuthService {
    private config: any;
    public principal: IUserPrincipal;
    private msalApplication: PublicClientApplication;
    public account?: AccountInfo | null;
    public token?;

    constructor(
        private store: Store<fromRoot.IState>,
        private http: HttpClient,
        private router: Router,
        private reportingService: ReportingService,
    ) {
        this.store
            .select(fromRoot.getUserPrincipal)
            .subscribe((principal) => (this.principal = principal));

        this.http
            .get("/api/configuration")
            .subscribe((config) => {
                this.config = config
            });

        // console.log(`this.config ${JSON.stringify(this.config)}`);
        
        this.msalApplication = new PublicClientApplication(msalConfig);
    }

    async ngOnInit() {
    }

    public register() {
        // navigate to register page
        window.location.href = `${appConfig.earsUrl}/${appConfig.clientId}`;
    }

    public updatePassword() {
        // navigate to update password
        window.location.href = `${appConfig.authority}/portal/updatepassword/${appConfig.clientId}`
    }

    public forgot() {
        //navigate to reset link
        window.location.href = `${appConfig.earsUrl}/forgotpassword/${appConfig.clientId}`;
    }

    async login() {
        let loginRedirectRequestPayload = {
            scopes: loginRequest.scopes,
            prompt: 'select_account',
            redirectUri: appConfig.redirectUri,
        }

        // this will redirect the web application to the Microsoft Identity platform sign in pages.
        // no code will execute after this point.
        return await this.msalApplication.loginRedirect(loginRedirectRequestPayload)
    }

    async handlePageLoadEvent() {
        const authResult = await this.msalApplication.handleRedirectPromise()
        return this.handleRedirectResponse(authResult)
    }

    async handleRedirectResponse(authResult) {
        console.log(`Handling redirect response ${JSON.stringify(authResult)}`);

        // if this page load is redirect from the Microsoft Identity platform then the
        // authResult will be populated. Otherwise null on other page loads.

        if (authResult !== null) {
            // save the fresh account info from the result.
            this.account = authResult.account;
            console.log(`Account saved ${JSON.stringify(this.account)}`);
        } else {
            // see if we have cached accounts.
            const currentAccounts = this.msalApplication.getAllAccounts()

            if (currentAccounts === null) {
                console.log(`No existing accounts`);
                // no cached accounts.
                // user will need to click the sign-in button and redirect to login.
                return
            } else if (currentAccounts.length > 1) {
                // there are some situations where the user may have multiple (different) cached logins.
                // this code sample does not cover that scenario but just logs a warning here.
                // this conditional block would need to be updated to support multiple accounts.
                // otherwise it will just grab the first one below.
                // eslint-disable-next-line no-console
                console.warn('Multiple accounts detected in MSAL account cache.')
                this.account = currentAccounts[0]
            } else if (currentAccounts.length === 1) {
                // we have exactly 1 cached account.
                // set the account info. user may not need to sign in.
                this.account = currentAccounts[0]
                console.log(`Setting account with existing`);
            }
        }

        if (this.account && this.account != null && this.account != undefined) {
            const token = this.token = await this.getToken();

            var accessToken = {
                token: token.accessToken,
                expiresOn: token.expiresOn,
                type: token.tokenType,
                resourceId: token.tenantId
            } as IBearerToken;

            let profile = {
                title: undefined,
                lastName: this.account.idTokenClaims.family_name,
                firstName: this.account.idTokenClaims.given_name,
                email: this.account.idTokenClaims.email,
                hasSeenTutorial: false,
                subjectId: this.account.idTokenClaims.sid,
                earsUpn: this.account.idTokenClaims.upn,
            } as IUserProfile;

            let finishedProfile = await this.furnishProfile(profile, accessToken);
            if (finishedProfile.isAuthenticated == true){
                this.router.navigate(['/user/dashboard']);
            } else {
                this.router.navigate(['/user/signin-failed']);
            }
            this.store.dispatch({
                type: "SIGNIN",
                payload: finishedProfile,
            });
        }

        return this.account;
    }

    public async furnishProfile(profile: IUserProfile, accessToken: IBearerToken) {

        if (!this.config) {
            this.config = await fetch('/api/configuration').then(res => res.json());
            console.log(`Second attempt config ${JSON.stringify(this.config)}`)
        }

        const request = new Request(this.config.api.organisation + "lobbyists/account/user/ensure", {
            method: "POST",
            headers: {
                "Authorization": `Bearer ${accessToken.token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(profile),
        });
        const objectResponse = await fetch(request);
        const response = await objectResponse.json();
        if (response.subjectId == null){ // If User is Inactive.
            return <IUserPrincipal>{
                isAuthenticated: false,
                email: null,
                displayName: null,
                accessToken: null,
                hasSeenTutorial: null,
                subjectId: null,
                earsUpn: null,
            };
        } else {
            return {
                isAuthenticated: true,
                email: response.email,
                displayName: `${response.firstName} ${response.lastName}`,
                accessToken,
                hasSeenTutorial: response.hasSeenTutorial,
                subjectId: response.subjectId,
                earsUpn: response.EARSUPN
            } as IUserPrincipal;
        }
    }

    public async getToken(): Promise<AuthenticationResult> {

        if (!this.account) {
            console.log(`Getting Token - No Account`);
            return
        }

        try {
            const tokenRequest = {
                account: this.account,
                scopes: loginRequest.scopes,
                authority: appConfig.authority
            }
            // msal will return the cached token if present, or call to get a new one
            // if it is expired or near expiring.
            return await this.msalApplication.acquireTokenSilent(tokenRequest)
        } catch (error) {
            console.log(`Getting Token Error ${JSON.stringify(error)}`);

            if (error instanceof InteractionRequiredAuthError) {
                const tokenRedirectRequestPayload = {
                    scopes: loginRequest.scopes,
                    prompt: 'select_account',
                    redirectUri: appConfig.redirectUri
                }

                await this.msalApplication.acquireTokenRedirect(tokenRedirectRequestPayload)
            } else if (error.errorCode === 'no_tokens_found') {
                console.log(`token error ${JSON.stringify(error)}`);
                // Redirect to sign in if get token failed
                window.location.href = '/user/signin'
            }
        }
    }

    /**
     * Logs out of current account.
     */
    public async logout() {
        const account: AccountInfo = this.msalApplication.getAccountByHomeId(this.account.homeAccountId);
        await this.msalApplication.logoutRedirect({
            account,
            postLogoutRedirectUri: appConfig.postLogoutRedirectUri,
            // mainWindowRedirectUri: appConfig.postLogoutRedirectUri,
            // onRedirectNavigate: appConfig.postLogoutRedirectUripopupWindowAttributes: {
            //     popupSize: {
            //         height: 250,
            //         width: 250
            //     },
            //     popupPosition: {
            //         top: 100,
            //         left: 100
            //     }
            // }
        });

        this.store.dispatch({ // Reset User Principal Store
            type: "SIGNIN",
            payload: <IUserPrincipal>{
                isAuthenticated: false,
                email: null,
                displayName: null,
                accessToken: null,
                earsUpn: null,
            },
        });
    }

    public createAuthorizationHttpHeaders() {
        return new HttpHeaders({
            Authorization: `Bearer ${this.principal.accessToken.token}`,
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "Sat, 01 Jan 2000 00:00:00 GMT",
        });
    }

    public createAuthorizationHttpHeader(headers: Headers): Promise<any> {
        return new Promise((resolve, reject) => {
            headers.append(
                "Authorization",
                `Bearer ${this.principal.accessToken.token}`
            );
            resolve(true);
        });
    }

    public createAuthorizationHeader(headers: HttpHeaders): Promise<any> {
        return new Promise((resolve, reject) => {
            headers.append(
                "Authorization",
                "Bearer " + this.principal.accessToken.token
            );
            resolve(true);
        });
    }

    public createXhrAuthorizationHeader(xhr: XMLHttpRequest): Promise<any> {
        return new Promise((resolve, reject) => {
            xhr.setRequestHeader(
                "Authorization",
                "Bearer " + this.principal.accessToken.token
            );
            resolve(true);
        });
    }
}