import { Component, OnInit } from '@angular/core';
import { PrintService } from 'app/modules/organisation/services/print.service';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})
export class ChecklistComponent implements OnInit {

  _printService: PrintService;

  constructor(private printService: PrintService) {
    this._printService = printService;
  }

  ngOnInit() {
  }

  print(): void {
    this._printService.requestPrint("");
  }
}
