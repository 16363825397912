import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { ISearchResponse } from './../../../organisation/services/reporting.service';
import { ISearchRequest } from './../../../organisation/services/search-request';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
    pages: number[];
    private totalPages: number = 0;

    private searchResult: any;

  public get viewCount(): number {
    if (this.totalRecords > 0 && this.totalRecords < this.searchRequest.count) {
      return this.totalRecords;
    } else {
      return Math.min(this.totalRecords, this.searchRequest.count * this.searchRequest.pageNumber);
    }
  }

  public get totalRecords(): number {
    return this.searchResult.totalRecords;
  }

    onPageClicked(event, pageNumber: number) {
        event.preventDefault();
        this.searchRequest.pageNumber = pageNumber;
        this.searchRequest.pagingCookie = this.searchResult.pagingCookie;
        this.onPageChanged.emit(this.searchRequest);
    }

    onNextPageClicked(event) {
        this.onPageClicked(event, this.searchRequest.pageNumber + 1);
    }

    onPreviousPageClicked(event) {
        this.onPageClicked(event, this.searchRequest.pageNumber - 1);
    }

    onFirstPageClicked(event) {
        this.onPageClicked(event, 1);
    }

    onLastPageClicked(event) {
        this.onPageClicked(event, this.totalPages);
    }

    @Input() searchRequest: ISearchRequest;

    @Output() onPageChanged = new EventEmitter<ISearchRequest>();

    constructor() { }

    public resultChanged(searchResult: any) {
        this.searchResult = searchResult;
        this.buildPages();
    }

    buildPages() {
        if (this.searchResult) {
            this.totalPages = Math.ceil(this.searchResult.totalRecords / this.searchResult.count);

            let startPage: number = 1;
            let endPage: number = 0;

            if (this.searchResult.pageNumber < 1) {
                this.searchResult.pageNumber = 1;
            }
            else if (this.searchResult.pageNumber > this.totalPages) {
                this.searchResult.pageNumber = this.totalPages;
            }

            let totalPagesToDisplay: number = 10;
            let derivedStartPage: number = this.searchResult.pageNumber - (totalPagesToDisplay / 2);
            if (derivedStartPage > startPage) {
                startPage = derivedStartPage;
            }

            endPage = (startPage + totalPagesToDisplay) - 1;
            if (endPage > this.totalPages) {
                endPage = this.totalPages;
            }

            this.pages = [];

            for (let i = startPage; i <= endPage; i++) {
                this.pages.push(i);
            }
        }
    }

    ngOnInit() {
        this.buildPages();
    }
}
