import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { IUserPrincipal } from './../modules/identity/identity.models';
import * as fromIdentity from './../modules/identity/reducers';

import { IMyProfileStatus } from './../modules/organisation/models';
import * as fromProfileStatus from './../modules/organisation/reducers';

import { INotification } from './notification';
import * as fromLayout from './notification';

import * as fromDashboardNotifications from './dashboard-notifications';

import * as fromReportingPeriodStatus from './reporting-period-status';

import * as fromSelectedFormData from './selected-form-data';

import * as fromConfiguration from './../modules/configuration/reducers';

import { IReportingPeriodStatus } from "app/modules/organisation/services/registration-form.service";

export interface IState {
  userPrincipal: IUserPrincipal;
  myProfileStatus: IMyProfileStatus;
  notification: INotification;
  configuration: fromConfiguration.IConfiguration;
  dashboardNotifications: fromDashboardNotifications.IDashboardNotifications;
  reportingPeriodStatus: IReportingPeriodStatus;
  selectedFormData: any;
}

const reducers = {
  userPrincipal: fromIdentity.reducer,
  myProfileStatus: fromProfileStatus.reducer,
  notification: fromLayout.reducer,
  configuration: fromConfiguration.reducer,
  dashboardNotifications: fromDashboardNotifications.reducer,
  reportingPeriodStatus: fromReportingPeriodStatus.reducer,
  selectedFormData: fromSelectedFormData.reducer
};

export const reducer: ActionReducerMap<IState> = reducers;

export const getConfig = (state: IState) => state.configuration;

export const getUserPrincipal = (state: IState) => state.userPrincipal;

export const getMyProfileStatus = (state: IState) => state.myProfileStatus;

export const getNotification = (state: IState) => state.notification;

export const getDashboardNotifications = (state: IState) => state.dashboardNotifications;

export const getReportingPeriodStatus = (state: IState) => state.reportingPeriodStatus;

export const getSelectedFormData = (state: IState) => state.selectedFormData;
