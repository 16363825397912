import { Injectable } from '@angular/core';
import { Http, Response, ResponseOptions } from '@angular/http';
import { Observable } from "rxjs";

import { MockConnection } from "@angular/http/testing";

@Injectable()
export class MockDataService {

    constructor() { }

    public getMockData(connection: MockConnection, action: string, realHttp: Http): Observable<void> {
        let url = "/assets/mocks" + action + ".json";

        const responseObservable: Observable<void> = realHttp.get(url).map(
            response => {
                this.onSuccess(connection, response);
            },
            error => {
                this.onSuccess(connection, error);
            }
        );

        return responseObservable;
    }

    private onSuccess(connection: MockConnection, response: Response): void {
        connection.mockRespond(response);
    }

    private onError(connection: MockConnection, response: Response): void {
        const response2 = new Response(new ResponseOptions({
            type: response.type,
            status: response.status,
            statusText: response.statusText,
            url: response.url,
            body: response.text()
        }));

        connection.mockRespond(response2);
    }
}
