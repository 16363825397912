import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HistoryService } from "./services/history.service";
import { HistoryComponent } from "./components/history/history.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PopoverModule } from "ngx-bootstrap/popover";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ControlsModule } from "../controls/controls.module";
import { ContentModule } from "../content/content.module";
import { WizardModule } from "../wizard/wizard.module";
import { BlankSubstitutePipe } from "../pipes/blank-substitute-pipe";
import { PipeModule } from "../pipes/pipes.module";

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    PopoverModule.forRoot(),
    AccordionModule.forRoot(),
    TooltipModule.forRoot(),
    NgbModule,
    ControlsModule,
    WizardModule,
    ContentModule,
    PipeModule.forRoot(),
  ],
  exports: [HistoryComponent],
  declarations: [HistoryComponent],
  providers: [HistoryService],
})
export class HistoryModule {}
