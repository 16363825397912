import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";

import { IdentityModule } from "./../identity/identity.module";
import { ControlsModule } from "./../controls/controls.module";

import { MasterLayoutComponent } from "./components/master-layout/master-layout.component";
import { HeaderComponent } from "./components/header/header.component";
import { PageHeaderComponent } from "./components/page-header/page-header.component";
import { SideNavComponent } from "./components/side-nav/side-nav.component";
import { ContentCanvasComponent } from "./components/content-canvas/content-canvas.component";
import { BottomBarComponent } from "./components/bottom-bar/bottom-bar.component";
import { FooterComponent } from "./components/footer/footer.component";
import { BreadcrumbsComponent } from "./components/breadcrumbs/breadcrumbs.component";
import { PageOutputComponent } from "./components/page-output/page-output.component";
import { AuthBarComponent } from "./components/auth-bar/auth-bar.component";
import { NotificationComponent } from "./components/notification/notification.component";
import { JoyrideModule } from "ngx-joyride";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    JoyrideModule.forRoot(),
    ControlsModule,
    IdentityModule,
    NgbModule,
  ],
  exports: [MasterLayoutComponent],
  declarations: [
    MasterLayoutComponent,
    HeaderComponent,
    PageHeaderComponent,
    SideNavComponent,
    ContentCanvasComponent,
    BottomBarComponent,
    FooterComponent,
    BreadcrumbsComponent,
    PageOutputComponent,
    AuthBarComponent,
    NotificationComponent,
  ],
})
export class LayoutModule {}
