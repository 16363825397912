import { Inject, Injectable } from '@angular/core';
import { Http, Response, ResponseOptions } from '@angular/http';
import { MockBackend, MockConnection } from "@angular/http/testing";

import  {  NgxLoremIpsumService  }  from  'ngx-lorem-ipsum';

import { Observable } from "rxjs";

import { MockBackendService } from './mock-backend.service';
import { MockDataService } from './mock-data.service';
import { IRequestRouter } from './request-router';

@Injectable()
export class ContentControllerBackendService implements IRequestRouter {

    constructor(private mockDataService: MockDataService, private mockContentService:  NgxLoremIpsumService) { }

    public routeRequest(connection: MockConnection, realHttp: Http): Observable<void> {
        let url = connection.request.url;

        if (!!url) {
            let organisationApi = "http://localhost:9000/";
            if (url.startsWith(organisationApi)) {
                url = "/" + url.substring(organisationApi.length);
                if (url.startsWith("/content/news") || url.startsWith("/content/faqs")) {
                    return this.mockDataService.getMockData(connection, url, realHttp);
                }

                if (url.startsWith("/content")) {
                    return this.getMockContent(connection, url, realHttp);
                }
            }
        }

        throw new Error("Mock Error: Mock not implemented for - " + url);
    }

    public getMockContent(connection: MockConnection, action: string, realHttp: Http): Observable<void> {
        const response2 = new Response(new ResponseOptions({
            status: 200,
            statusText: 'OK',
            url: action,
            body: JSON.stringify({
                text: null,
                markup: this.mockContentService.getRandom(15, 50, 5)
            })
        }));

        return Observable.of(connection.mockRespond(response2));
    }
}
