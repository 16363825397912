import { Component, OnInit, Input } from '@angular/core';
import { ISideNavItem } from "./../../layout.models";

@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

    @Input() navItems: ISideNavItem[];

    constructor() { }

    ngOnInit() {
    }

}
