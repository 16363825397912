import { Component, OnInit, ElementRef, ViewChild, Input } from "@angular/core";

import {
  HistoryService,
  IOrganisationHistory,
  IOrganisationSummary,
  ILobbyist,
  IClient,
} from "../../services/history.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import moment = require("moment");
import { ILookup } from "../../../organisation/services/registration-form.service";
import { IStakeholder, recordEntity } from "../../../organisation/models";

@Component({
  selector: "app-history",
  templateUrl: "./history.component.html",
  styleUrls: ["./history.component.scss"],
})
export class HistoryComponent implements OnInit {
  @ViewChild("modalHistory", { read: false, static: false })
  modalHistory: ElementRef;

  public history: IOrganisationHistory;
  public hasHistory: boolean = true;
  public historyYears: ILookup[];
  public sortColumns: Record<string, Record<string, Boolean>>;
  public selectedYear: string;

  @Input() isDeregisteredOrganisation: boolean;

  public organisationHistory: IOrganisationSummary[];

  constructor(
    private historyService: HistoryService,
    private modalService: NgbModal
  ) {
    this.sortColumns = {
      organisation: {},
      lobbyist: {},
      client: {},
      stakeholders: {},
    };
  }

  ngOnInit() {
    this.historyService.historyAvailable$.subscribe((history) => {
      // avoid infinite loading looping.
      // todo - discuss with team a design for this kind of pattern.
      try {
        this.watchHistory(history);
      } catch (error) {
        this.modalService.dismissAll();
        console.log("error", error);
      }
    });
  }

  public watchHistory(history: IOrganisationHistory) {
    this.history = this.filterJunkRecords(history);

    if (this.history) {
      if (this.history.clients && this.history.clients.length > 0) {
        // a) get column names to set up sorting table
        // b) run a default sort by name
        Object.keys(this.history.clients[0]).forEach(
          (k) => (this.sortColumns["client"][k] = false)
        );
        this.sort(recordEntity.client, "displayName");
      }
      if (this.history.lobbyists && this.history.lobbyists.length > 0) {
        Object.keys(this.history.lobbyists[0]).forEach(
          (k) => (this.sortColumns["lobbyist"][k] = false)
        );
        this.sort(recordEntity.lobbyist, "displayName");
      }
      if (this.organisationHistory && this.organisationHistory.length > 0) {
        Object.keys(this.organisationHistory[0]).forEach(
          (k) => (this.sortColumns["organisation"][k] = false)
        );
        this.sort(recordEntity.organisation, "displayName");
      }
      if (this.history.stakeholders && this.history.stakeholders.length > 0) {
        Object.keys(this.history.stakeholders[0]).forEach(
          (k) => (this.sortColumns["stakeholders"][k] = false)
        );
        this.sort(recordEntity.stakeholders, "displayName");
      }
    }

    let generatedYears = [];

    for (let year = 2020; year < new Date().getFullYear() + 1; year++) {
      generatedYears.push({
        text: `${year}`,
        value: year,
      });
    }

    // put my thing down flip it and reverse it
    // want the generated years to be in descending order
    this.historyYears = [this.allLookup, ...generatedYears.reverse()];

    this.modalService.dismissAll();

    this.modalService.open(this.modalHistory, {
      keyboard: true,
      scrollable: true,
      size: "xl",
    });
  }

  private allLookup = {
    text: "All",
    value: "all",
  };

  public onYearChange(year: string) {
    this.selectedYear = year;
  }

  // was record visible on register as at selected date?
  public recordInDateRange(
    recordDate: Date,
    recordType: recordEntity
  ): boolean {
    let allDates: moment.Moment[];

    // short circuit for 'all' selection
    if (isNaN(+this.selectedYear)) return true;

    switch (recordType) {
      case recordEntity["organisation"]:
        if (this.organisationHistory)
          allDates = this.organisationHistory.map((o) =>
            moment(o.dateDeregistered)
          );
        break;
      case recordEntity["lobbyist"]:
        if (this.history.lobbyists)
          allDates = this.history.lobbyists.map((l) =>
            moment(l.dateDeregistered)
          );
        break;
      case recordEntity["client"]:
        if (this.history.clients)
          allDates = this.history.clients.map((c) =>
            moment(c.dateDeregistered)
          );
        break;
      case recordEntity["stakeholders"]:
        if (this.history.stakeholders)
          allDates = this.history.stakeholders.map((s) =>
            moment(s.dateDeregistered)
          );
        break;
    }

    let recordYear = moment(recordDate).year();
    return recordYear.toString() === this.selectedYear;
  }

  public noRecordsInDateRange(
    recordCollection: any[],
    recordType: string
  ): boolean {
    if (!this.hasHistory) return true;

    return (
      !recordCollection ||
      recordCollection.length === 0 ||
      !recordCollection.some((record) =>
        this.recordInDateRange(
          record.dateDeregistered,
          recordEntity[recordType]
        )
      )
    );
  }

  public sort(recordType: recordEntity, column: string) {
    this.sortColumns[recordType][column] = !this.sortColumns[recordType][
      column
    ];
    let isAsc = this.sortColumns[recordType][column];

    let dataset:
      | IOrganisationSummary[]
      | ILobbyist[]
      | IClient[]
      | IStakeholder[];

    switch (recordType) {
      case recordEntity["organisation"]:
        dataset = this.organisationHistory;
        break;
      case recordEntity["lobbyist"]:
        dataset = this.history.lobbyists;
        break;
      case recordEntity["client"]:
        dataset = this.history.clients;
        break;
      case recordEntity["stakeholders"]:
        dataset = this.history.stakeholders;
        break;
    }

    if (
      [
        "cessationDate",
        "modifiedOn",
        "datePublished",
        "registeredOn",
        "dateDeregistered",
      ].indexOf(column) != -1
    ) {
      dataset.sort((a, b) => {
        let aC: moment.Moment;
        let bC: moment.Moment;

        if (isAsc) {
          aC = moment(a[column]);
          bC = moment(b[column]);
        } else {
          bC = moment(a[column]);
          aC = moment(b[column]);
        }
        return aC.valueOf() - bC.valueOf();
      });
    } else {
      dataset.sort((a, b) => {
        let aValue: any;
        let bValue: any;

        if (column === "isFormerRepresentative") {
          aValue = <Boolean>a[column] ? "yes" : "no";
          bValue = <Boolean>b[column] ? "yes" : "no";
        } else {
          aValue = a[column];
          bValue = b[column];
        }

        if (aValue === null || aValue === undefined) {
          return -1;
        } else if (bValue === null || bValue === undefined) {
          return 1;
        } else if (
          (aValue === null || aValue === undefined) &&
          (bValue === null || bValue === undefined)
        ) {
          return 0;
        } else if (isAsc) {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      });
    }
  }

  public filterJunkRecords(
    records: IOrganisationHistory
  ): IOrganisationHistory {
    // hard pass reference/value drama
    let updatedRecords = { ...records };

    if (!records) {
      this.hasHistory = false;
      return null;
    }

    // add summary to local store and setup orgs
    if (this.isDeregisteredOrganisation) {
      if (updatedRecords.organisations) {
        this.organisationHistory = [
          updatedRecords.summary,
          ...updatedRecords.organisations,
        ];
      } else {
        this.organisationHistory = [updatedRecords.summary];
      }
    } else {
      this.organisationHistory = updatedRecords.organisations;
    }

    // zero index month
    const magicDate = new Date(2020, 4, 1).getTime();

    if (this.organisationHistory) {
      this.organisationHistory = this.organisationHistory.filter((org) => {
        const deregDate = new Date(org.dateDeregistered);
        const pubDate = new Date(org.registeredOn);

        //special case handler for deregistered orgs
        // in this case we have two types of records merrged into
        // this.orgHistory -> current record (org sumary)
        //                 -> history records (previous entities)

        // we want to show the date registered for summary
        // but not history records

        if (deregDate.getTime() < magicDate) {
          org.dateDeregistered = org.isDeregistered
            ? org.dateDeregistered
            : null;
        }
        if (pubDate.getTime() < magicDate) {
          org.registeredOn = org.isDeregistered ? org.registeredOn : null;
        }

        // filter out completely dud records
        if (org.dateDeregistered) {
          return org;
        }
      });
    }

    if (updatedRecords.lobbyists) {
      updatedRecords.lobbyists = updatedRecords.lobbyists.filter((lob) => {
        const deregDate = new Date(lob.dateDeregistered);
        const pubDate = new Date(lob.datePublished);

        if (deregDate.getTime() < magicDate) {
          lob.dateDeregistered = null;
        }
        if (pubDate.getTime() < magicDate) {
          lob.datePublished = null;
        }

        // filter out completely dud records
        if (lob.dateDeregistered) {
          return lob;
        }
      });
    }

    if (updatedRecords.clients) {
      updatedRecords.clients = updatedRecords.clients.filter((client) => {
        const deregDate = new Date(client.dateDeregistered);
        const pubDate = new Date(client.datePublished);

        if (deregDate.getTime() < magicDate) {
          client.dateDeregistered = null;
        }

        if (pubDate.getTime() < magicDate) {
          client.datePublished = null;
        }

        if (client.dateDeregistered) {
          return client;
        }
      });
    }

    if (updatedRecords.stakeholders) {
      updatedRecords.stakeholders = updatedRecords.stakeholders.filter(
        (stakehold) => {
          const deregDate = new Date(stakehold.dateDeregistered);
          const pubDate = new Date(stakehold.datePublished);

          if (deregDate.getTime() < magicDate) {
            stakehold.dateDeregistered = null;
          }

          if (pubDate.getTime() < magicDate) {
            stakehold.datePublished = null;
          }

          if (stakehold.dateDeregistered) {
            return stakehold;
          }
        }
      );
    }

    return updatedRecords;
  }
}
