import { Component, OnInit, Input } from '@angular/core';

import { IPageMetadata } from './../../layout.models';

@Component({
    selector: 'app-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
    @Input() pageMetadata: IPageMetadata;

    constructor() { }

    ngOnInit() {

    }
}
