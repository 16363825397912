import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { DocumentService } from "./../../services/document.service";
import * as FileSaver from "file-saver";

@Component({
  selector: "app-file-input",
  templateUrl: "./file-input.component.html",
  styleUrls: ["./file-input.component.scss"],
})
export class FileInputComponent implements OnInit {
  @Input() public documents: File[];
  @Input() public singleDoc: boolean = false;
  @Input() public filter: string;
  @Input() public filterExtension: string;
  @Input() public filterTypeText: string;
  @Input() public helpText: string;
  @Input() public readonly: boolean = false;
  @Input() public downloadUrl: string;
  @Input() public mode: string = "full";
  @Input() public noBorder: boolean = false;

  @Output() changed = new EventEmitter<any>();

  private filters: string[];
  private filterExtensions: string[];

  constructor(private documentService: DocumentService) {}

  ngOnInit() {
    this.filters = this.filter.split(";");
    this.filterExtensions = this.filterExtension.split(";");
  }

  addDocument(event) {
    if (event.srcElement.files && event.srcElement.files.length > 0) {
      let document = event.srcElement.files[0];
      if (
        (document.type && this.filters.indexOf(document.type) !== -1) ||
        (document.name && this.isExtensionValid(document.name))
      ) {
        // the more full solution would have this property be "maxDocs"
        // or something but then we'd need a UI pattern for when max
        // is exceeded, because it's not so easy as gutting this.documents
        // where maxDocs > 1...
        // e.g. if max was 3 which one would we overwrite?
        // we pop instead of clearing because there is a default document set
        // for stat dec uploads in lobbyist
        if (this.singleDoc) {
          this.documents.pop();
        }
        this.documents.push(document);
        this.changed.emit();
      } else {
        alert(
          "Only files of the following type can be uploaded: " +
            (this.filterTypeText || this.filter)
        );
      }
    }

    event.srcElement.value = "";
  }

  download(event, document) {
    event.preventDefault();
    let url = this.downloadUrl + "/" + document.name;

    this.documentService
      .initDocumentDownload(url)
      .subscribe((blob) => FileSaver.saveAs(blob, document.name));
  }

  private extractExtension(filename: string): string {
    let fileComponents = filename.split(".");
    return fileComponents.length >= 2
      ? fileComponents[fileComponents.length - 1]
      : filename;
  }

  private isExtensionValid(filename: string): boolean {
    if (!this.filterExtension) {
      return false;
    }
    let extension = (this.extractExtension(filename) || "").toLowerCase();

    return this.filterExtensions.indexOf(extension) !== -1;
  }

  removeDocument(event, documentIndex) {
    event.preventDefault();
    this.documents.splice(documentIndex, 1);
    this.changed.emit();
  }
}
