import { ActionReducer, Action } from '@ngrx/store';

const initialState: INotification = {
    message: null,
    startDate: null,
    endDate: null,
    type: null
};

export const NOTIFY = 'NOTIFY';
export const ALERT = 'ALERT';

export function reducer(state: INotification = initialState, action: UnsafeAction) {
  if (action.payload == null) {
    return state;
  }

  switch (action.type) {
    case NOTIFY:
      var notification = {
        message: action.payload.message,
        type: NOTIFY,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate
      };
      return notification;
    case ALERT:
      var notification = {
        message: action.payload.message,
        type: ALERT,
        startDate: null,
        endDate: null
      };
      return notification;

    default:
      return state;
  }
}

export interface INotification {
    message: string,
    startDate: Date,
    endDate: Date,
    type: string
}

export class UnsafeAction implements Action {
  payload?: any;
  type: string;
}
