import { Component, OnInit, Input } from '@angular/core';
import { IPageMetadata } from './../../layout.models';

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
    @Input() pageMetadata: IPageMetadata;

    constructor() { }

    ngOnInit() {

    }
}