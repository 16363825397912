import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { FormGroup, AbstractControl, FormArray } from "@angular/forms";
import { Subscription, Observable } from "rxjs";

@Component({
  selector: "app-validation-summary",
  templateUrl: "./validation-summary.component.html",
  styleUrls: ["./validation-summary.component.scss"],
})
export class ValidationSummaryComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  formErrors: any[];

  constructor() {}

  @Input() set form(form: FormGroup) {
    this.subscription && this.subscription.unsubscribe();

    if (form != null) {
      this.subscription = form.valueChanges.subscribe(() =>
        this.updateValidationErrors(form)
      );
      this.updateValidationErrors(form);
    }
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }

  updateValidationErrors(form: FormGroup) {
    this.formErrors = [];

    this.addFormGroupErrors(form);
  }

  private translateControlName(name: string) {
    var names = {
      addressError_addressLine1: "Address line 1",
      addressError_suburb: "Suburb",
      addressError_state: "State",
      addressError_postcode: "Postcode",
      addressError_country: "Country",
      businessEntityName: "Business entity name",
      isSoleTrader: "Are you a sole trader",
      primaryPhoneNumber: "Primary phone number",
      addressLine1: "Address line 1",
      suburb: "Suburb",
      state: "State",
      postcode: "Postcode",
      country: "Country",
      postalAddressIsSameAsPhysicalAddress:
        "Do you want to provide a separate mailing address",
      title: "Title",
      firstName: "First name",
      lastName: "Last name",
      email: "Email",
      position: "Job title",
      isFormerRepresentative: "Is former government representative",
      previousPosition: "Previous position held",
      previousPositionOther: "Previous position held (other)",
      ngbCessationDate: "Cessation date",
      informationIsCorrectDeclaration: "Acceptance of the declaration",
      allLobbyistsHaveReadCodeDeclaration:
        "Confirming that all lobbyists have read the Code",
      owners: "The owners section",
      lobbyists: "The lobbyists section",
      isBusiness: "The owner type (business or person)",
      hasTradingName: "Specifying whether the organisation has any other name",
      noChangesDeclaration:
        "Confirming your organisation details are up to date",
      isLobbyist: "The entity type (lobbyist or organisation)",
      organisationId: "Organisation",
      relationshipToBreach: "Relationship to breach",
      relationshipToBreachOther: "Relationship to breach (other)",
      locationOfBreach: "Location of breach",
      dateOfBreach: "Date of breach",
      previousPositionLevel: "Previous position level",
      howBecameAwareOfBreach: "How you became aware of the breach",
      identifyLobbyists:
        "Specifying whether you want to identify one more more lobbyists for the breach report",
      furtherContact: "Specifying whether you want further contact",
      recaptchaResponse: "ReCAPTCHA response",
      descriptionOfBreach: "Description of breach",
      codeOfConductSectionBreached:
        "The part of the Lobbying Code of Conduct that was breached",
      codeOfConductSectionBreachedOther:
        "The part of the Lobbying Code of Conduct that was breached",
    };

    return names[name] ? names[name] : `field <${name}>`;
  }

  private currentLob = "";

  private addControlErrors(
    control: AbstractControl,
    controlName: string,
    recordDescription: string
  ) {
    let errors = Object.assign({}, control.errors);
    let displayName = this.translateControlName(controlName);
    // ancient TS version doesn't support optional chaining..
    // check if the control has a lobbyist name
    const lobbyist = recordDescription.match(/Lobbyist (.*):/);
    // if it does extract it
    const lobbyistName = lobbyist ? lobbyist.pop() : null;
    // because of the way this flicks through controls, child controls might not have a lobbyist name
    // so we need to set outside the scope
    if (lobbyistName) {
      this.currentLob = lobbyistName;
    }
    // if it exists && this is a Lobbyist control then set the additional text
    let nameError =
      !!this.currentLob && recordDescription.indexOf("Lobbyist") == -1
        ? ` for ${this.currentLob}`
        : "";

    let errorMap = {
      required: `${displayName} is required${nameError}`,
      tradingName: "Trading name is required",
      validAbn: "Please enter a valid ABN",
      firstName_required: `First name is required${nameError}`,
      lastName_required: `Last name is required${nameError}`,
      businessName_required: "Business name is required",
      alternateBusinessNumberFields:
        "Please either fill in both Alternate Business Number fields, or leave both fields blank",
      email: `${displayName} is not a valid email address${nameError}`,
      addressError_addressLine1: `Address line 1 is required`,
      addressError_suburb: "Suburb is required",
      addressError_state: "State is required",
      addressError_postcode: "Postcode is required",
      addressError_country: "Country is required",
      ngbCessationDate_required: `Cessation date is required${nameError}`,
      ngbDate: `Cessation date must be in the past${nameError}`,
      statDecMissing:
        "Please ensure that you have uploaded a Statutory Declaration for each lobbyist",
      mustHaveClients:
        "You must add one client or select that you do not have any clients",
      duplicateEmails: "Each lobbyist must have a different email address",
      missingAtLeastOneLobbyist:
        "You must add one lobbyist or select that you do not have any lobbyists",
      missingAtLeastOneOwner: "You must register at least one owner",
      emailIsTaken: "Email address is already registered",
      missingAtLeastOneOfficer:
        "You must register at least one responsible officer",
      noOfficersReceiveCommunications:
        "Please ensure at least one responsible officer will receive notification emails from the Register",
      selectAtLeastOneLobbyist: "You must select at least one lobbyist",
      dateWitnessedError: `Statutory declarations must be witnessed no earlier than 10 business days prior to 30 June and cannot be future dated${nameError}`,
      ngbDateWitnessed_required: `Date witnessed required${nameError}`,
      previousPositionLevel: `Previous position level is required${nameError}`,
      noCodePartsSelected:
        "You must select at least one principle of engagement that was not adhered to",
    };

    const errorKeys: string[] = Object.keys(errorMap);
    for (var i = 0; i < errorKeys.length; i++) {
      const key: string = errorKeys[i];
      if (errors[key]) {
        if (key == "statDecMissing" || key == "duplicateEmails") {
          this.formErrors.push(errors[key].message);
        } else if (key == "ngbCessationDate_required") {
          // trap so it doesn't hit fallback
        } else {
          const errorDescription: string = recordDescription + errorMap[key];
          // console.log(`error description ${errorDescription}`);
          // console.log(`key ${key}`);

          if (this.formErrors.indexOf(errorDescription) == -1) {
            this.formErrors.push(errorDescription);
          }
        }

        delete errors[key];
      }
    }

    // As a fallback, show unhandled errors for development/diagnostic purposes
    Object.keys(errors).forEach((key) => {
      this.formErrors.push(
        `Error: ${key} | display ${displayName} | value ${errors[key]}`
      );
    });
  }

  private addFormGroupErrors(
    formGroup: FormGroup,
    ignoreFormGroupErrors = false
  ) {
    if (ignoreFormGroupErrors === false) {
      this.addControlErrors(formGroup, "Overall form errors", "");
    }

    if (!formGroup.controls) {
      return;
    }

    var controlNames: string[] = Object.keys(formGroup.controls);

    var recordDescription = "";
    if (controlNames.indexOf("description") != -1) {
      if (formGroup.get("description").value != null) {
        // identify record type
        if (controlNames.indexOf("ownerDetails") != -1) {
          recordDescription = "Owner ";
        } else if (controlNames.indexOf("lobbyistDetails") != -1) {
          recordDescription = "Lobbyist ";
        } else if (controlNames.indexOf("businessDetails") != -1) {
          recordDescription = "Organisation ";
        } else if (controlNames.indexOf("hasTradingName") != -1) {
          recordDescription = "Client ";
        } else {
          recordDescription = "Responsible officer ";
        }

        recordDescription +=
          (<string>formGroup.get("description").value).replace("null", "") +
          ": ";
      }
    }

    // Only way to smuggle description into forEach
    // that I could make work
    var controlWithDescription = controlNames.map((ctrl) => {
      let rObj = {};
      rObj["controlName"] = ctrl;
      rObj["description"] = recordDescription;

      return rObj;
    });

    controlWithDescription.forEach((controlName) => {
      let control = formGroup.controls[controlName["controlName"]];
      let errors = control.errors;

      this.addControlErrors(
        control,
        controlName["controlName"],
        controlName["description"]
      );

      if (control instanceof FormGroup === true) {
        this.addFormGroupErrors(<FormGroup>control, true);
      } else if (control instanceof FormArray === true) {
        let array = <FormArray>control;
        for (let i = 0; i < array.length; ++i) {
          this.addFormGroupErrors(<FormGroup>array.get([i]));
        }
      }
    });
  }
}
