import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GoogleAnalyticsService } from '../../../../../services/google-analytics.service';

@Component({
  selector: 'app-step-sole-trader',
  templateUrl: './step-sole-trader.component.html',
  styleUrls: ['./step-sole-trader.component.scss']
})
export class StepSoleTraderComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() soleTraderChange: (isSoleTrader: boolean) => void;

  constructor(private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    this.googleAnalyticsService.logPageVisit("step-sole-trader");
  }
}
