import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { Observable } from "rxjs/Rx";
import { ISearchRequest } from "./../../../organisation/services/search-request";
import { SortOrder } from "./../../../organisation/services/sort-criteria";
import {
  ReportingService,
  ISearchDownloadRequest,
} from "./../../../organisation/services/reporting.service";

import * as FileSaver from "file-saver";
import * as moment from "moment";

@Component({
  selector: "app-search-bar",
  templateUrl: "./search-bar.component.html",
  styleUrls: ["./search-bar.component.scss"],
})
export class SearchBarComponent implements OnInit {
  public sortOrder = SortOrder;

  @Input() public searchRequest: ISearchRequest;
  @Output() public onFilterChanged = new EventEmitter<ISearchRequest>();
  @Input() public delay: number = 100;
  @Input() public isSearching: boolean = false;
  @Input() public isBreachSearch: boolean = false;
  @Input() public isMainSearch: boolean = false;

  constructor(
    private elementRef: ElementRef,
    private reportingService: ReportingService
  ) {
    const eventStream = Observable.fromEvent(elementRef.nativeElement, "keyup")
      .debounceTime(this.delay)
      .map(() => this.searchRequest.query)
      .distinctUntilChanged();

    /*
          eventStream.subscribe(input => {
              this.searchRequest.pagingCookie = null;
              this.searchRequest.pageNumber = 1;
              this.onFilterChanged.emit(this.searchRequest);
          });
          */
  }

  public download(event, fileExtension) {
    event.preventDefault();

    let request: ISearchDownloadRequest = {
      fileExtension: fileExtension,
      searchCriteria: { ...this.searchRequest },
    };

    request.searchCriteria.pageNumber = 1;

    this.reportingService
      .initSearchDownload(request)
      .subscribe((blob) =>
        FileSaver.saveAs(
          blob,
          request.searchCriteria.entity +
            "-resultset-" +
            moment().format("YYYY-MM-DD-HHmmss") +
            "." +
            request.fileExtension
        )
      );
  }

  ngOnInit() {}

  public doSearch(event) {
    event.preventDefault();
    this.searchRequest.pageNumber = 1;
    this.searchRequest.pagingCookie = null;
    this.onFilterChanged.emit(this.searchRequest);
  }

  public setCount(event, count) {
    event.preventDefault();
    this.searchRequest.count = count;
    this.onFilterChanged.emit(this.searchRequest);
  }

  public setSortDesc(event, fieldName) {
    event.preventDefault();
    this.searchRequest.sortCriteria.fieldName = fieldName;
    this.searchRequest.sortCriteria.sortOrder = SortOrder.descending;
    this.onFilterChanged.emit(this.searchRequest);
  }

  public setSortAsc(event, fieldName) {
    event.preventDefault();
    this.searchRequest.sortCriteria.fieldName = fieldName;
    this.searchRequest.sortCriteria.sortOrder = SortOrder.ascending;
    this.onFilterChanged.emit(this.searchRequest);
  }

  public reset(event) {
    event.preventDefault();
    this.searchRequest.query = "";
    this.searchRequest.pageNumber = 1;
    this.searchRequest.pagingCookie = null;
    this.searchRequest.count = 10;
    this.searchRequest.sortCriteria.fieldName = "";
    this.searchRequest.sortCriteria.sortOrder = SortOrder.ascending;

    this.reportingService.setSortCriteria(this.searchRequest);

    this.onFilterChanged.emit(this.searchRequest);
  }
}
