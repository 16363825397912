import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { MockConnection } from "@angular/http/testing";

import { Observable } from "rxjs";

import { MockBackendService } from './mock-backend.service';
import { MockDataService } from './mock-data.service';
import { IRequestRouter } from './request-router';

@Injectable()
export class AuthControllerBackendService implements IRequestRouter {

    constructor(private mockDataService: MockDataService) { }

    public routeRequest(connection: MockConnection, realHttp: Http): Observable<void> {
        let url = connection.request.url;

        if (!!url) {
            if (url.startsWith("/api/user/principal")) {
                return this.mockDataService.getMockData(connection, url.split("?")[0], realHttp);
            }
        }

        throw new Error("Mock Error: Mock not implemented for - " + url);
    }
}
