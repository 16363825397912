import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IMenuItem } from "./../../models"

@Component({
    selector: 'app-actionbar',
    templateUrl: './actionbar.component.html',
    styleUrls: ['./actionbar.component.scss']
})
export class ActionbarComponent implements OnInit {

    @Input() actionItems: IMenuItem[];
    @Output() onAction = new EventEmitter<number>();

    constructor() { }

    ngOnInit() { }

    hasContent() : boolean {
        if (this.actionItems && this.actionItems.length) {
            for (let i = 0; i < this.actionItems.length; i++) {
                if (this.actionItems[i].visible !== true)
                    return true;
            }
        }
        return false;
    }

    onActionItemClicked(event, index: number) {
        event.preventDefault();
        this.onAction.emit(index);
    }
}
