import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";

import "rxjs/Rx";

import * as fromRoot from "../../../reducers";
import { Store } from "@ngrx/store";

import { ISearchRequest } from "../../organisation/services/search-request";

import { IStakeholder } from "../../organisation/models";

import { IConfiguration } from "../../configuration/reducers";
import { Subject } from "rxjs/Rx";

@Injectable()
export class HistoryService {
  public historyAvailable$ = new Subject<IOrganisationHistory>();
  private config: IConfiguration;
  private history: IOrganisationHistory;

  constructor(private store: Store<fromRoot.IState>, private http: HttpClient) {
    this.store.select(fromRoot.getConfig).subscribe((config) => {
      this.config = config;
    });
  }

  public getOrganisationHistory(id: string) {
    console.log(`collecting history for ${id}`);

    this.makeNetworkCall(id).subscribe((profile) => {
      console.log(`history record is ${JSON.stringify(profile)}`);
      this.history = profile;
      this.historyAvailable$.next(this.history);
    });
  }

  private makeNetworkCall(id: string) {
    return this.http
      .get<IOrganisationHistory>(
        this.config.api.organisation + "history/organisations/" + id
      )
      .map((profile) => <IOrganisationHistory>profile);
  }
}
export interface IOrganisationSummary {
  id: string;
  displayName: string;
  tradingName: string;
  abn: string;
  registeredOn: Date;
  modifiedOn: Date;
  isDeregistered: boolean;
  dateDeregistered: Date;
}

export interface ILobbyist {
  displayName: string;
  position: string;
  isFormerRepresentative: boolean;
  previousPosition: string;
  previousPositionOther: string;
  additionalNotes: string;
  cessationDate: Date;
  datePublished: Date;
  dateDeregistered: Date;
  isDeregistered: boolean;
}

export interface IClient {
  displayName: string;
  abn: string;
  datePublished: Date;
  dateDeregistered: Date;
  isDeregistered: boolean;
}

export interface IOrganisationHistory {
  summary: IOrganisationSummary;
  organisations: IOrganisationSummary[];
  lobbyists: ILobbyist[];
  clients: IClient[];
  stakeholders: IStakeholder[];
}

export interface ISearchResponse<T> {
  totalRecords: number;
  pagingCookie: string;
  pageNumber: number;
  hasMoreRecords: boolean;
  count: number;
  resultSet: T[];
}

export interface IBusinessResult extends IOrganisationSummary {}

export interface ILobbyistResult extends ILobbyist {
  organisation: IOrganisationSummary;
}

export interface IClientResult extends IClient {
  organisation: IOrganisationSummary;
}

export interface ISearchDownloadRequest {
  fileExtension: string;
  searchCriteria: ISearchRequest;
}
