import { Component, OnInit } from '@angular/core';
import { Route, Routes, Router, NavigationEnd, ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';

import { ISideNavItem } from "./../../layout.models";

import { routes } from './../../../../app.module.routing';

@Component({
    selector: 'app-page-output',
    templateUrl: './page-output.component.html',
    styleUrls: ['./page-output.component.scss']
})
export class PageOutputComponent implements OnInit {
    sideNavItems: ISideNavItem[];

    constructor(private router: Router, private activatedRoute: ActivatedRoute) {
        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .map(() => this.activatedRoute)
            .map(route => {
                while (route.firstChild) route = route.firstChild;
                return route;
            })
            .filter(route => route.outlet === PRIMARY_OUTLET)
            .subscribe(route => {
                this.sideNavItems = [];

                let relatedRoutes = route.snapshot.data['relatedRoutes'];
                if (relatedRoutes) {

                    for (let relatedRoute of relatedRoutes) {
                        let routeNode: Route = routes.slice()[0];

                        let sideNavItem: ISideNavItem = {
                            routerLink: <string>relatedRoute,
                            label: ""
                        };

                        if (!this.sideNavItems) {
                            this.sideNavItems = [];
                        }

                        let routeComponents = relatedRoute.split('/');

                        for (let routeComponent of routeComponents) {

                            routeNode = this.getRoute(routeNode, routeComponent);
                        }

                        sideNavItem.label = routeNode.data["title"];
                        this.sideNavItems.push(sideNavItem);
                    }
                }
            });
    }

    private getRoute(route: Route, routeComponent: string): Route {
        let children = route.children;

        if (children.length === 0) {
            return route;
        }

        for (let child of children) {
            let path: string = child.path;
            
            if (path == routeComponent) {
                return child;
            }
        }

        return route;
    }

    ngOnInit() {
    }

}
