import * as fromRoot from "./../../../../reducers";
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from "@angular/core";

import { Store } from "@ngrx/store";
import { Observable, combineLatest, timer, Subscription } from "rxjs";
import { map, takeWhile, take, tap } from "rxjs/operators";
import { IMyProfileStatus, IMyLobbyist } from "./../../models";
import { IUserPrincipal } from "./../../../identity/identity.models";
import { AuthService } from "app/modules/identity/services/auth.service";
import { Router } from "@angular/router";

@Component({
    selector: "app-dashboard",
    templateUrl: "./signin.component.html",
    styleUrls: ["./signin.component.scss"],
    host: {'window:beforeunload': 'removeClass'}
})
export class SignInComponent implements OnInit, OnDestroy {
    constructor(private authService: AuthService, private router: Router){

    }

    ngOnInit() {
        /** This makes "Lobbyist Portal" 'active' or highlighted. 
         * Done this way as Angular's native RouterLinkActive sets 'active' 
         * attribute based on route link being open. However, 'user/signin' 
         * route is not included in this list, so will not populate the class.
         */
        document.getElementById('lobbyist-portal-nav-item').classList.add("active");
    }

    @HostListener('window:beforeunload')
    ngOnDestroy() {
        document.getElementById('lobbyist-portal-nav-item').classList.remove("active");
    }

    // MSAL Authentication: On Button Click, load these Functions Respectively.
    public login() {
        this.authService.login();
    }

    public register() {
        this.authService.register();
    }

    public forgotPassword() {
        this.authService.forgot();
    }

    public goToRegister(){
        this.router.navigate(["/register"]);
    }
}