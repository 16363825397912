import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DocumentService } from './services/document.service';
import { AbnService } from './services/abn.service';
import { ContactService } from './services/contact.service';
import { OfficerService } from './services/officer.service';

import { AddressComponent } from './components/address/address.component';
import { LoaderComponent } from './components/loader/loader.component';

import { AbnDisplayComponent } from './components/abn-display/abn-display.component';

import { IconComponent } from './components/icon/icon.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';

import { TextInputComponent } from './components/text-input/text-input.component';
import { NavTabsComponent } from './components/nav-tabs/nav-tabs.component';
import { ActionbarComponent } from './components/actionbar/actionbar.component';
import { AbnInputComponent } from './components/abn-input/abn-input.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FullNameComponent } from './components/full-name/full-name.component';
import { BusinessNameComponent } from './components/business-name/business-name.component';
import { FileInputComponent } from './components/file-input/file-input.component';

import { AgmCoreModule } from "@agm/core";

import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AgmCoreModule.forRoot({
            apiKey: "AIzaSyAcMk_2UjpTO66-gdsHNhYFUBzU7ktlSdg",
            libraries: ["places"]
        }),
        RecaptchaModule.forRoot(),
        RecaptchaFormsModule
    ],
    exports: [
        AddressComponent,
        LoaderComponent,
        AbnDisplayComponent,
        IconComponent,
        ContactFormComponent,
        TextInputComponent,
        NavTabsComponent,
        ActionbarComponent,
        AbnInputComponent,
        SpinnerComponent,
        FullNameComponent,
        BusinessNameComponent,
        FileInputComponent
    ],
    providers: [
        DocumentService,
        AbnService,
      ContactService,
        OfficerService
    ],
    declarations: [
        AddressComponent,
        LoaderComponent,
        AbnDisplayComponent,
        IconComponent,
        ContactFormComponent,
        TextInputComponent,
        NavTabsComponent,
        ActionbarComponent,
        AbnInputComponent,
        SpinnerComponent,
        FullNameComponent,
        BusinessNameComponent,
        FileInputComponent
    ]
})
export class ControlsModule { }
