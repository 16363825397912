import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { EChartsOption } from "echarts";
import {
  StatisticsService,
  IStatistics,
  IDataPoint,
} from "app/modules/organisation/services/statistics.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";

@Component({
  selector: "app-statistics",
  templateUrl: "./statistics.component.html",
  styleUrls: ["./statistics.component.scss"],
})
export class StatisticsComponent implements OnInit {
  public clientsChart: EChartsOption;
  public lobbyistChart: EChartsOption;
  public formerRepsChart: EChartsOption;
  public registerStatsChart: EChartsOption;
  public topChart: EChartsOption;
  public orgsByYearChart: EChartsOption;

  public clientsLoading = true;
  public lobbyistLoading = true;
  public formerRepsLoading = true;
  public registerLoading = true;

  public clientSeries: any;
  public lobbyistSeries: any;
  public formerRepsSeries: any;
  public registerSeries: any;
  public topSeries: any;
  public orgSeries: any;

  public datasetTitle: string;
  public datasetEntity: string = "Item";
  public dataset: IDataPoint[];
  public descriptionCaption: string;
  public showDescription: boolean;
  public showTotal: boolean;

  @ViewChild("modalDataset", { read: false, static: false })
  modalDataset: ElementRef;
  @ViewChild("modalLoading", { read: false, static: false })
  modalLoading: ElementRef;

  private statsColor: string[] = [
    "#50748a",
    "#F4D58D",
    "#8D0801",
    "#628B48",
    "#A11692",
    "#001427",
    "#209440",
    "#a2a1a6",
    "#e02020",
  ];
  private fontStyle = {
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    "white-space": "pre-wrap",
  };
  private textStyle = {
    ...this.fontStyle,
    color: "#212529",
  };
  private bgColor: string = "#f0f3f5";

  constructor(
    private statisticsService: StatisticsService,
    private modalService: NgbModal
  ) {}

  groupBy<IDataPoint>(
    xs: IDataPoint[],
    key: string
  ): { [key: string]: IDataPoint[] } {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  public get descriptionColumns(): string[] {
    if (this.showDescription) {
      return this.dataset[0].descriptionCaption.split("|");
    } else {
      return [];
    }
  }

  public getDescription(dp: IDataPoint): string[] {
    if (this.showDescription) {
      return dp.description.split("|");
    } else {
      return [];
    }
  }

  ngAfterViewInit() {
    this.modalService.open(this.modalLoading, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });

    this.statisticsService.clients$.subscribe((clientStats) => {
      this.clientSeries = this.groupBy(clientStats.data, "category");

      this.clientsChart = {
        color: this.statsColor,
        backgroundColor: this.bgColor,
        title: {
          text: "Clients across organisations",
          left: "center",
          subtext:
            "This graph breaks down registered lobbying organisations \nby the number of clients that each organisation represents.\n\n",
          textAlign: "left",
          textStyle: this.textStyle,
          subtextStyle: this.textStyle,
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: clientStats.categories,
          formatter: (name) => {
            return name + " clients";
          },

          top: "bottom",
          textStyle: this.textStyle,
        },
        series: [
          {
            type: "pie",
            radius: "55%",
            data: clientStats.categories.map((category) => {
              return {
                name: category,
                value: this.clientSeries[category].length,
              };
            }),
            label: {
              ...this.fontStyle,
              formatter: "{b}: {d}%",
            },
          },
        ],
      };

      this.clientsLoading = false;
      this.modalService.dismissAll();
    });

    this.statisticsService.lobbyists$.subscribe((lobbyistStats) => {
      this.lobbyistSeries = this.groupBy(lobbyistStats.data, "category");

      this.lobbyistChart = {
        color: this.statsColor,
        backgroundColor: this.bgColor,
        title: {
          text: "Lobbyists across organisations",
          left: "center",
          subtext:
            "This graph breaks down registered lobbying organisations \nby the number of lobbyists that each organisation represents.\n\n",
          textAlign: "left",
          textStyle: this.textStyle,
          subtextStyle: this.textStyle,
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: lobbyistStats.categories,
          formatter: (name) => {
            switch (name) {
              case "0":
                return "None";
              case "1":
                return "1 lobbyist";
              default:
                return name + " lobbyists";
            }
          },
          top: "bottom",
          textStyle: this.textStyle,
        },
        series: [
          {
            type: "pie",
            radius: "55%",
            data: lobbyistStats.categories.map((category) => {
              return {
                name: category,
                value: this.lobbyistSeries[category].length,
              };
            }),
            label: {
              ...this.fontStyle,
              formatter: "{b}: {d}%",
            },
          },
        ],
      };

      this.lobbyistLoading = false;
      this.modalService.dismissAll();
    });

    this.statisticsService.formerReps$.subscribe((formerRepStats) => {
      this.formerRepsSeries = this.groupBy(formerRepStats.data, "category");

      this.formerRepsChart = {
        color: this.statsColor,
        backgroundColor: this.bgColor,
        title: {
          text: "Percentage of \nFormer Government Representatives",
          left: "center",
          subtext:
            "This graph outlines the percentage of \nlobbyists who have listed themselves as \nformer Government representatives. It includes \nlobbyists that are currently in their prohibition period.",
          textAlign: "left",
          textStyle: this.textStyle,
          subtextStyle: this.textStyle,
        },
        series: [
          {
            type: "pie",
            radius: "55%",
            data: formerRepStats.categories.map((category) => {
              return {
                name: category,
                value: this.formerRepsSeries[category].length,
              };
            }),
            label: {
              ...this.fontStyle,
              formatter: "{b}: {d}%",
              position: "inside",
            },
          },
        ],
      };

      this.formerRepsLoading = false;
    });

    this.statisticsService.register$.subscribe((registerStats) => {
      this.registerSeries = this.groupBy(registerStats.data, "category");

      this.registerStatsChart = {
        color: this.statsColor,
        backgroundColor: this.bgColor,
        grid: {
          top: "15%",
          left: "25%",
        },
        title: {
          text: "Register Statistics",
          left: "center",
          textAlign: "left",
          subtext:
            "This graph outlines the total number of lobbying \norganisations, lobbyists and lobbying clients that \nare recorded on the Lobbyist Register.",
          textStyle: this.textStyle,
          subtextStyle: this.textStyle,
        },
        xAxis: {
          type: "value",
          axisLabel: this.textStyle,
        },
        yAxis: {
          type: "category",
          data: registerStats.categories,
          axisLabel: this.textStyle,
        },
        series: [
          {
            data: registerStats.categories.map((category) => {
              return {
                name: category,
                value: this.registerSeries[category].length,
              };
            }),
            type: "bar",
            label: {
              ...this.textStyle,
              show: true,
              position: "right",
            },
          },
        ],
      };

      this.registerLoading = false;
    });

    this.statisticsService.top$.subscribe((topStats) => {
      this.topSeries = this.groupBy(topStats.data, "category");

      var topData = topStats.categories
        .map((category) => {
          return { name: category, value: this.topSeries[category].length };
        })
        .sort((a, b) => (a.value < b.value ? -1 : a.value > b.value ? 1 : 0));
      var topCategories = topData.map((d) => d.name);

      this.topChart = {
        color: this.statsColor,
        backgroundColor: this.bgColor,
        grid: {
          left: "20%",
        },
        title: {
          text: "Largest lobbying organisation (by client)",
          left: "center",
          textAlign: "left",
          subtext:
            "This graph lists the lobbying organisations with the highest numbers of clients.",
          textStyle: this.textStyle,
          subtextStyle: this.textStyle,
        },
        xAxis: {
          type: "value",
          axisLabel: this.textStyle,
        },
        yAxis: {
          type: "category",
          data: topCategories,
          axisLabel: this.textStyle,
        },
        series: [
          {
            data: topData,
            type: "bar",
            label: {
              ...this.textStyle,
              show: true,
              position: "right",
            },
          },
        ],
      };
    });

    this.statisticsService.orgsByYear$.subscribe((orgStats) => {
      this.orgSeries = this.groupBy(orgStats.data, "category");

      var orgData = orgStats.categories
        .map((category) => {
          return { name: category, value: this.orgSeries[category].length };
        })
        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
      var orgCategories = orgData.map((d) => d.name);

      this.orgsByYearChart = {
        color: this.statsColor,
        backgroundColor: this.bgColor,
        title: {
          text: "Number of published organisations",
          left: "center",
          textAlign: "left",
          subtext:
            "This graph provides the number of new lobbying organisations that are published on the Lobbyist Register each year.",
          textStyle: this.textStyle,
          subtextStyle: this.textStyle,
        },
        xAxis: {
          type: "category",
          data: orgCategories,
          axisLabel: this.textStyle,
        },
        yAxis: {
          type: "value",
          axisLabel: this.textStyle,
        },
        series: [
          {
            data: orgData,
            type: "bar",
            label: {
              ...this.textStyle,
              show: true,
              position: "top",
            },
          },
        ],
      };
    });
  }

  ngOnInit() {}

  sortColumns: Record<string, boolean> = {};

  drilldown(
    chartEvent: any,
    targetSeries: any,
    entityType: string,
    captionMap: Record<string, string> = null,
    showTotal: boolean = false
  ) {
    if (entityType == "Register") {
      this.datasetEntity = captionMap[chartEvent.name];
      this.datasetTitle = entityType + " - " + chartEvent.name;
    } else if (entityType == "lobbyist" || entityType == "client") {
      this.datasetEntity = entityType;

      let title;
      switch (chartEvent.name) {
        case "0":
          title = "Organisation - No lobbyists";
          break;
        case "1":
          title = "Organisation - 1 lobbyist";
          break;
        default:
          title = "Organisation - " + chartEvent.name + " " + entityType + "s";
      }

      this.datasetTitle = title;

      // this.datasetTitle =
      //   "Organisation - " + chartEvent.name + " " + entityType;
    } else {
      this.datasetEntity = entityType;

      if (captionMap) {
        this.datasetTitle = entityType + " - " + captionMap[chartEvent.name];
      } else {
        this.datasetTitle = entityType + " - " + chartEvent.name;
      }
    }

    this.dataset = (<IDataPoint[]>targetSeries[chartEvent.name]).sort((a, b) =>
      a.displayName.localeCompare(b.displayName)
    );
    this.showTotal = showTotal;

    this.showDescription = false;

    if (this.dataset.length > 0) {
      this.descriptionCaption = this.dataset[0].descriptionCaption;
      switch (this.datasetEntity) {
        case "lobbyist":
        case "client":
          this.datasetEntity = "Organisation";
          break;
      }
      // don't show description if selected category is organisation and description field is 'organisation'
      this.showDescription = !(
        this.descriptionCaption == "Organisation" &&
        this.dataset.every((d) => d.description == d.displayName)
      );

      this.sortColumns = {};

      var columns = this.descriptionCaption.split("|");
      columns.forEach((c) => (this.sortColumns[c] = true));
      this.sortColumns["displayName"] = true;
    } else {
      this.descriptionCaption = "";
    }

    this.modalService.open(this.modalDataset, {
      keyboard: true,
      scrollable: true,
      size: "lg",
    });
  }

  sort(column: string) {
    this.sortColumns[column] = !this.sortColumns[column];
    var isAsc = this.sortColumns[column];

    if (column == "Cessation date") {
      this.dataset.sort((a, b) => {
        var aC: moment.Moment;
        var bC: moment.Moment;

        if (isAsc) {
          aC = moment(a.description.split("|")[1], "D/MM/YYYY");
          bC = moment(b.description.split("|")[1], "D/MM/YYYY");
        } else {
          bC = moment(a.description.split("|")[1], "D/MM/YYYY");
          aC = moment(b.description.split("|")[1], "D/MM/YYYY");
        }

        return aC.valueOf() - bC.valueOf();
      });
    } else if (column.toLowerCase().indexOf("number") != -1) {
      // forgive my sins\
      this.dataset.sort((a, b) => {
        var values = a.description.split("|");
        values.push(a.displayName);

        var columns = a.descriptionCaption.split("|");

        var aValue = +values[columns.indexOf(column)];
        var bValues = b.description.split("|");
        bValues.push(b.displayName);
        var bValue = +bValues[columns.indexOf(column)];
        console.log(aValue);
        console.log(bValue);
        if (isAsc) {
          return aValue - bValue;
        } else {
          return bValue - aValue;
        }
      });
    } else {
      this.dataset.sort((a, b) => {
        var values = a.description.split("|");
        values.push(a.displayName);

        var columns = a.descriptionCaption.split("|");
        columns.push("displayName");

        var aValue = values[columns.indexOf(column)];
        var bValues = b.description.split("|");
        bValues.push(b.displayName);
        var bValue = bValues[columns.indexOf(column)];

        if (isAsc) {
          return aValue.localeCompare(bValue);
        } else {
          return bValue.localeCompare(aValue);
        }
      });
    }
  }
}
