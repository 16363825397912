import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

import * as fromRoot from './../../../../reducers';
import { Store } from '@ngrx/store';

import { ContactService } from './../../services/contact.service';

import { IContactModel, IContactResponse } from './../../services/contact.service'

@Component({
    selector: 'app-contact-form',
    templateUrl: './contact-form.component.html',
    styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
    private config: any;

    constructor(private contactService: ContactService, private store: Store<fromRoot.IState>) {
        this.store.select(fromRoot.getConfig).subscribe(config => this.config = config);
    }

    model: IContactModel;
    submitResponse: IContactResponse;
    public isSubmitting: boolean = false;

    public formGroup: FormGroup;
    public validationMessages = {};
    public formErrors = {};
    public formModel = {};
    
    private onSubmit() {
        this.isSubmitting = true;
        this.contactService.submit(this.model).subscribe(response => {
            this.isSubmitting = false;
            this.submitResponse = response;
            if (this.submitResponse.IsSuccess) {
                this.reset();
            }
        });
    }

    private reset() {
        this.model = {
            fullName: null,
            email: null,
            phone: null,
            address: {
                formattedAddress: null,
                unit: null,
                addressLine1: null,
                addressLine2: null,
                suburb: null,
                state: null,
                postcode: null,
                country: "Australia"
            },
            isResponseRequested: false,
            message: null,
            recaptchaResponse: null
        }
    }

    ngOnInit() {
        this.isSubmitting = false;
        this.submitResponse = null;

        this.reset();
        this.formGroup = new FormGroup({});
        this.formGroup.valueChanges.subscribe(data => this.onValueChanged(data));
    }

    onValueChanged(data?: any) {
        if (!this.formGroup) { return; }
        const form = this.formGroup;

        for (const field in this.formErrors) {
            // clear previous error message (if any)
            this.formErrors[field] = '';
            const control = form.get(field);

            if (control && control.dirty && !control.valid) {
                const messages = this.validationMessages[field];
                for (const key in control.errors) {
                    this.formErrors[field] += messages[key] + ' ';
                }
            }
        }
    }

    resolved(captchaResponse: string) {
        this.model.recaptchaResponse = captchaResponse;
    }
}
