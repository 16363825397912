import { ActionReducer, Action } from '@ngrx/store';

export const CONFIGURATIONLOADED = 'CONFIGURATIONLOADED';

const initialState: IConfiguration = {
  api:
  {
    base: "/api",
    //@ts-ignore
    content: window.__RUNTIME_CONFIG__.ANGULAR_APP_API_URL.valueOf(),
    //@ts-ignore
    organisation: window.__RUNTIME_CONFIG__.ANGULAR_APP_API_URL.valueOf(),
  },
  routes:
  {
    account:
    {
      signinUrl: "/account/signin",
      signoutUrl: "/account/signout",
      manageUrl: "/account/manage",
      expiredUrl: "/account/sessionexpired"
    }
  },
  recaptcha:
  {
    siteKey: ""
  },
  homeUrl: null,
  isReportingPeriod: true,
  versionNumber: ""
}

export function reducer(state: IConfiguration = initialState, action: ConfigAction) {
    switch (action.type) {
      case CONFIGURATIONLOADED:
        return { ...action.payload };
        default:
            return state;
    }
}

export interface IApi {
  base: string,
  content: string,
  organisation: string
}

export interface IAccountRoutes {
  signinUrl: string,
  signoutUrl: string,
  manageUrl: string,
  expiredUrl: string
}

export interface IRoutes {
  account: IAccountRoutes
}

export interface IRecaptcha {
  siteKey: string
}
export interface IConfiguration {
  api: IApi,
  routes: IRoutes,
  recaptcha: IRecaptcha,
  homeUrl: string,
  isReportingPeriod: boolean,
  versionNumber: string
}

export class ConfigAction implements Action {
  payload?: IConfiguration;
  type: string;
}
