import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from './../identity/gaurds/auth.gaurd';

import { ProfileComponent } from './components/profile/profile.component';
import { DefaultComponent } from "./../content/components/default/default.component";
import { RegisterComponent } from './components/register/register.component';
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { RegistrationFormComponent } from "./components/registration-form/registration-form/registration-form.component";
import { BreachComponent } from "./components/breach/breach/breach.component";
import { StatisticsComponent } from "./components/statistics/statistics.component";

import { SignInComponent } from "./components/signin/signin.component"; // Updated Login/Registration Flow.
import { SignInFailedComponent } from "./components/signin-failed/signin-failed.component";

const routes: Routes = [
    {
        path: 'organisation',
        component: DefaultComponent,
        data: {
            breadcrumb: {
                label: 'Organisation',
                isClickable: false
            }
        },
        children: [
            {
                path: ":id/profile",
                component: ProfileComponent,
                data: {
                    title: 'Organisation Profile',
                    breadcrumb: {
                        label: 'Profile',
                        isClickable: true
                    }
                }
            },
        ]
    },
    {
        path: 'register',
        component: RegisterComponent,
        data: {
            title: 'The Lobbyist Register',
            breadcrumb: {
                label: 'Lobbyist Register',
                isClickable: true
            }
        }
    },
    {
      path: 'report-a-breach',
      component: BreachComponent,
      data: {
        title: 'Report a breach',
        breadcrumb: {
          label: 'Report a breach',
          isClickable: true
        }
      }
    },
  {
    path: 'statistics',
    component: StatisticsComponent,
    data: {
      title: 'Statistics',
      breadcrumb: {
        label: 'Statistics',
        isClickable: true
      }
    }
  },
    {
        path: 'user',
        component: DefaultComponent,
        data: {
            breadcrumb: {
                label: 'User',
                isClickable: false
            }
        },
        children: [
            {
              path: "signin",
              component: SignInComponent,
              // canActivate: [AuthGuard],
              data: {
                title: 'Signin',
                breadcrumb: {
                  label: 'Login/Register Form',
                  isClickable: false
                }
              }
            },
            {
              path: 'dashboard',
              component: DashboardComponent,
              canActivate: [AuthGuard],
              data: {
                title: 'Organisation Dashboard',
                breadcrumb: {
                  label: 'Dashboard',
                  isClickable: true
                }
              }
            },
            {
              path: "form",
              component: RegistrationFormComponent,
              canActivate: [AuthGuard],
              data: {
                title: 'Registration Form',
                breadcrumb: {
                  label: 'Registration Form',
                  isClickable: true
                }
              }
            },
            {
              path: "update",
              component: RegistrationFormComponent,
              canActivate: [AuthGuard],
              data: {
                title: 'Update details',
                breadcrumb: {
                  label: 'Update details',
                  isClickable: true
                }
              }
            },
            {
              path: "signin-failed",
              component: SignInFailedComponent,
              data: {
                title: 'Signin Failed',
                breadcrumb: {
                  label: 'Login Failed',
                  isClickable: false
                }
              }
            }
        ]
    }
];

export const orgRoutes: ModuleWithProviders = RouterModule.forRoot(routes);
