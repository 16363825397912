import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { MockConnection } from "@angular/http/testing";

import { Observable } from "rxjs";

import { MockBackendService } from './mock-backend.service';
import { MockDataService } from './mock-data.service';
import { IRequestRouter } from './request-router';

@Injectable()
export class ContactControllerBackendService implements IRequestRouter {

    constructor(private mockDataService: MockDataService) { }

    public routeRequest(connection: MockConnection, realHttp: Http): Observable<void> {
        let url = connection.request.url;

        if (!!url) {
            let contactApi = "http://mock/";
            if (url.startsWith(contactApi)) {
                url = "/" + url.substring(contactApi.length);
                if (url.startsWith("/contact")) {
                    console.log(url);
                    return this.mockDataService.getMockData(connection, url, realHttp);
                }
            }
        }

        throw new Error("Mock Error: Mock not implemented for - " + url);
    }

}
