import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";

import { Observable } from "rxjs/Observable";
import "rxjs/Rx";

import * as fromRoot from "./../../../reducers";
import { Store } from "@ngrx/store";

import { ISearchRequest } from "./search-request";

import { IStakeholder, recordEntity } from "./../models";

import { IConfiguration } from "./../../../modules/configuration/reducers";
import { INotification } from "app/reducers/notification";

@Injectable()
export class ReportingService {
  private config: IConfiguration;

  constructor(private store: Store<fromRoot.IState>, private http: HttpClient) {
    this.store.select(fromRoot.getConfig).subscribe((config) => {
      this.config = config;
    });
  }

  public getOrganisationProfile(id: string) {
    return this.http
      .get<IOrganisationProfile>(
        this.config.api.organisation + "search/organisations/" + id + "/profile"
      )
      .map((profile) => <IOrganisationProfile>profile);
  }

  public setSortCriteria(request: ISearchRequest) {
    if (!request.sortCriteria.fieldName) {
      switch (request.entity) {
        case recordEntity["organisation"]:
          request.sortCriteria.fieldName = "name";
          break;
        case recordEntity["lobbyist"]:
          request.sortCriteria.fieldName = "firstname";
          break;
        case recordEntity["client"]:
          request.sortCriteria.fieldName = "name";
          break;
        default:
          request.sortCriteria.fieldName = "name";
          break;
      }
    }
  }

  public search(request: ISearchRequest): Observable<any> {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    let options = { headers: headers };

    this.setSortCriteria(request);

    switch (request.entity) {
      case recordEntity["organisation"]:
        return this.http.post<ISearchResponse<IBusinessResult>>(
          this.config.api.organisation + "search/organisations",
          request,
          options
        );
      case recordEntity["lobbyist"]:
        return this.http.post<ISearchResponse<ILobbyistResult>>(
          this.config.api.organisation + "search/lobbyists",
          request,
          options
        );
      case recordEntity["client"]:
        return this.http.post<ISearchResponse<IClientResult>>(
          this.config.api.organisation + "search/clients",
          request,
          options
        );
      case recordEntity["all"]:
        return this.http.post<ISearchResponse<IAllResult>>(
          this.config.api.organisation + "search/all",
          request,
          options
        );
      default:
        return this.http.post<ISearchResponse<IBusinessResult>>(
          this.config.api.organisation + "search/organisations",
          request,
          options
        );
    }
  }

  public initSearchDownload(request: ISearchDownloadRequest): Observable<Blob> {
    return this.http.post(
      this.config.api.organisation + "search/download",
      request,
      { responseType: "blob" }
    );
  }

  public getLobbyistsForOrganisation(id: string): Observable<any> {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    let options = { headers: headers };

    return this.http.get<ISearchResponse<ILobbyistResult>>(
      this.config.api.organisation +
        "search/organisations/" +
        id +
        "/lobbyists",
      options
    );
  }

  public loadCurrentNotifications() {
    this.http
      .get<INotification>(this.config.api.organisation + "my/notifications")
      .subscribe((notification) =>
        this.store.dispatch({ type: "NOTIFY", payload: notification })
      );
  }
}

export interface IOrganisationSummary {
  id: string;
  displayName: string;
  tradingName: string;
  abn: string;
  registeredOn: Date;
  modifiedOn: Date;
  dateDeregistered: Date;
  hasSeenTutorial: boolean;
}

export interface ILobbyist {
  displayName: string;
  position: string;
  isFormerRepresentative: boolean;
  previousPositionOther: string;
  additionalNotes: string;
  cessationDate: Date;
}

export interface IClient {
  displayName: string;
  abn: string;
  datePublished: Date;
}

export interface IOrganisationProfile {
  summary: IOrganisationSummary;
  lobbyists: ILobbyist[];
  clients: IClient[];
  stakeholders: IStakeholder[];
}

export interface ISearchResponse<T> {
  totalRecords: number;
  pagingCookie: string;
  pageNumber: number;
  hasMoreRecords: boolean;
  count: number;
  resultSet: T[];
}

export interface IBusinessResult extends IOrganisationSummary {}

export interface ILobbyistResult extends ILobbyist {
  organisation: IOrganisationSummary;
}

export interface IClientResult extends IClient {
  organisation: IOrganisationSummary;
}

export interface IAllResult extends IOrganisationSummary {
  organisation: IOrganisationSummary;
  recordType: string;
}

export interface ISearchDownloadRequest {
  fileExtension: string;
  searchCriteria: ISearchRequest;
}
