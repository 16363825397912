import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { IWizardState, IWizardStep } from './../../models'

@Component({
    selector: 'app-wizard-nav',
    templateUrl: './wizard-nav.component.html',
    styleUrls: ['./wizard-nav.component.scss']
})
export class WizardNavComponent implements OnInit {

    @Input() state: IWizardState<any>;
    @Input() onStepValidate: () => boolean;
    @Output() stepChanged = new EventEmitter<number>();

    constructor() { }

    ngOnInit() { }

    onStepClicked(event, step) {
        event.preventDefault();
        if (step.index <= this.state.currentStepIndex || (this.onStepValidate && this.onStepValidate()))
            this.stepChanged.emit(step.index);
    }
}