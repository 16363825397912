import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  IFormStep,
  IFormSubstep,
  BreachSteps,
  BreachSubsteps,
} from "app/modules/organisation/models";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidationErrors,
  FormArray,
} from "@angular/forms";
import { Router } from "@angular/router";
import {
  ILookup,
  IStatDecFile,
} from "app/modules/organisation/services/registration-form.service";
import { BreachService } from "app/modules/organisation/services/breach.service";
import { tap, combineLatest, catchError } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IContactResponse } from "app/modules/controls/services/contact.service";

import { IConfiguration } from 'app/modules/configuration/reducers';
import { Store } from '@ngrx/store';
import * as fromRoot from './../../../../../reducers';

@Component({
  selector: "app-breach",
  templateUrl: "./breach.component.html",
  styleUrls: ["./breach.component.scss"],
})
export class BreachComponent implements OnInit {
  public steps: IFormStep[];
  public form: FormGroup;
  public titles: ILookup[];
  public relations: ILookup[];
  public codes: ILookup[];
  public codeParts: ILookup[];

  public isInitialised: boolean;
  public failedToSave: boolean;
  public isSaving: boolean;
  public showValidationSummary: boolean;

  public onSubstepChange: Subject<string> = new Subject<string>();

  public submitResponse: IContactResponse;

  public config: IConfiguration;

  @ViewChild("modalSaving", { read: false, static: false })
  modalSaving: ElementRef;
  @ViewChild("modalComplete", { read: false, static: false })
  modalComplete: ElementRef;
  @ViewChild("modalBlocked", { read: false, static: false })
  modalBlocked: ElementRef;
  @ViewChild("modalCancel", { read: false, static: false })
  modalCancel: ElementRef;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private breachService: BreachService,
    private store: Store<fromRoot.IState>
  ) {
    this.store.select(fromRoot.getConfig).subscribe(config => this.config = config);
  }

  ngOnInit() {
    this.form = this.configureForm();

    this.steps = [
      {
        title: BreachSteps["Your details"],
        form: <FormGroup>this.form.get("reporterDetails"),
        complete: null,
        canSave: false,
        substeps: [],
      },
      {
        title: BreachSteps["Who committed the breach?"],
        form: <FormGroup>this.form.get("selectedEntityType"),
        complete: null,
        canSave: false,
        substeps: [],
      },
      {
        title: BreachSteps["Select registrant"],
        form: <FormGroup>this.form.get("selectedEntity"),
        complete: null,
        canSave: false,
        substeps: [],
      },
      {
        title: BreachSteps["Details of the breach"],
        form: <FormGroup>this.form.get("breachDetails"),
        complete: null,
        canSave: false,
        substeps: [
          {
            name: BreachSubsteps["Details of the breach"],
            form: <FormGroup>this.form.get("breachDetails.basicDetails"),
          },
          {
            name: BreachSubsteps["Description of the breach"],
            form: <FormGroup>this.form.get("breachDetails.breachDetails"),
          },
          {
            name: BreachSubsteps["Supporting documentation"],
            form: <FormGroup>(
              this.form.get("breachDetails.supportingDocumentation")
            ),
          },
          { name: BreachSubsteps["Further contact"], form: null },
        ],
      },
    ];

    this.currentStep = this.steps[0];

    this.breachService.titles$
      .pipe(
        tap((data) => {
          this.titles = data;
          this.isInitialised = true;
        })
      )
      .subscribe();

    this.breachService.relations$
      .pipe(tap((data) => (this.relations = data)))
      .subscribe();

    this.breachService.codes$
      .pipe(tap((data) => (this.codes = data)))
      .subscribe();

    this.breachService.codeParts$
      .pipe(tap((data) => (this.codeParts = data)))
      .subscribe();
  }

  configureForm(): FormGroup {
    var report = this.fb.group({
      reporterDetails: this.fb.group({
        isAnonymous: [false, Validators.required],
        title: ["", Validators.required],
        firstName: [null, Validators.required],
        lastName: [null, Validators.required],
        email: [null, [Validators.email, Validators.required]],
        primaryPhoneNumber: [null],
      }),
      selectedEntityType: this.fb.group({
        isLobbyist: [null, Validators.required],
      }),
      selectedEntity: this.fb.group({
        organisation: this.fb.group({
          organisationId: [null, Validators.required],
          organisationName: null,
        }),
        lobbyists: this.fb.group({
          identifyLobbyists: [null, Validators.required],
          lobbyists: this.fb.array([]),
        }),
      }),
      breachDetails: this.fb.group({
        basicDetails: this.fb.group({
          dateOfBreach: [null, Validators.required],
          dateNotKnown: false,
          relationshipToBreach: [null, Validators.required],
          relationshipToBreachOther: null,
          locationOfBreach: [null, Validators.required],
          howBecameAwareOfBreach: [null, Validators.required],
        }),
        breachDetails: this.fb.group({
          codeOfConductSectionBreached: [null, Validators.required],
          codeOfConductSectionBreachedOther: null,
          Lobbyistdidnotkeeptheirlobbyingactivitiesseparatefromtheirpersonalactivitiesorinvolvementonbehalfofpoliticalparties: false,
          Lobbyistengagedincorruptdishonestillegalorunlawfulconductorunlawfullycausedorthreatenedanydetriment: false,
          Lobbyistfailedtosatisfythemselvesofthetruthandaccuracyofinformationtheyprovidedtoclientsthepublicorgovernmentrepresentatives: false,
          Lobbyistmademisleadingexaggeratedorextravagantclaimsthatmisrepresentedtheiraccesstogovernmentrepresentativespoliticalpartiesorotherpersons: false,
          Thelobbyistfailedtoinformgovernmentrepresentativesoftheirroleregistrationstatusclientorthenatureofthemattertheywishtoraise: false,
          descriptionOfBreach: [null, Validators.required],
        }),
        supportingDocumentation: this.fb.group({
          attachments: [{ value: [], disabled: true }],
        }),
        furtherContact: this.fb.group({
          furtherContact: [null, Validators.required],
          recaptchaResponse: [null, Validators.required],
        }),
      }),
    });

    if (this.config.versionNumber == "DEVELOPMENT") {
      report.get("breachDetails.furtherContact.recaptchaResponse").clearValidators();
    }

    report.get("reporterDetails.isAnonymous").valueChanges.subscribe(() => {
      var mandatories = [
        report.get("reporterDetails.title"),
        report.get("reporterDetails.firstName"),
        report.get("reporterDetails.lastName"),
        report.get("reporterDetails.email"),
      ];

      mandatories.forEach((mandatoryControl) => {
        mandatoryControl.clearValidators();
        if (!report.get("reporterDetails.isAnonymous").value) {
          mandatoryControl.setValidators(Validators.required);
        }
        mandatoryControl.updateValueAndValidity();
      });
    });

    var dateNotKnown = report.get("breachDetails.basicDetails.dateNotKnown");
    dateNotKnown.valueChanges.subscribe(() => {
      var breachDate = report.get("breachDetails.basicDetails.dateOfBreach");
      breachDate.clearValidators();
      if (dateNotKnown.value === false) {
        breachDate.setValidators(Validators.required);
      }

      breachDate.updateValueAndValidity();
    });

    var relation = report.get(
      "breachDetails.basicDetails.relationshipToBreach"
    );
    relation.valueChanges.subscribe(() => {
      var rtbo = report.get(
        "breachDetails.basicDetails.relationshipToBreachOther"
      );
      rtbo.clearValidators();
      if (relation.value === "Other") {
        rtbo.setValidators(Validators.required);
      }

      rtbo.updateValueAndValidity();
    });

    report
      .get("selectedEntity.organisation.organisationId")
      .valueChanges.subscribe(() => {
        report.get("selectedEntity.lobbyists.identifyLobbyists").setValue(null);
      });

    var identifyLobbyists = report.get(
      "selectedEntity.lobbyists.identifyLobbyists"
    );
    identifyLobbyists.valueChanges.subscribe(() => {
      var selectedEntity = report.get("selectedEntity.lobbyists");
      selectedEntity.clearValidators();
      if (identifyLobbyists.value === true) {
        selectedEntity.setValidators((): ValidationErrors | null => {
          if (
            !(<FormArray>selectedEntity.get("lobbyists")).controls.some(
              (lobbyist) => lobbyist.get("selected").value
            )
          ) {
            return {
              selectAtLeastOneLobbyist: true,
            };
          } else {
            return null;
          }
        });
      }
    });

    var code = report.get(
      "breachDetails.breachDetails.codeOfConductSectionBreached"
    );
    code.valueChanges.subscribe(() => {
      var cbo = report.get(
        "breachDetails.breachDetails.codeOfConductSectionBreachedOther"
      );
      cbo.clearValidators();
      if (code.value === "Other") {
        cbo.setValidators(Validators.required);
      }
      cbo.updateValueAndValidity();

      var bd = report.get("breachDetails.breachDetails");
      bd.clearValidators();
      if (code.value === "Failedtoadheretotheprinciplesofengagement") {
        bd.setValidators(this.validateAtLeastOneCodeOfConductPartSelected);
      }
      bd.updateValueAndValidity();
    });

    var isAnonymous = report.get("reporterDetails.isAnonymous");
    isAnonymous.valueChanges.subscribe(() => {
      var furtherContact = report.get(
        "breachDetails.furtherContact.furtherContact"
      );
      furtherContact.clearValidators();
      var breachDetailsStep = this.steps[3];
      if (isAnonymous.value === true) {
        breachDetailsStep.substeps[3].name =
          BreachSubsteps["Confirm submission"];
      } else {
        furtherContact.setValidators(Validators.required);
        breachDetailsStep.substeps[3].name = BreachSubsteps["Further contact"];
      }

      furtherContact.updateValueAndValidity();
    });

    return report;
  }

  validateAtLeastOneCodeOfConductPartSelected(
    form: FormGroup
  ): ValidationErrors | null {
    var parts = [
      "Lobbyistdidnotkeeptheirlobbyingactivitiesseparatefromtheirpersonalactivitiesorinvolvementonbehalfofpoliticalparties",
      "Lobbyistengagedincorruptdishonestillegalorunlawfulconductorunlawfullycausedorthreatenedanydetriment",
      "Lobbyistfailedtosatisfythemselvesofthetruthandaccuracyofinformationtheyprovidedtoclientsthepublicorgovernmentrepresentatives",
      "Lobbyistmademisleadingexaggeratedorextravagantclaimsthatmisrepresentedtheiraccesstogovernmentrepresentativespoliticalpartiesorotherpersons",
      "Thelobbyistfailedtoinformgovernmentrepresentativesoftheirroleregistrationstatusclientorthenatureofthemattertheywishtoraise",
    ];
    var anySelected = false;

    anySelected = parts.some((part) => form.get(part).value === true);

    if (anySelected) {
      return null;
    } else {
      return { noCodePartsSelected: true };
    }
  }

  _currentStep: IFormStep;
  currentSubstep: IFormSubstep;

  get currentStep(): IFormStep {
    return this._currentStep;
  }

  set currentStep(newValue: IFormStep) {
    this._currentStep = newValue;

    if (this._currentStep.substeps.length > 0) {
      this.currentSubstep = this._currentStep.substeps[0];
    } else {
      this.currentSubstep = null;
    }
  }

  get moreSubsteps(): boolean {
    return (
      this.currentStep.substeps.length > 0 &&
      this.currentStep.substeps.indexOf(
        this.currentStep.substeps.find(
          (s) => s.name === this.currentSubstep.name
        )
      ) <
        this.currentStep.substeps.length - 1
    );
  }

  isFirstStep() {
    var result = ["Review & submit"].indexOf(this.currentStep.title) !== -1;

    return result;
  }

  goBackToRegister() {
    if (this.form.dirty) {
      this.modalService
        .open(this.modalCancel, { centered: true, backdrop: "static" })
        .result.then(
          () => {
            this.router.navigateByUrl("/register");
          },
          () => {}
        ); // do nothing on 'take me back'
    } else {
      this.router.navigateByUrl("/register");
    }
  }

  goBack() {
    if (
      this.currentStep.substeps.findIndex(
        (s) => s.name === this.currentSubstep.name
      ) >= 1
    ) {
      this.goToPrevSubstep();
    } else {
      this.onWizardNavigateToStep(
        this.findStepForForm(this.currentStep.form) - 1
      );
    }
  }

  onWizardNavigateToStep(step: number) {
    if (step === -1) {
      this.goBackToRegister();
    } else {
      this.stepsNavigate(step);
    }
  }

  stepsNavigate(step: number) {
    if (step < 0) {
      step = 0;
    }

    if (step >= this.steps.length) {
      step = this.steps.length - 1;
    }

    if (!this.canNavigateToStep(step)) {
      return;
    }

    this.currentStep = this.steps[step];

    setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }));
  }

  goToNextStep() {
    if (this.checkValidity()) {
      this.onWizardNavigateToStep(this.stepsGetCurrent() + 1);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  goToNextSubstep() {
    if (this.checkValidity()) {
      var idxSubstep = this.currentStep.substeps.indexOf(
        this.currentStep.substeps.find(
          (s) => s.name === this.currentSubstep.name
        )
      );
      var substep = this.currentStep.substeps[idxSubstep + 1];
      this.setSubstep(substep);
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  canNavigateToStep(step: number): boolean {
    if (this.findStepForForm(this.currentStep.form) === step) {
      return false;
    }

    if (step < 0) {
      return false;
    }

    if (step >= this.steps.length) {
      return false;
    }

    // TODO: Validate the step we are navigating to (i.e. are the previous steps all validated and completed?)

    return true;
  }

  setSubstepByName(substep: string) {
    var newSubstep = this.currentStep.substeps.find((s) => s.name === substep);
    this.setSubstep(newSubstep);
  }

  setSubstep(substep: IFormSubstep) {
    this.currentSubstep = substep;
    this.onSubstepChange.next(substep.name.toString());
  }

  findStepForForm(form: FormGroup): number {
    for (let i = 0; i < this.steps.length; ++i) {
      const step = this.steps[i];
      if (step.form === form) {
        return i;
      }
    }

    return 0;
  }

  goToPrevSubstep() {
    var idxSubstep = this.currentStep.substeps.indexOf(
      this.currentStep.substeps.find((s) => s.name === this.currentSubstep.name)
    );
    this.setSubstep(this.currentStep.substeps[idxSubstep - 1]);
  }

  onFormSubmit() {
    if (
      this.canNavigateToStep(this.findStepForForm(this.currentStep.form) + 1)
    ) {
      this.onSaveAndContinue();
    } else if (
      !this.canNavigateToStep(this.findStepForForm(this.currentStep.form) + 1)
    ) {
      this.onFinaliseSubmission();
    } else {
      throw "Cannot submit form";
    }
  }

  onSaveAndContinue() {
    if (this.checkValidity()) {
      this.saveFormData()
        .pipe(
          tap(() => this.stepsNavigate(this.stepsGetCurrent() + 1)),
          catchError((err) => {
            this.failedToSave = true;
            return Observable.of({});
          })
        )
        .subscribe();
    } else {
      // Form is invalid
      // Scroll to validation summary (TODO) and flash it

      // TODO: Alternatively, scroll to first error in form?
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  onFinaliseSubmission() {
    if (this.checkValidity()) {
      this.saveFormData()
        .pipe(
          tap((response) => {
            if (response.isSuccess) {
              this.modalService
                .open(this.modalComplete, {
                  centered: true,
                  backdrop: "static",
                  keyboard: false,
                })
                .result.then(
                  () => {
                    this.router.navigateByUrl("/register");
                    return true;
                  },
                  () => {
                    return false;
                  }
                );
            }
          }),
          catchError((err) => {
            this.failedToSave = true;
            return Observable.of({});
          })
        )
        .subscribe();
    } else {
      // Form is invalid
      // Scroll to validation summary (TODO) and flash it

      // TODO: Alternatively, scroll to first error in form?
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  stepsGetCurrent() {
    return this.steps.indexOf(this.currentStep);
  }

  get currentForm(): AbstractControl {
    if (this.currentSubstep === null || this.currentSubstep.form === null) {
      return this.currentStep.form;
    } else {
      return this.currentSubstep.form;
    }
  }

  updateStepsValidityStatus() {
    for (let step of this.steps) {
      step.complete = step.form.valid;
    }
  }

  checkValidity(): boolean {
    var isValid = true;

    if (this.currentSubstep === null) {
      this.currentStep.form.updateValueAndValidity();
      this.currentStep.form.markAllAsTouched();
      isValid = this.currentStep.form.valid;
    } else if (this.currentForm != null) {
      var wasDisabled = this.currentForm.disabled;
      if (wasDisabled) this.currentForm.enable({ onlySelf: true });

      this.currentForm.updateValueAndValidity({ onlySelf: true });
      this.currentForm.markAllAsTouched();
      isValid = this.currentForm.valid;

      if (wasDisabled) this.currentForm.disable({ onlySelf: true });
    }

    this.showValidationSummary = !isValid;
    this.updateStepsValidityStatus();

    return isValid;
  }

  saveFormData(): Observable<any> {
    this.isSaving = true;
    this.failedToSave = false;

    this.modalService.open(this.modalSaving, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });

    const attachments: IStatDecFile[] = [];
    for (let document of this.form.get(
      "breachDetails.supportingDocumentation.attachments"
    ).value) {
      attachments.push({
        lobbyistIndex: 0,
        blob: document,
        fileName: document.name,
      });
    }

    let formData = this.form.value;
    this.form.disable();

    return this.breachService.submitBreachReport(formData, attachments).pipe(
      tap((response) => {
        this.modalService.dismissAll();
        this.isSaving = false;
        this.submitResponse = response;

        return Observable.of(response);
      }),
      catchError((err) => {
        this.modalService.dismissAll();

        console.error(err);

        this.isSaving = false;
        this.form.enable();

        if (err.status === 403) {
          this.modalService.open(this.modalBlocked, {
            centered: true,
            backdrop: "static",
            keyboard: false,
          });
          return Observable.of({ isSuccess: false });
        }

        return Observable.throwError(err);
      })
    );
  }

  isLobbyistChange = (isLobbyist: boolean) => {
    var step;
    var identifyLobbyists = this.form.get(
      "selectedEntity.lobbyists.identifyLobbyists"
    );
    if (isLobbyist) {
      step = {
        title: "Select lobbyist",
        form: <FormGroup>this.form.get("selectedEntity"),
        complete: null,
        canSave: false,
        substeps: [],
      };
      identifyLobbyists.setValue(true);
      identifyLobbyists.clearValidators();
    } else {
      step = {
        title: "Select organisation",
        form: <FormGroup>this.form.get("selectedEntity"),
        complete: null,
        canSave: false,
        substeps: [
          {
            name: "Select organisation",
            form: <FormGroup>this.form.get("selectedEntity.organisation"),
          },
          {
            name: "Select lobbyist/s",
            form: <FormGroup>this.form.get("selectedEntity.lobbyists"),
          },
        ],
      };
      identifyLobbyists.setValue(null);
      identifyLobbyists.setValidators(Validators.required);
    }
    identifyLobbyists.updateValueAndValidity();
    identifyLobbyists.setErrors(null);
    identifyLobbyists.markAsUntouched({ onlySelf: true });

    this.steps[2] = step;
  };
}
