import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { GoogleAnalyticsService } from '../../../../../services/google-analytics.service';

@Component({
  selector: 'app-step-introduction',
  templateUrl: './step-introduction.component.html',
  styleUrls: ['./step-introduction.component.scss']
})
export class StepIntroductionComponent implements OnInit {

  @Input() form: FormGroup;

  constructor(private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
    this.googleAnalyticsService.logPageVisit("step-introduction");
  }

}
