import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'blankSubstitute' })
export class BlankSubstitutePipe implements PipeTransform {
  transform(value): string {
    if (value === null || value.trim() === '' || value === undefined) {
      return '-';
    } else {
      return value;
    }
  }
}
