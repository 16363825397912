import { IAddress } from "./../../controls/components/address/address.models";
import { IAbnResult } from "./../../controls/models";
import { FormGroup, AbstractControl } from "@angular/forms";

export enum recordEntity {
  deregistered = "deregistered",
  organisation = "organisation",
  lobbyist = "lobbyist",
  client = "client",
  stakeholders = "stakeholders",
  all = "all",
  none = "",
}

export interface IMyProfileStatus {
  isOrganisationSetup: boolean;
  isOrganisationReviewed: boolean;
  isInitialised: boolean;
  abn: string;
  altAbnType: string;
  altAbn: string;
  companyName: string;
  tradingName: string;
  registeredOn: Date;
  status: string;
  isSuspended: boolean;
}

export interface IStatus {
  status: string;
  statusCode: number;
  statusReason: string;
}

export interface IMetrics {
  pendingRequestsCount: number;
  totalClientsCount: number;
  totalLobbyistsCount: number;
}

export interface IOrganisationDashboardSummary {
  abn: string;
  id: string;
  displayName: string;
  tradingName: string;
  registeredOn: Date;
  status: IStatus;
}

export interface IMyDashboard {
  metrics: IMetrics;
  organisation: IOrganisationDashboardSummary;
  isReportingPeriod: boolean;
  refreshStatDecs: boolean;
}

export interface IMyProfile {
  firstName: string;
  lastName: string;
  email: string;
  secondaryEmail: string;
  phone: string;
  mobile: string;
  isModified: boolean;
  status: IStatus;
  isSaved: boolean;
  submissionReason: number;
  earsUpn: string;
}

export interface IMyOrganisation {
  abn: IAbnResult;
  website: string;
  phoneNumber1: string;
  phoneNumber2: string;
  mobile: string;
  fax: string;
  postalAddress: IAddress;
  businessAddress: IAddress;
  submissionReason: number;
  isAddressPostalAddress: boolean;
  status: IStatus;
  isSaved: boolean;
  isModified: boolean;
  isSuspended: boolean;
  previouslyApproved: boolean;
  documents: any[];
  detailsAreUnchanged: boolean;
}

export interface IMyClient {
  id: string;
  submissionReason: number;
  isSaved: boolean;
  isModified: boolean;
  isSuspended: boolean;
  canRemove: boolean;
  abn: IAbnResult;
  clientNotes: string;
  status: IStatus;
  earsUpn: string;
}

export interface IMyLobbyist {
  id: string;
  submissionReason: number;
  isSaved: boolean;
  isModified: boolean;
  isSuspended: boolean;
  canRemove: boolean;
  firstName: string;
  lastName: string;
  position: string;
  isFormerRepresentative: boolean;
  previousPositionOther: string;
  additionalNotes: string;
  cessationDate: Date;
  status: IStatus;
  documents: any[];
}

export interface IStakeholder {
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  submissionReason: number;
  isSaved: boolean;
  isModified: boolean;
  isSuspended: boolean;
  canRemove: boolean;
  status: IStatus;
  datePublished: Date;
  dateDeregistered: Date;
  isDeregistered: boolean;
  earsUpn: string;
}

export interface IWizardModel {
  responsibleOfficer: IMyProfile;
  organisation: IMyOrganisation;
  clients: IMyClient[];
  stakeholders: IStakeholder[];
}

export interface IFormSubstep {
  name: RegistrationSubsteps | BreachSubsteps;
  form: AbstractControl;
}

export interface IFormStep {
  form: FormGroup;
  title: RegistrationSteps | BreachSteps;
  complete: boolean;
  canSave?: boolean;
  substeps: IFormSubstep[];
}

export enum RegistrationSteps {
  "Organisation details" = "Organisation details",
  "Owners" = "Owners",
  "Clients" = "Clients",
  "Lobbyists" = "Lobbyists",
  "Responsible officers" = "Responsible officers",
  "Introduction" = "Introduction",
  "Sole tradership" = "Sole tradership",
  "Review & submit" = "Review & submit",
}

export enum RegistrationSubsteps {
  // org steps
  "Business details" = "Business details",
  "Contact" = "Contact",
  "Business address" = "Business address",
  "Postal address" = "Postal address",
  // ro steps
  "Officers list" = "Officers list",
  "Add officer/s" = "Add officer/s",
  "Officers overview" = "Officers overview",
  // owner steps
  "Owners list" = "Owners list",
  "Owner type" = "Owner type",
  "Add owner/s" = "Add owner/s",
  "Owners overview" = "Owners overview",
  // lobbyist steps
  "Lobbyists list" = "Lobbyists list",
  "Add lobbyist/s" = "Add lobbyist/s",
  "Former details" = "Former details",
  "Lobbyists overview" = "Lobbyists overview",
  // client steps
  "Clients list" = "Clients list",
  "Add client/s" = "Add client/s",
  "Clients overview" = "Clients overview",
  "Foreign Influence Transparency Scheme Obligation" = "Further obligations",
}

export enum BreachSteps {
  "Your details" = "Your details",
  "Who committed the breach?" = "Who committed the breach?",
  "Select registrant" = "Select registrant",
  "Details of the breach" = "Details of the breach",
}

export enum BreachSubsteps {
  "Details of the breach" = "Details of the breach",
  "Description of the breach" = "Description of the breach",
  "Supporting documentation" = "Supporting documentation",
  "Further contact" = "Further contact",
  "Confirm submission" = "Confirm submission",
}

export interface IHasSeenTurtorialResponse {
  HasSeenTutorial: boolean;
}
