import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as fromRoot from './../../../../reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  private config: any;
  public isCollapsed = true;

  constructor(private store: Store<fromRoot.IState>) {
    this.store.select(fromRoot.getConfig).subscribe(config => this.config = config);
  }

  ngOnInit() {
  }

}
