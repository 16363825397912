import { Inject, Injectable } from '@angular/core';
import { Http, Response, Headers, ResponseOptions, RequestOptions, RequestOptionsArgs } from '@angular/http';
import { MockConnection } from "@angular/http/testing";
import { Observable } from "rxjs";

import { MockBackendService } from './mock-backend.service';
import { IRequestRouter } from './request-router';

@Injectable()
export class PassThroughService implements IRequestRouter {
    constructor() { }

    public routeRequest(connection: MockConnection, realHttp: Http): Observable<void> {
        let url = connection.request.url;
        console.log("Passing through to real HTTP for: " + url);
        const responseObservable: Observable<void> = realHttp.get(url).map(
            response => {
                this.onSuccess(connection, response);
            },
            error => {
                this.onSuccess(connection, error)
            }
        );

        return responseObservable;
    }

    private onSuccess(connection: MockConnection, response: Response): void {
        connection.mockRespond(response);
    }

    private onError(connection: MockConnection, response: Response): void {
        const response2 = new Response(new ResponseOptions({
            type: response.type,
            status: response.status,
            statusText: response.statusText,
            url: response.url,
            body: response.text()
        }));

        connection.mockRespond(response2);
    }
}
