import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';


@Component({
  selector: 'app-step-select-entity',
  templateUrl: './step-select-entity.component.html',
  styleUrls: ['./step-select-entity.component.scss']
})
export class StepSelectEntityComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() isLobbyistChange: (isLobbyistChange: boolean) => void;

  public lobbyist: any;

  constructor() { }

  ngOnInit() {
  }

  onSelectedLobbyist(lobbyist: any) {
    this.form.get('organisation.organisationId').setValue(lobbyist.organisation.id);
    this.form.get('organisation.organisationName').setValue(lobbyist.organisation.displayName);

    var lobbyists = <FormArray>this.form.get('lobbyists.lobbyists');
    lobbyists.clear();

    var fgLobbyist = new FormGroup({ "displayName": new FormControl(lobbyist.displayName), "id": new FormControl(lobbyist.id), "selected": new FormControl(true) });
    lobbyists.push(fgLobbyist);
  }
}
