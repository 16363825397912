import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { DefaultComponent } from "./modules/content/components/default/default.component";
import { HomePageComponent } from "./modules/content/components/home-page/home-page.component";
import { ContentComponent } from "./modules/content/components/content/content.component";
import { ContactComponent } from "./modules/content/components/contact/contact.component";
import { FaqsComponent } from "./modules/content/components/faqs/faqs.component";
import { PageNotFoundComponent } from "./modules/content/components/page-not-found/page-not-found.component";



import { AuthGuard } from './modules/identity/gaurds/auth.gaurd';


export const routes: Routes = [
    {
        path: "",
        component: DefaultComponent,
        children: [            
            {
                path: 'copyright',
                component: ContentComponent,
                data: {
                    title: 'Copyright',
                    id: 'copyright',
                    breadcrumb: {
                        label: 'Copyright',
                        isClickable: true
                    }
                }
            },
            {
                path: 'disclaimer',
                component: ContentComponent,
                data: {
                    title: 'Disclaimer',
                    id: 'disclaimer',
                    breadcrumb: {
                        label: 'Disclaimer',
                        isClickable: true
                    }
                }
            },
            {
                path: 'privacy',
                component: ContentComponent,
                data: {
                    title: 'Privacy',
                    id: 'privacy',
                    breadcrumb: {
                        label: 'Privacy',
                        isClickable: true
                    }
                }
            },
            {
              path: "home",
              redirectTo: "/register",
              pathMatch: "full"
            },
            {
                path: "",
                redirectTo: "/register",
                pathMatch: "full"
            },
            {
                path: '**',
                component: PageNotFoundComponent,
                data: {
                    title: '404 - Page Not Found',
                    breadcrumb: {
                        label: '404',
                        isClickable: false
                    }
                }
            }
        ]
    }
];

export const appRoutes: ModuleWithProviders = RouterModule.forRoot(routes);
