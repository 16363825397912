import { Injectable } from "@angular/core";
import { Observable, timer, throwError, Subject } from "rxjs";
import * as fromRoot from "./../../../reducers";
import { Store } from "@ngrx/store";
import { map, tap, catchError } from "rxjs/operators";
import { AuthService } from "./../../identity/services/auth.service";
import {
  Http,
  Response,
  Headers,
  RequestOptions,
  ResponseContentType,
  ResponseType,
} from "@angular/http";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { IUserPrincipal } from "./../../identity/identity.models";
import { HttpErrorResponse } from "@angular/common/http";
import { MyOrganisationService } from "./my-organisation.service";

export interface IRegistrationFormData {
  currentWizardStep: number;

  introduction: {};
  fitsConfirmed: boolean | null;

  soleTradership: {
    isSoleTrader: boolean;
    hasChanged: boolean;
  };

  organisationDetails: {
    id: string;
    abn: string;
    hasNoAbn: boolean;
    businessEntityName: string;
    hasTradingName: boolean;
    tradingName: string;
    primaryPhoneNumber: string;
    secondaryPhoneNumber: string;
    website: string;
    businessEntityPhysicalAddress: IRegistrationFormAddress;
    businessEntityPostalAddress: IRegistrationFormAddress;
    status: IEntityStatus;
    submissionReason: number;
    previouslyApproved: boolean;
    lastActiveTab: string;
    hasChanged: boolean;
    isSuspended: boolean;
  };

  responsibleOfficer: {
    id: string;
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    primaryPhoneNumber: string;
    secondaryPhoneNumber: string;
    status: IEntityStatus;
    submissionReason: number;
    hasChanged: boolean;
    hasSeenTutorial: boolean;
    earsupn: string;
  };

  owners: {
    owners: any[];
  };

  lobbyists: {
    lobbyists: any[];
  };

  clients: {
    clients: any[];
  };

  responsibleOfficers: {
    responsibleOfficers: any[];
  };
}

export interface IEntityStatus {
  status: string;
  statusCode: number;
  statusReason: string;
  statusDescription: string;
}

export interface IStatDecFile {
  lobbyistIndex: number;
  blob: File;
  fileName: string;
}

interface IRegistrationFormAddress {
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  suburb: string;
  state: string;
  postcode: string;
  country: string;
}

export interface ILookup {
  text: string;
  value: string;
}

export interface IPosition {
  text: string;
  value: string;
}

export interface IReportingPeriodStatus {
  id: string;
  endDateTime: string;
  startDateTime: string;
  status: string;
  portalMessage: string;
  isStatDecMandatory: boolean;
}

interface IResponsibleOfficer {
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  primaryPhoneNumber: string;
  secondaryPhoneNumber: string;
  status: IEntityStatus;
  submissionReason: number;
  hasChanged: boolean;
  accountActivationStatus: string;
  earsupn: string;
}

@Injectable()
export class RegistrationFormService {
  private config: any;
  private userPrincipal: IUserPrincipal;
  formData$: Observable<IRegistrationFormData>;
  originalData$: Observable<IRegistrationFormData>;
  titles$: Observable<ILookup[]>;
  positions$: Observable<IPosition[]>;
  positionLevels$: Observable<IPosition[]>;
  reportingPeriod$: Observable<IReportingPeriodStatus>;
  notifications$: Observable<object>;

  public constructor(
    private store: Store<fromRoot.IState>,
    private http: HttpClient,
    private authService: AuthService
  ) {
    console.log(`debuglog: rego service constructor`);

    this.store
      .select(fromRoot.getConfig)
      .subscribe((config) => (this.config = config));
    this.store
      .select(fromRoot.getUserPrincipal)
      .subscribe((principal) => (this.userPrincipal = principal));

      console.log(`debuglog: rego service constructor after this.config ${this.config.api.organisation}`);

    if (this.userPrincipal.accessToken == null) {
      return;
    }

    var headers = this.authService.createAuthorizationHttpHeaders();
    var options = { headers: headers };

    this.formData$ = this.http
      .get(this.config.api.organisation + "my/wizard", options)
      .pipe(map((response: any) => <IRegistrationFormData>response));

    this.originalData$ = this.http
      .get(this.config.api.organisation + "my/original", options)
      .pipe(map((response: any) => <IRegistrationFormData>response));

    this.titles$ = this.http
      .get(this.config.api.organisation + "my/lookup/titles", options)
      .pipe(map((response: any) => <ILookup[]>response));

    this.positions$ = this.http
      .get(this.config.api.organisation + "my/lookup/positions", options)
      .pipe(map((response: any) => <IPosition[]>response));

    this.positionLevels$ = this.http
      .get(this.config.api.organisation + "my/lookup/positionlevels", options)
      .pipe(map((response: any) => <IPosition[]>response));

    this.reportingPeriod$ = this.http
      .get(this.config.api.organisation + "my/reporting-period-status", options)
      .pipe(
        map((response: any) => <IReportingPeriodStatus>response),
        tap((status) => {
          return this.store.dispatch({
            type: "REPORTING_PERIOD_NOTIFICATION",
            payload: status,
          })
        }
        )
      );
  }

  public saveFormData(value: IRegistrationFormData, statDecs: IStatDecFile[]) {
    return this.saveMyNewOrganisation(value, statDecs);
  }

  public finaliseApplicationForRegistration(value: IRegistrationFormData) {
    const headers = this.authService.createAuthorizationHttpHeaders();

    const options = { headers: headers };

    console.log(`finalise request ${JSON.stringify(value)}`);

    return this.http
      .post(
        this.config.api.organisation + "my/wizardSubmitRegistration",
        value,
        options
      )
      .pipe(
        tap(() => {
          this.getMyStatus(headers).subscribe();
        }),
        map((response: any) => {
          console.log(`finalise response ${JSON.stringify(response)}`);
          return response;
        })
      );
  }

  private getMyDetails<T>(action: string): Promise<T> {
    return new Promise((resolve, reject) => {
      if (this.authService.principal.isAuthenticated) {
        var headers = new HttpHeaders({});
        this.authService
          .createAuthorizationHeader(headers)
          .then(() => {
            var options = { headers: headers };
            return this.http
              .get(this.config.api.organisation + "my/" + action, options)
              .map((response) => <T>response)
              .subscribe((details) => {
                resolve(details);
                console.log(`DEBUGLOG: GetMyDetails Log ${details}`);
              });
          })
          .catch(() => {
            throw new Error("User is not authenticated");
          });
      }
    });
  }

  private handleError(error: HttpErrorResponse) {
    var blocked: boolean = false;
    if (error.status == 403) {
      blocked = true;
    } else if (error instanceof Response) {
      if ((<Response>error).type == ResponseType.Error) {
        blocked = true;
      }
    }

    return throwError(error);
  }

  private getMyStatus(headers: HttpHeaders): Observable<any> {
    return this.http
      .get(this.config.api.organisation + "my/status", { headers: headers })
      .map((profile) => {
        this.store.dispatch({
          type: "MYPROFILESTATUSCHANGED",
          payload: profile,
        });
      });
  }

  private saveMyDetails<T>(
    action: string,
    model: T,
    formData = new FormData()
  ): Observable<T> {
    var headers = this.authService.createAuthorizationHttpHeaders();

    formData.append("data", JSON.stringify(model));

    console.log(`DEBUGLOG: saveMyDetails ${action} ${JSON.stringify(model)}`);

    this.http
      .post(
        this.config.api.organisation + "my/beginorgregistration",
        {},
        { headers }
      )
      .map((res) => res);

    return this.http
      .post(this.config.api.organisation + "my/" + action, formData, {
        headers: headers,
      })
      .pipe(
        catchError((error) => {
          console.error(error);

          return throwError(error);
        }),
        tap(() => {
          this.getMyStatus(headers).subscribe();
        }),
        map((response: any) => {
          console.log(`DEBUGLOG: response ${JSON.stringify(response)}`);
          return <T>response;
        })
      );
  }

  public getMyOrganisation(): Promise<IRegistrationFormData> {
    return this.getMyDetails<IRegistrationFormData>("organisation");
  }

  public deregisterMyOrganisation(
    model: IRegistrationFormData
  ): Observable<IRegistrationFormData> {
    const headers = this.authService.createAuthorizationHttpHeaders();

    const options = { headers: headers };
    return this.http
      .post(this.config.api.organisation + "my/deregister", model, options)
      .pipe(
        tap(() => {
          this.getMyStatus(headers).subscribe();
        }),
        map((response: any) => response)
      );
  }

  public activateMyOrganisation(
    model: IRegistrationFormData
  ): Observable<IRegistrationFormData> {
    const headers = this.authService.createAuthorizationHttpHeaders();

    const options = { headers: headers };

    return this.http
      .post(this.config.api.organisation + "my/activate", model, options)
      .pipe(
        tap(() => {
          this.getMyStatus(headers).subscribe();
        }),
        map((response: any) => response)
      );
  }

  public saveMyNewOrganisation(
    myNewOrganisation: IRegistrationFormData,
    statDecs: IStatDecFile[]
  ): Observable<IRegistrationFormData> {
    var formData = new FormData();
    for (let statDec of statDecs) {
      formData.append(
        `statDecs[${statDec.lobbyistIndex}]`,
        statDec.blob,
        statDec.fileName
      );
    }
    return this.saveMyDetails<IRegistrationFormData>(
      "wizard",
      myNewOrganisation,
      formData
    );
  }

  public beginOrgRegistration(): void {
    const headers = this.authService.createAuthorizationHttpHeaders();
    const options = { headers: headers };
    const url = this.config.api.organisation + "my/beginorgregistration";
    try {
      this.http
        .post(url, {}, options)
        .subscribe((res) => console.log(`${JSON.stringify(res)}`));
    } catch (e) {
      console.log(`DEBUGLOG: error? ${e}`);
    }
  }

  public resendActivationEmail(
    model: IResponsibleOfficer
  ): Observable<IResponsibleOfficer> {
    const headers = this.authService.createAuthorizationHttpHeaders();

    const options = { headers: headers };

    return this.http
      .post(
        this.config.api.organisation + "my/resendverification",
        model,
        options
      )
      .pipe(map((response: any) => response));
  }

  private deregistrationRequestedSource = new Subject<void>();
  deregistrationRequested$ = this.deregistrationRequestedSource.asObservable();

  public requestDeregistration() {
    this.deregistrationRequestedSource.next();
  }

  private activationRequestedSource = new Subject<void>();
  activationRequested$ = this.activationRequestedSource.asObservable();

  public requestActivation() {
    this.activationRequestedSource.next();
  }

  private recordSelectedSource = new Subject<any>();
  recordSelected$ = this.recordSelectedSource.asObservable();

  public selectRecord(formData: any) {
    this.store.dispatch({ type: "FORM_SELECTED", payload: formData });
  }
}
