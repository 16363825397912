import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn } from "@angular/forms";

@Component({
    selector: 'app-business-name',
    templateUrl: './business-name.component.html',
    styleUrls: ['./business-name.component.scss']
})
export class BusinessNameComponent implements OnInit {
    
    @Input() public value: string;
    @Input() public controlName: string;
    @Output() changed: EventEmitter<string> = new EventEmitter(); 
    @Input() public readonly: boolean = false;
    @Input() public formGroup: FormGroup;
    @Input() validationMessages: any;
    @Input() public formErrors: any;
    @Input() public formModel: any;

    constructor() { }

    ngOnInit() {
        this.formModel[this.controlName] = new FormControl(this.controlName,
            [Validators.required, Validators.maxLength(50)]);
        this.validationMessages[this.controlName] = {
            'required': 'The business name is required (maximum 50 characters)'
        };
        this.formErrors[this.controlName] = '';
        this.formGroup.addControl(this.controlName, this.formModel[this.controlName])
        this.formGroup.valueChanges.subscribe(data => this.valueChanged(this.formGroup, data));
    }

    public valueChanged(form: FormGroup, data?: any) {
        this.formErrors[this.controlName] = '';
        const control = this.formGroup.get(this.controlName);
        if (control && control.dirty && !control.valid) {
            const messages = this.validationMessages[this.controlName];
            for (const key in control.errors) {
                this.formErrors[this.controlName] += messages[key] + ' ';
            }
        }
    }

    public onChanged(e: any) {
        this.changed.emit();
    }
}
