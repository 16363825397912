import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";

import {
  ReportingService,
  ISearchResponse,
} from "../../../../organisation/services/reporting.service";
import { ISearchRequest } from "../../../../organisation/services/search-request";
import { SortOrder } from "../../../../organisation/services/sort-criteria";
import {
  IOrganisationSummary,
  HistoryService,
} from "app/modules/history/services/history.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SearchService } from "../../../services/search.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-result-register",
  templateUrl: "./result-register.component.html",
  styleUrls: ["./result-register.component.scss"],
})
export class RegisterResultComponent implements OnInit {
  @ViewChild("modalLoading", { read: false, static: false })
  modalLoading: ElementRef;

  public isSearching: boolean = true;
  public searchResult: any;
  public sortOrder = SortOrder;
  public error: string = undefined;

  public selectedEntityId: string = null;

  constructor(
    private reportingService: ReportingService,
    private historyService: HistoryService,
    private modalService: NgbModal,
    public searchService: SearchService,
    private router: Router
  ) {}

  allClick(event, result) {
    if (result.recordType === "Deregistered organisation") {
      this.throwDeregisteredHistoryModal(event, result);
    } else {
      this.router.navigateByUrl("/organisation/" + result.id + "/profile");
    }
  }

  ngOnInit() {
    this.searchService.isSearching.subscribe((result) => {
      this.isSearching = result;
    });

    this.searchService.searchResult.subscribe((result) => {
      this.searchResult = result;
    });

    this.searchService.error.subscribe((error) => {
      this.error = error;
    });
  }

  isAsc(column: string): boolean {
    return (
      this.searchService.searchRequest.sortCriteria.sortOrder ==
        this.sortOrder.ascending &&
      this.searchService.searchRequest.sortCriteria.fieldName == column
    );
  }

  toggleSort(column: string) {
    this.searchService.searchRequest.sortCriteria.fieldName = column;
    var currentOrder = this.searchService.searchRequest.sortCriteria.sortOrder;
    this.searchService.searchRequest.sortCriteria.sortOrder =
      currentOrder == this.sortOrder.ascending
        ? this.sortOrder.descending
        : this.sortOrder.ascending;

    this.searchService.doSearch();
  }

  select(entity: any) {
    this.selectedEntityId = entity.id;
  }

  throwDeregisteredHistoryModal(
    event: Event,
    organisation: IOrganisationSummary
  ) {
    event.preventDefault();
    this.modalService.open(this.modalLoading, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
    this.historyService.getOrganisationHistory(organisation.id);
  }
}
