import { Injectable } from "@angular/core";

import {
  Http,
  Response,
  Headers,
  RequestOptions,
  ResponseContentType,
} from "@angular/http";

import { Observable } from "rxjs/Observable";
import "rxjs/Rx";

import * as fromRoot from "./../../../reducers";
import { Store } from "@ngrx/store";

import { AuthService } from "./../../identity/services/auth.service";

import { IUserPrincipal } from "./../../identity/identity.models";
import {
  IMyProfile,
  IMyOrganisation,
  IMyClient,
  IMyLobbyist,
  IStakeholder,
  IWizardModel,
  IMyDashboard,
  IMyProfileStatus,
  IHasSeenTurtorialResponse,
} from "./../models";
import { AuthGuard } from "app/modules/identity/gaurds/auth.gaurd";
import { IConfiguration } from "app/modules/configuration/reducers";

@Injectable()
export class MyOrganisationService {
  private config: IConfiguration;
  private userPrincipal: IUserPrincipal;
  private isReady: boolean;

  constructor(
    private store: Store<fromRoot.IState>,
    private http: Http,
    private authService: AuthService
  ) {
    this.store
      .select(fromRoot.getConfig)
      .subscribe((config) => (this.config = config));
    this.store
      .select(fromRoot.getUserPrincipal)
      .subscribe((principal) => (this.userPrincipal = principal));
  }

  private getMyDetails<T>(action: string): Promise<T> {
    return new Promise((resolve, reject) => {
      if (this.authService.principal.isAuthenticated) {
        var headers = new Headers({});
        this.authService
          .createAuthorizationHttpHeader(headers)
          .then(() => {
            var options = new RequestOptions({ headers: headers });
            console.log(`debuglog: calling my service ${action} ${JSON.stringify(headers)}`);

            return this.http
              .get(this.config.api.organisation + "my/" + action, options)
              .map((response) => {
                return response.json();
              })
              .map((response) => <T>response)
              .subscribe((details) => {
                resolve(details);
              });
          })
      } else {
        throw new Error("User is not authenticated");
      }
    });
  }

  private saveMyDetails<T>(action: string, model: T): Promise<T> {
    return new Promise((resolve) => {

      if (this.authService.principal.isAuthenticated) {
        var headers = new Headers({});
        this.authService.createAuthorizationHttpHeader(headers).then(() => {
          var options = new RequestOptions({ headers: headers });

          return this.http
            .post(
              this.config.api.organisation + "my/" + action,
              model,
              options
            )
            .map((response) => response.json())
            .map((response) => <T>response)
            .subscribe((details) => {
              resolve(details);
            });
        });
      } else {
        throw new Error("User is not authenticated");
      }
    });
  }

  public getMyProfileStatus(): Promise<IMyProfileStatus> {
    return this.getMyDetails<IMyProfileStatus>("status");
  }

  public getMyDashboard(): Promise<IMyDashboard> {
    return this.getMyDetails<IMyDashboard>("dashboard");
  }

  public getMyProfile(): Promise<IMyProfile> {
    return this.getMyDetails<IMyProfile>("profile");
  }

  public saveMyProfile(myProfile: IMyProfile): Promise<IMyProfile> {
    return this.saveMyDetails<IMyProfile>("profile", myProfile);
  }

  public getMyClients(): Promise<IMyClient[]> {
    return this.getMyDetails<IMyClient[]>("clients");
  }

  public saveMyClients(myClients: IMyClient[]): Promise<IMyClient[]> {
    const clients = myClients.filter(
      (x) =>
        x.isModified &&
        [981660002, 981660003, 981660004, 981660007].indexOf(
          x.status.statusCode
        ) != -1
    );
    return clients.length
      ? this.saveMyDetails<IMyClient[]>("clients", clients)
      : Promise.resolve(myClients);
  }

  public getMyLobbyists(): Promise<IMyLobbyist[]> {
    return this.getMyDetails<IMyLobbyist[]>("lobbyists");
  }

  private saveMyLobbyist(lobbyist: IMyLobbyist): Promise<IMyLobbyist> {
    return new Promise((resolve, reject) => {
      if (this.authService.principal.isAuthenticated) {
        let formData: FormData = new FormData();

        formData.append("id", lobbyist.id);
        formData.append("firstName", lobbyist.firstName);
        formData.append("lastName", lobbyist.lastName);
        formData.append("position", lobbyist.position);
        formData.append(
          "isFormerRepresentative",
          lobbyist.isFormerRepresentative.toString()
        );
        formData.append(
          "submissionReason",
          lobbyist.submissionReason.toString()
        );
        formData.append(
          "previousPositionOther",
          lobbyist.previousPositionOther
        );
        formData.append("additionalNotes", lobbyist.additionalNotes);
        formData.append(
          "cessationDate",
          lobbyist.isFormerRepresentative && lobbyist.cessationDate
            ? typeof lobbyist.cessationDate === "string"
              ? lobbyist.cessationDate
              : lobbyist.cessationDate.toISOString()
            : null
        );
        formData.append("status[status]", lobbyist.status.status);
        formData.append(
          "status[statusCode]",
          lobbyist.status.statusCode.toString()
        );
        formData.append("status[statusReason]", lobbyist.status.statusReason);

        for (let i = 0; i < lobbyist.documents.length; i++) {
          formData.append(
            `documents`,
            lobbyist.documents[i],
            lobbyist.documents[i].name
          );
        }

        let xhr: XMLHttpRequest = new XMLHttpRequest();
        xhr.open("POST", this.config.api.organisation + "my/lobbyist", true);
        xhr.setRequestHeader("enctype", "multipart/form-data");
        xhr.setRequestHeader("Cache-Control", "no-cache");
        xhr.setRequestHeader("Cache-Control", "no-store");
        xhr.setRequestHeader("Pragma", "no-cache");

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              resolve(<IMyLobbyist>JSON.parse(xhr.response));
            } else {
              reject(xhr.response);
            }
          }
        };

        this.authService.createXhrAuthorizationHeader(xhr).then(() => {
          xhr.send(formData);
        });
      }
    });
  }

  public saveMyLobbyists(myLobbyists: IMyLobbyist[]): Promise<IMyLobbyist[]> {
    return new Promise((resolve, reject) => {
      const promises: Promise<IMyLobbyist>[] = [];
      const lobbyists = myLobbyists.filter(
        (x) =>
          x.isModified &&
          [981660000, 981660002, 981660003, 981660008].indexOf(
            x.status.statusCode
          ) != -1
      );
      if (!lobbyists.length) {
        resolve(myLobbyists);
      } else {
        for (let lobbyist of lobbyists) {
          promises.push(this.saveMyLobbyist(lobbyist));
        }

        Promise.all(promises)
          .then(() => this.getMyLobbyists())
          .then(resolve)
          .catch(() => {
            console.error(
              "An error occurred saving information for one or more lobbyists."
            );
            reject();
          });
      }
    });
  }

  public getMyOrganisation(): Promise<IMyOrganisation> {
    return this.getMyDetails<IMyOrganisation>("organisation");
  }

  public saveMyOrganisation(
    myOrganisation: IMyOrganisation
  ): Promise<IMyOrganisation> {
    if (
      [981660002, 981660003, 981660004, 981660007].indexOf(
        myOrganisation.status.statusCode
      ) != -1
    )
      return this.saveMyDetails<IMyOrganisation>(
        "organisation",
        myOrganisation
      );
    return Promise.resolve(myOrganisation);
  }

  public submitMyOrganisation() {
    // ! todo: need to add "save before submit" functionality
    return new Promise((resolve, reject) => {
      if (this.authService.principal.isAuthenticated) {
        var headers = new Headers({});
        this.authService.createAuthorizationHttpHeader(headers).then(() => {
          var options = new RequestOptions({ headers: headers });
          return this.http
            .post(
              this.config.api.organisation + "my/organisation/submit",
              null,
              options
            )
            .subscribe(() => {
              resolve(true);
            });
        });
      } else {
        throw new Error("User is not authenticated");
      }
    });
  }

  public getMyStakeholders(): Promise<IStakeholder[]> {
    return this.getMyDetails<IStakeholder[]>("stakeholders");
  }

  public saveMyStakeholders(
    myStakeholders: IStakeholder[]
  ): Promise<IStakeholder[]> {
    const stakeholders = myStakeholders.filter(
      (x) =>
        x.isModified &&
        [981660000, 981660002, 981660003, 981660008].indexOf(
          x.status.statusCode
        ) != -1
    );
    return stakeholders.length
      ? this.saveMyDetails<IStakeholder[]>("stakeholders", stakeholders)
      : Promise.resolve(myStakeholders);
  }

  public saveMyNewOrganisation(
    myNewOrganisation: IWizardModel
  ): Promise<IWizardModel> {
    return new Promise((resolve, reject) => {
      console.log(`DEBUGLOG: saveMyNewOrganisation ${myNewOrganisation}`);

      this.saveMyDetails<IWizardModel>("wizard", myNewOrganisation)
        .then((wizardModel) => {
          this.getMyProfileStatus().then((myProfileStatus) => {
            this.store.dispatch({
              type: "MYPROFILESTATUSCHANGED",
              payload: myProfileStatus,
            });

            resolve(wizardModel);
          });
        })
        .catch(() => {
          throw new Error("User is not authenticated");
        });
    });
  }

  public emailMyChanges(myOrganisation: IMyOrganisation) {
    return new Promise((resolve, reject) => {
      if (this.authService.principal.isAuthenticated) {
        let formData: FormData = new FormData();

        // organisation
        formData.append("abn", myOrganisation.abn.abn);
        formData.append("tradingName", myOrganisation.abn.tradingName);
        formData.append("legalName", myOrganisation.abn.legalName);

        // attachments
        for (let i = 0; i < myOrganisation.documents.length; i++) {
          formData.append(
            `documents`,
            myOrganisation.documents[i],
            myOrganisation.documents[i].name
          );
        }

        let xhr: XMLHttpRequest = new XMLHttpRequest();
        xhr.open(
          "POST",
          this.config.api.organisation + "my/manualUpdate",
          true
        );
        xhr.setRequestHeader("enctype", "multipart/form-data");
        xhr.setRequestHeader("Cache-Control", "no-cache");
        xhr.setRequestHeader("Cache-Control", "no-store");
        xhr.setRequestHeader("Pragma", "no-cache");

        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              resolve(true);
            } else {
              reject(xhr.response);
            }
          }
        };

        this.authService.createXhrAuthorizationHeader(xhr).then(() => {
          xhr.send(formData);
        });
      } else {
        throw new Error("User is not authenticated");
      };
    });
  }

  public initializeWizard(): Observable<IWizardModel> {
    if (this.userPrincipal.isAuthenticated) {
      var headers = new Headers({});
      this.authService.createAuthorizationHttpHeader(headers);
      var options = new RequestOptions({ headers: headers });

      return this.http
        .get(this.config.api.organisation + "my/wizard", options)
        .map((response) => response.json())
        .map((response) => <IWizardModel>response);
    }

    throw new Error("User is not authenticated");
  }
}
