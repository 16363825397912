import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/modules/identity/services/auth.service';

@Component({
  selector: 'app-dashboard', // 'app-page-not-found',
  templateUrl: './signin-failed.component.html',
  styleUrls: ['./signin-failed.component.scss']
})
export class SignInFailedComponent implements OnInit {

  constructor(
      private authService: AuthService,
      private router: Router
    ) { }

  ngOnInit() {
    /** This makes "Lobbyist Portal" 'active' or highlighted. 
     * Done this way as Angular's native RouterLinkActive sets 'active' 
     * attribute based on route link being open. However, 'user/signin' 
     * route is not included in this list, so will not populate the class.
     */
    document.getElementById('lobbyist-portal-nav-item').classList.add("active");
  }

  @HostListener('window:beforeunload')
  ngOnDestroy() {
      document.getElementById('lobbyist-portal-nav-item').classList.remove("active");
  }

  public logoutUser() {
    this.authService.logout();
    this.router.navigate(["/user/signin"]);
  }

}
