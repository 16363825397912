import { ActionReducer, Action } from '@ngrx/store';
import { IReportingPeriodStatus } from "app/modules/organisation/services/registration-form.service";

const initialState: IReportingPeriodStatus = {
  id: null,
  endDateTime: null,
  startDateTime: null,
  status: null,
  portalMessage: "UNINITIALISED",
  isStatDecMandatory: false
};

export const REPORTING_PERIOD_NOTIFICATION = 'REPORTING_PERIOD_NOTIFICATION';

export function reducer(state: IReportingPeriodStatus = initialState, action: UnsafeAction) {
  switch (action.type) {
    case REPORTING_PERIOD_NOTIFICATION:
      if(action.payload == null) {
        state = { ...initialState };
        state.portalMessage = null;
      } else {
        state = { ...action.payload };
      }
      
      return state;
    default:
      return state;
  }
}

export class UnsafeAction implements Action {
  payload?: any;
  type: string;
}
