import { Injectable, TemplateRef, Output } from "@angular/core";
import { ISearchRequest } from "app/modules/organisation/services/search-request";
import { recordEntity } from "app/modules/organisation/models";
import { SortOrder } from "app/modules/organisation/services/sort-criteria";
import { EventEmitter } from "events";
import {
  ReportingService,
  ISearchDownloadRequest,
} from "app/modules/organisation/services/reporting.service";
import { Observable, Subject, BehaviorSubject } from "rxjs";
import * as FileSaver from "file-saver";

export const lengthError = "Please enter at least three characters to search.";

@Injectable({ providedIn: "root" })
export class SearchService {
  constructor(private reportingService: ReportingService) {}
  public isSearching = new BehaviorSubject<boolean>(false);
  public searchResult = new BehaviorSubject<any>(undefined);
  public error = new BehaviorSubject<string>(undefined);

  public searchRequest: ISearchRequest = {
    entity: recordEntity["all"],
    query: "",
    pageNumber: 1,
    pagingCookie: null,
    count: 10,
    sortCriteria: {
      fieldName: "",
      sortOrder: SortOrder.ascending,
    },
    isDeregistered: false,
  };

  public get isLoading() {
    return this.isSearching.getValue();
  }

  public get hasResult() {
    return !!this.searchResult.getValue();
  }

  public download(req) {
    this.isSearching.next(true);
    this.reportingService.initSearchDownload(req).subscribe((blob) => {
      try {
        FileSaver.saveAs(blob, "Downloadable Lobbyist Register.xlsx");
        this.isSearching.next(false);
      } catch (e) {
        console.log(`DEBUGLOG: error ${JSON.stringify(e)}`);
        this.isSearching.next(false);
      }
    });
  }

  public doSearch() {
    this.searchResult.next(undefined);

    if (
      this.searchRequest.entity === recordEntity["all"] &&
      this.searchRequest.query.trim().length < 3
    ) {
      // if query is null then they're probably just clicking
      // tabs, if length >0 && <3 then it's an error
      if (this.searchRequest.query.trim().length === 0) {
        this.error.next("");
      } else {
        this.error.next(lengthError);
      }
    } else {
      this.isSearching.next(true);

      this.searchRequest.pageNumber = 1;
      this.searchRequest.pagingCookie = null;

      this.reportingService.search(this.searchRequest).subscribe((result) => {
        this.searchResult.next(result);
        this.error.next(undefined);
        this.isSearching.next(false);
      });
    }
  }

  public updatePage() {
    this.isSearching.next(true);
    // const cur = { ...this.searchResult.getValue() };
    // cur.pageNumber = this.searchRequest.pageNumber;

    // this.searchResult.next(cur);

    this.reportingService.search(this.searchRequest).subscribe((result) => {
      this.searchResult.next(result);
      this.isSearching.next(false);
    });
  }

  ngOnInit() {}

  public reset() {
    this.searchRequest = {
      entity: recordEntity["all"],
      query: "",
      pageNumber: 1,
      pagingCookie: null,
      count: 10,
      sortCriteria: {
        fieldName: "",
        sortOrder: SortOrder.ascending,
      },
      isDeregistered: false,
    };

    this.error.next(undefined);
    this.searchResult.next(undefined);
  }
}
