import {
  Component,
  OnInit,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { ResultComponent } from "./../result/result.component";
import { PaginatorComponent } from "./../paginator/paginator.component";
import { ISearchRequest } from "./../../../organisation/services/search-request";
import { SortOrder } from "./../../../organisation/services/sort-criteria";
import { recordEntity } from "app/modules/organisation/models";

@Component({
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})

// this guy was initially used as the main register search
// but it was pulled apart for PBI and left in place
// becuase breach still uses it.

// the main register view uses ./search-register
export class SearchComponent implements OnInit {
  public searchResult: any;
  public isSearching: boolean = true;
  public isBreachSearch: boolean = false;
  @Input() isMainSearch: boolean = false;

  public viewOptions = [
    {
      name: "10",
      value: 10,
    },
    {
      name: "50",
      value: 50,
    },
    {
      name: "100",
      value: 100,
    },
  ];

  public selectedOption: number = 10;

  @ViewChild(ResultComponent, { static: false })
  private resultComponent: ResultComponent;

  @ViewChild(PaginatorComponent, { static: false })
  private paginatorComponent: PaginatorComponent;

  @Input()
  set entity(entity: string) {
    this.searchRequest.entity = recordEntity[entity];
  }

  @Input()
  set deregistered(status: boolean) {
    this.searchRequest.isDeregistered = status;
  }

  @Input()
  public searchRequest: ISearchRequest = {
    entity: recordEntity["none"],
    query: "",
    pageNumber: 1,
    pagingCookie: null,
    count: 10,
    sortCriteria: {
      fieldName: "",
      sortOrder: SortOrder.ascending,
    },
    isDeregistered: false,
  };

  @Input()
  set forBreach(isBreach: boolean) {
    this.isBreachSearch = isBreach;
  }

  @Output() selectedEntity = new EventEmitter<any>();

  filterChanged(searchRequest) {
    this.resultComponent.search(this.searchRequest);
  }

  resultChanged(searchResult) {
    this.searchResult = searchResult;
    this.paginatorComponent.resultChanged(this.searchResult);
    setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }));
  }

  updateResultsVal(newVal: number) {
    this.selectedOption = newVal;
    this.searchRequest.count = newVal;
    this.resultComponent.search(this.searchRequest);
    this.paginatorComponent.resultChanged(this.searchResult);
    setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }));
  }

  public onSearching(isSearching) {
    this.isSearching = isSearching;
  }

  constructor() {}

  ngOnInit() {}

  onSelectedEntity(entity: any) {
    this.selectedEntity.emit(entity);
  }
}
