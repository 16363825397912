import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MyOrganisationService } from "app/modules/organisation/services/my-organisation.service";
import { IMyLobbyist } from './../../../models';
import { RegistrationFormService, IRegistrationFormData } from 'app/modules/organisation/services/registration-form.service';
import { Observable } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import * as fromDashboardNotifications from 'app/reducers/dashboard-notifications';
import { Store } from '@ngrx/store';
import * as fromRoot from './../../../../../reducers';
import { Router } from '@angular/router';

@Component({
  selector: 'app-activate-organisation',
  templateUrl: './activate-organisation.component.html',
  styleUrls: ['./activate-organisation.component.scss']
})
export class ActivateOrganisationComponent implements OnInit {

  @Input() activeModal: NgbActiveModal;
  @Input() currentOrganisation: IRegistrationFormData;
  @Output() activateSelected = new EventEmitter<IRegistrationFormData>();

  public isActivating: boolean;

  constructor(private registrationFormService: RegistrationFormService,
    private store: Store<fromRoot.IState>,
    private router: Router) {
  }

  ngOnInit() {
  }

  activateOrganisation() {
    this.isActivating = true;

    if (this.currentOrganisation == null) {
      this.registrationFormService.formData$.pipe(
        take(1),
        tap(orgData => this.registrationFormService.activateMyOrganisation(orgData).subscribe(orgData => {
          this.notifySubscribers(orgData);
        }))).subscribe();
    } else {
      this.registrationFormService.activateMyOrganisation(this.currentOrganisation).subscribe(orgData => {
        this.notifySubscribers(orgData);
      });
    }

  }

  notifySubscribers(orgData: IRegistrationFormData) {
    this.store.dispatch({
      type: fromDashboardNotifications.SUCCESS_NOTIFICATION,
      payload: { message: "Your organisation has successfully been reactivated. To appear on the public register your organisations details need to be reviewed, updated where necessary, and submitted." }
    });

    this.activateSelected.next(orgData);
    this.activeModal.close();
    this.isActivating = false;
    this.router.navigateByUrl('/user/dashboard');
  }
}
