import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from "./../../services/content.service";
import { IContent, IAttachment, IDocument } from './../../content.models'

import * as FileSaver from 'file-saver';

@Component({
    selector: 'app-attachment',
    templateUrl: './attachment.component.html',
    styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnInit {

    @Input() attachment: IAttachment;

    constructor(private route: ActivatedRoute, private contentService: ContentService) { }

    ngOnInit() {
        if (!this.attachment) {
            this.attachment = this.route.snapshot.data['attachment'];
        }
    }

    download(event, document: IDocument) {
        event.preventDefault();

        this.contentService
            .initDocumentDownload(document.id, document.type)
            .subscribe(blob => FileSaver.saveAs(blob, document.id + "." + document.type));
    }
}
