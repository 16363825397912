// MSAL Authentication
import { LogLevel } from "msal";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export const appConfig = {
  //@ts-ignore
  clientId: window.__RUNTIME_CONFIG__.ANGULAR_APP_ADFS_APPLICATION_ID.valueOf(),
  //@ts-ignore
  redirectUri: window.__RUNTIME_CONFIG__.ANGULAR_APP_ADFS_REDIRECT_URL.valueOf(),
  //@ts-ignore
  authority: window.__RUNTIME_CONFIG__.ANGULAR_APP_ADFS_AUTHORITY.valueOf(),
  //@ts-ignore
  knownAuthorities: [window.__RUNTIME_CONFIG__.ANGULAR_APP_ADFS_AUTHORITY.valueOf()],
  //@ts-ignore
  earsUrl: window.__RUNTIME_CONFIG__.ANGULAR_APP_EARS_URL.valueOf(),
  //@ts-ignore
  postLogoutRedirectUri: window.__RUNTIME_CONFIG__.ANGULAR_APP_ADFS_POST_LOGOUT_REDIRECT_URL.valueOf(),
  //@ts-ignore
  navigateToLoginRequestUrl: false
}

// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
      ...appConfig
  },
  cache: {
      storeAuthStateInCookie: isIE || isEdge || isFirefox
  },
  system: {
      loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
              if (containsPii) {
                  return;
              }
              switch (level) {
                  case LogLevel.Error:
                      console.error(message);
                      return;
                  case LogLevel.Info:
                      return;
                  case LogLevel.Verbose:
                      return;
                  case LogLevel.Warning:
                      console.warn(message);
                      return;
                  default:
                      return;
              }
          }
      }
  }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['openid', 'profile'],
};
