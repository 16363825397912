import { NgModule } from '@angular/core';
import { BlankSubstitutePipe } from './blank-substitute-pipe';
import { YesNoBlankPipe } from './yes-no-blank-pipe';
import { NgbDatePipe } from './ngb-date-pipe';

@NgModule({
  imports: [],
  declarations: [
    BlankSubstitutePipe,
    YesNoBlankPipe,
    NgbDatePipe,

  ],
  exports: [
    BlankSubstitutePipe,
    YesNoBlankPipe,
    NgbDatePipe,
  ],
})

export class PipeModule {

  static forRoot() {
    return {
      ngModule: PipeModule,
      providers: [],
    };
  }
} 
