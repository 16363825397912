import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { ReportingService } from 'app/modules/organisation/services/reporting.service';


@Component({
  selector: 'app-step-select-organisation',
  templateUrl: './step-select-organisation.component.html',
  styleUrls: ['./step-select-organisation.component.scss']
})
export class StepSelectOrganisationComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() activeSubstep: string;
  @Input() onSubstepChange: Subject<string>;

  public lobbyists: any;

  constructor(private reportingService: ReportingService,
    private fb: FormBuilder) { }

  ngOnInit() {
    this.onSubstepChange.subscribe(newSubstep => {
      this.activeSubstep = newSubstep;
    });
  }

  onSelectedOrganisation(organisation: any) {
    this.form.get('organisation.organisationId').setValue(organisation.id);
    this.form.get('organisation.organisationName').setValue(organisation.displayName);

    var lobbyists = <FormArray>this.form.get('lobbyists.lobbyists');
    lobbyists.clear();

    this.reportingService.getLobbyistsForOrganisation(organisation.id).subscribe(orgLobbyists => {
      this.lobbyists = orgLobbyists;

      orgLobbyists.resultSet.forEach(lobbyist => {
        var fbLobbyist = this.fb.group({
          "id": lobbyist.id,
          "displayName": lobbyist.displayName,
          "position": lobbyist.position,
          "isFormerRepresentative": lobbyist.isFormerRepresentative,
          "previousPositionOther": lobbyist.previousPositionOther,
          "cessationDate": lobbyist.cessationDate,
          "selected": false
        });

        lobbyists.push(fbLobbyist);
      });

    });
  }

  toggleSelectAll(changed) {
    (<FormArray>this.form.get('lobbyists.lobbyists')).controls.forEach(lobbyist => lobbyist.get('selected').setValue(changed.target.checked));
  }
}
