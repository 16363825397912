import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ErrorHandlerService } from './services/error-handler.service';

import { StoreModule } from '@ngrx/store';
import { RecaptchaModule } from 'ng-recaptcha';

import { appRoutes } from './app.module.routing';

import { AppComponent } from './app.component';

import { environment } from '../environments/environment';

//App Modules
import { MockHttpModule } from './modules/mock-http/mock-http.module';

import { ConfigurationModule } from './modules/configuration/configuration.module';
import { IdentityModule } from './modules/identity/identity.module';
import { LayoutModule } from './modules/layout/layout.module';
import { ContentModule } from './modules/content/content.module';
import { ControlsModule } from './modules/controls/controls.module';
import { HistoryModule } from './modules/history/history.module';

import { OrganisationModule } from './modules/organisation/organisation.module';

import { reducer } from './reducers';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    appRoutes,
    BrowserModule,
    FormsModule,
    HistoryModule,
    RecaptchaModule.forRoot(),
    MockHttpModule,
    ConfigurationModule,
    IdentityModule,
    LayoutModule,
    ContentModule,
    ControlsModule,
    OrganisationModule,
    StoreModule.forRoot(reducer),
    HttpClientModule,
    NgbModule
  ],
  providers: [
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useValue: MsalInterceptor,
    //   multi: true
    // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
