import { ActionReducer, Action } from "@ngrx/store";
import { IUserPrincipal } from "./../identity.models";

const initialState: IUserPrincipal = {
  isAuthenticated: false,
  email: null,
  displayName: null,
  accessToken: null,
  hasSeenTutorial: null,
  subjectId: null,
  earsUpn: null,
};

export const SIGNOUT = "SIGNOUT";
export const SIGNIN = "SIGNIN";
export const SEENTUTORIAL = "SEENTUTORIAL";

export function reducer(
  state: IUserPrincipal = initialState,
  action: UnsafeAction
) {
  switch (action.type) {
    case SIGNOUT:
      state = initialState;
      return state;
    case SIGNIN:
      state = <IUserPrincipal>action.payload;
      return state;
    case SEENTUTORIAL:
      state = <IUserPrincipal>action.payload;
      return state;
    default:
      return state;
  }
}

export class UnsafeAction implements Action {
  payload?: any;
  type: string;
}
