import { Component, OnInit } from '@angular/core';
import { ContentService } from "./../../services/content.service";
import { INewsItem } from './../../content.models'

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

    public newsFeed: INewsItem[];

    constructor(private contentService: ContentService) { }

    ngOnInit() {
        this.contentService.getNews().subscribe(newsFeed => this.newsFeed = newsFeed);
    }

}
