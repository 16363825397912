import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';

import { IPageMetadata, IBreadcrumb } from './../../layout.models';

@Component({
    selector: 'app-content-canvas',
    templateUrl: './content-canvas.component.html',
    styleUrls: ['./content-canvas.component.css']
})
export class ContentCanvasComponent implements OnInit {
    private showPageHeader: boolean = true;
    public pageMetadata: IPageMetadata = {
        title: null,
        subTitle: null,
        breadcrumbs: []
    };

    @Output() onPageMetadataChanged = new EventEmitter<IPageMetadata>();

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title) { }

    ngOnInit() {
        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .map(() => this.activatedRoute)
            .map(route => {
                this.pageMetadata.breadcrumbs = this.getBreadcrumbs(route.root);
                while (route.firstChild) route = route.firstChild;
                return route;
            })
            .filter(route => route.outlet === PRIMARY_OUTLET)
            .mergeMap(route => route.data)
            .subscribe((data) => {
                this.titleService.setTitle(data['title'] + ' - Australian Government Lobbyist Register');
                if (data['showPageHeader'] == null) {
                    this.showPageHeader = true;
                } else {
                    this.showPageHeader = data['showPageHeader'];
                }

                this.pageMetadata.title = data['title'];
                this.pageMetadata.subTitle = data['subTitle'];

                this.onPageMetadataChanged.emit(this.pageMetadata);
            });
    }

    /**
   * Returns array of IBreadcrumb objects that represent the breadcrumb
   *
   * @class DetailComponent
   * @method getBreadcrumbs
   * @param {ActivateRoute} route
   * @param {string} url
   * @param {IBreadcrumb[]} breadcrumbs
   */
    private getBreadcrumbs(route: ActivatedRoute, url: string = "", breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
        const ROUTE_DATA_BREADCRUMB: string = "breadcrumb";
        const IS_NONCLICKABLE: string = "isNonClickable";

        //get the child routes
        let children: ActivatedRoute[] = route.children;

        //return if there are no more children
        if (children.length === 0) {
            return breadcrumbs;
        }

        //iterate over each children
        for (let child of children) {
            //verify primary route
            if (child.outlet !== PRIMARY_OUTLET) {
                continue;
            }

            //verify the custom data property "breadcrumb" is specified on the route
            if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
                return this.getBreadcrumbs(child, url, breadcrumbs);
            }

            //get the route's URL segment
            let routeURL: string = child.snapshot.url.map(segment => segment.path).join("/");

            //append route URL to URL
            url += `/${routeURL}`;

            let breadcrumb: IBreadcrumb = {
                label: child.snapshot.data[ROUTE_DATA_BREADCRUMB].label,
                params: child.snapshot.params,
                url: url,
                isClickable: child.snapshot.data[ROUTE_DATA_BREADCRUMB].isClickable
            };
           
            breadcrumbs.push(breadcrumb);

            //recursive
            return this.getBreadcrumbs(child, url, breadcrumbs);
        }
    }
}
