import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'yesNoBlank' })
export class YesNoBlankPipe implements PipeTransform {
  transform(value): string {
    if (value === true) {
      return 'Yes';
    }
    if (value === false) {
      return 'No';
    }
    return '-';
  }
}
