import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ILookup } from 'app/modules/organisation/services/registration-form.service';

@Component({
  selector: 'app-step-reporter-details',
  templateUrl: './step-reporter-details.component.html',
  styleUrls: ['./step-reporter-details.component.scss']
})
export class StepReporterDetailsComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() titles: ILookup[] = [];

  constructor() { }

  ngOnInit() {
  }

}
