import { ActionReducer, Action } from '@ngrx/store';

const initialState: IDashboardNotifications = {
  message: null
};

export const SUCCESS_NOTIFICATION = 'SUCCESS_NOTIFICATION';

export function reducer(state: IDashboardNotifications = initialState, action: UnsafeAction) {
  switch (action.type) {
    case SUCCESS_NOTIFICATION:
      state.message = action.payload.message;
      return state;
    default:
      return state;
  }
}

export interface IDashboardNotifications {
  message: string;
}

export class UnsafeAction implements Action {
  payload?: any;
  type: string;
}
