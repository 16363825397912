import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";

import {
  ReportingService,
  ISearchResponse,
} from "./../../../organisation/services/reporting.service";
import { ISearchRequest } from "./../../../organisation/services/search-request";
import { SortOrder } from "./../../../organisation/services/sort-criteria";
import {
  IOrganisationSummary,
  HistoryService,
} from "app/modules/history/services/history.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-result",
  templateUrl: "./result.component.html",
  styleUrls: ["./result.component.scss"],
})
export class ResultComponent implements OnInit {
  @ViewChild("modalLoading", { read: false, static: false })
  modalLoading: ElementRef;

  public searchResult: any;
  public sortOrder = SortOrder;

  search(searchRequest: ISearchRequest) {
    this.searchRequest = searchRequest;
    this.onSearching.emit(true);

    this.reportingService.search(this.searchRequest).subscribe((result) => {
      this.searchResult = result;

      this.onResultChanged.emit(this.searchResult);
      this.onSearching.emit(false);
    });
  }

  @Output() public onResultChanged = new EventEmitter<any>();
  @Output() public onSearching = new EventEmitter<boolean>();
  public selectedEntityId: string = null;
  @Input() public searchRequest: ISearchRequest;
  @Input() public isBreachSearch: boolean = false;
  @Output() selectedEntity = new EventEmitter<any>();

  constructor(
    private reportingService: ReportingService,
    private historyService: HistoryService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    if (this.searchRequest) {
      this.search(this.searchRequest);
    }
  }

  isAsc(column: string): boolean {
    return (
      this.searchRequest.sortCriteria.sortOrder == this.sortOrder.ascending &&
      this.searchRequest.sortCriteria.fieldName == column
    );
  }

  toggleSort(column: string) {
    this.searchRequest.sortCriteria.fieldName = column;
    var currentOrder = this.searchRequest.sortCriteria.sortOrder;
    this.searchRequest.sortCriteria.sortOrder =
      currentOrder == this.sortOrder.ascending
        ? this.sortOrder.descending
        : this.sortOrder.ascending;

    this.search(this.searchRequest);
  }

  select(entity: any) {
    this.selectedEntityId = entity.id;
    this.selectedEntity.emit(entity);
  }

  throwDeregisteredHistoryModal(
    event: Event,
    organisation: IOrganisationSummary
  ) {
    event.preventDefault();
    this.modalService.open(this.modalLoading, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
    this.historyService.getOrganisationHistory(organisation.id);
  }
}
