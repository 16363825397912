import { Inject, Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { MockConnection } from "@angular/http/testing";

import { Observable } from "rxjs";

import { MockBackendService } from './mock-backend.service';
import { MockDataService } from './mock-data.service';
import { IRequestRouter } from './request-router';

@Injectable()
export class AbnControllerBackendService implements IRequestRouter {

    constructor(private mockDataService: MockDataService) { }

    public routeRequest(connection: MockConnection, realHttp: Http): Observable<void> {
        let url = connection.request.url;

        if (!!url) {
            let organisationApi = "http://localhost:9000/";
            if (url.startsWith(organisationApi)) {
                url = "/" + url.substring(organisationApi.length);
                if (url.startsWith("/lookup/abn")) {
                    return this.mockDataService.getMockData(connection, url, realHttp);
                }
            }
        }

        throw new Error("Mock Error: Mock not implemented for - " + url);
    }

}
