import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-step-select-entity-type',
  templateUrl: './step-select-entity-type.component.html',
  styleUrls: ['./step-select-entity-type.component.scss']
})
export class StepSelectEntityTypeComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() isLobbyistChange: (isLobbyistChange: boolean) => void;

  constructor() { }

  ngOnInit() {
  }

}
