import * as fromRoot from "./../../../../reducers";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  TemplateRef,
  AfterViewInit,
} from "@angular/core";
import { JoyrideService } from "ngx-joyride";

import { Store } from "@ngrx/store";
import { Observable, combineLatest, timer, Subscription } from "rxjs";
import { map, takeWhile, take, tap } from "rxjs/operators";
import { IMyProfileStatus, IMyLobbyist } from "./../../models";
import { IUserPrincipal } from "./../../../identity/identity.models";
import { ContentService } from "app/modules/content/services/content.service";
import {
  IReportingPeriodStatus,
  RegistrationFormService,
} from "app/modules/organisation/services/registration-form.service";
import * as FileSaver from "file-saver";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { IConfiguration } from "app/modules/configuration/reducers";
import { MyOrganisationService } from "app/modules/organisation/services/my-organisation.service";
import { DeregisterOrganisationComponent } from "app/modules/organisation/components/registration-form/deregister-organisation/deregister-organisation.component";
import { ActivateOrganisationComponent } from "app/modules/organisation/components/registration-form/activate-organisation/activate-organisation.component";
import {
  TutorialDashExistingSteps,
  TutorialDashIntroSteps,
} from "../../helpers/tutorial-steps";
import { OfficerService } from "app/modules/controls/services/officer.service";
import { CookieService } from "app/services/cookie.service";
import { Router } from "@angular/router";
import { AuthService } from "app/modules/identity/services/auth.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  public profile: IMyProfileStatus;
  public dashboardSuccessMessage$: Observable<string>;
  public isLoading: boolean;
  private userPrincipal: IUserPrincipal;
  private reportingPeriodStatus: IReportingPeriodStatus;
  private config: IConfiguration;
  private lobbyists: IMyLobbyist[];

  private logoutTimer: Subscription;
  private hasLeftPage: boolean = false;
  public hasSeenTutorial: boolean;

  @ViewChild("modalLogout", { read: false, static: false })
  modalLogout: ElementRef;
  @ViewChild("modalDeregisterOrg", { read: false, static: false })
  modalDeregister: TemplateRef<DeregisterOrganisationComponent>;
  @ViewChild("modalActivateOrg", { read: false, static: false })
  modalActivate: TemplateRef<ActivateOrganisationComponent>;
  @ViewChild("modalTutorial", { read: false, static: false })
  modalTutorial: ElementRef;

  get isInReportingPeriod(): boolean {
    const currentDate = new Date();

    return (
      this.reportingPeriodStatus != null &&
      this.reportingPeriodStatus.id != null &&
      this.reportingPeriodStatus.status == "NotCompliant" &&
      currentDate.getTime() >=
        Date.parse(this.reportingPeriodStatus.startDateTime) &&
      currentDate.getTime() <=
        Date.parse(this.reportingPeriodStatus.endDateTime)
    );
  }

  get isDeregistered(): boolean {
    return (
      this.profile.status == "Deregistered" ||
      this.profile.status == "Removed" ||
      this.profile.status == "Submittedforremoval" ||
      this.profile.status == "PendingDeregistration"
    );
  }

  constructor(
    private store: Store<fromRoot.IState>,
    private router: Router,
    private contentService: ContentService,
    private authService: AuthService,
    private registrationFormService: RegistrationFormService,
    private officerService: OfficerService,
    private modalService: NgbModal,
    private joyrideService: JoyrideService,
    private cookieService: CookieService
  ) {
    this.isLoading = true;
    this.store
      .select(fromRoot.getConfig)
      .subscribe((config) => (this.config = config));

    this.store.select(fromRoot.getUserPrincipal).subscribe((principal) => {
      if (principal.hasSeenTutorial === null) {
        // fetch principal
        console.log(`DEBUGLOG: principal is in default state`);
      }

      this.userPrincipal = principal;
      this.hasSeenTutorial = principal.hasSeenTutorial;
    });

    this.loadDashboardMessage();

    this.registrationFormService.deregistrationRequested$.subscribe(() => {
      this.modalService.open(this.modalDeregister, {
        centered: true,
        backdrop: "static",
        keyboard: true,
      });
    });

    this.registrationFormService.activationRequested$.subscribe(() => {
      this.modalService.open(this.modalActivate, {
        centered: true,
        backdrop: "static",
        keyboard: true,
      });
    });
  }

  ngOnInit() {
    combineLatest(
      this.store.select(fromRoot.getUserPrincipal),
      this.store.select(fromRoot.getMyProfileStatus),
      this.registrationFormService.reportingPeriod$
    )
      .pipe(
        map(async ([principal, profile, reportingPeriodStatus]) => {
          this.userPrincipal = principal;
          this.hasSeenTutorial = principal.hasSeenTutorial;
          this.profile = profile;

          this.isLoading = !profile.isInitialised;
          this.reportingPeriodStatus = reportingPeriodStatus;

          let cook = window.localStorage.getItem("HasSeenTutorial");

          if (cook) {
            const name = await this.hashName(this.userPrincipal.displayName);

            if (JSON.parse(cook).id === name) {
              this.hasSeenTutorial = true;
            }
          }

          if (this.hasSeenTutorial === false) {
            this.modalService.open(this.modalTutorial, {
              centered: true,
              backdrop: "static",
              keyboard: false,
            });
          }
        })
      )
      .subscribe();

    this.hasLeftPage = false;
    this.logoutTimer = timer(30 * 60 * 1000)
      .pipe(takeWhile(() => !this.hasLeftPage))
      .subscribe((x) => {
        this.modalService.open(this.modalLogout, {
          centered: true,
          backdrop: "static",
          keyboard: false,
        });
      });
  }

  ngOnDestroy() {
    this.hasLeftPage = true;
  }

  loadDashboardMessage() {
    this.dashboardSuccessMessage$ = this.store
      .select(fromRoot.getDashboardNotifications)
      .map((x) => x.message);
  }

  download(event) {
    event.preventDefault();

    this.contentService
      .initDocumentDownload("Statutory Declaration - Lobbyist Register", "docx")
      .subscribe((blob) =>
        FileSaver.saveAs(blob, "Statutory Declaration - Lobbyist Register.docx")
      );
  }

  beginNow() {
    this.registrationFormService.beginOrgRegistration();
    this.router.navigate(["/user/form"]);
  }

  reloadDashboardMessage(orgData: any) {
    this.isLoading = true;

    this.loadDashboardMessage();

    this.store.select(fromRoot.getMyProfileStatus).subscribe((profile) => {
      this.profile = profile;
      this.isLoading = false;
    });
  }

  showHelp(event?: Event) {
    if (event) event.preventDefault();

    // the help link is displayed before content loads so just block that
    // don't need alerts or anything becuase it happens pretty quick.
    if (this.isLoading) {
      return;
    }

    // this wild conditional existed in the template when I got here I'm just
    // misappropriating it
    const steps = !(
      this.isDeregistered ||
      this.profile.isSuspended ||
      (this.profile.status != "SubmittedforReview" &&
        this.profile.status != "PendingApproval" &&
        !this.profile.isOrganisationReviewed)
    )
      ? TutorialDashExistingSteps
      : TutorialDashIntroSteps;

    const options = {
      steps,
      stepDefaultPosition: "top",
      themeColor: "#50748a",
      showPrevButton: true,
      logsEnabled: false,
    };

    this.joyrideService.startTour(options).subscribe(
      (step) => {
        window.scrollTo(0, 0);
        console.log("Next:", step);
      },
      (e) => {
        console.log("Error", e);
      },
      () => {
        console.log("Tour finished");
      }
    );
  }

  public async showTutorial(event: Event, showTutorial: boolean) {
    event.preventDefault();

    if (showTutorial) {
      this.modalService.dismissAll();
      this.showHelp();
    } else {
      this.modalService.dismissAll();
      this.hasSeenTutorial = true;
    }
    this.officerService
      .saveTutorialStatus(this.userPrincipal.subjectId)
      .subscribe(async (res) => {
        let payload = { ...JSON.parse(JSON.stringify(this.userPrincipal)) };

        payload.hasSeenTutorial = true;

        this.store.dispatch({
          type: "SEENTUTORIAL",
          payload,
        });

        const name = await this.hashName(this.userPrincipal.displayName);

        const values = JSON.stringify({
          HasSeenTutorial: true,
          id: name,
        });

        window.localStorage.setItem("HasSeenTutorial", values);
      });
  }

  public async hashName(displayName: string) {
    // some versions of ie don't support crypto methods
    try {
      const buffer = await crypto.subtle.digest(
        "SHA-256",
        new TextEncoder().encode(displayName)
      );
      return btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)));
    } catch (e) {
      return btoa(displayName);
    }
  }

  public logoutUser(){
    this.authService.logout();
  }
}
