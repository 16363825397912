import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Observable } from "rxjs/Observable";
import {
  Router,
  NavigationEnd,
  ActivatedRoute,
  PRIMARY_OUTLET,
} from "@angular/router";
import { IMyProfileStatus } from "./../../../organisation/models";

import * as fromRoot from "./../../../../reducers";
import { Store } from "@ngrx/store";

import { AuthService } from "./../../../identity/services/auth.service";
import { MyOrganisationService } from "./../../../organisation/services/my-organisation.service";
import { IUserPrincipal } from "./../../../identity/identity.models";
import { AuthGuard } from "app/modules/identity/gaurds/auth.gaurd";
import { RegistrationFormService } from "app/modules/organisation/services/registration-form.service";

@Component({
  selector: "app-auth-bar",
  templateUrl: "./auth-bar.component.html",
  styleUrls: ["./auth-bar.component.scss"],
})
export class AuthBarComponent {
  userPrincipal: IUserPrincipal;
  myProfileStatus: IMyProfileStatus;
  config: any;
  public returnUrl: string;

  constructor(
    private store: Store<fromRoot.IState>,
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private myOrgService: MyOrganisationService,
    private registrationService: RegistrationFormService
  ) {
    this.store
      .select(fromRoot.getConfig)
      .subscribe((config) => (this.config = config));
    this.store
      .select(fromRoot.getMyProfileStatus)
      .subscribe((myProfileStatus) => (this.myProfileStatus = myProfileStatus));
    this.store.select(fromRoot.getUserPrincipal).subscribe((principal) => {
      if (
        !this.userPrincipal ||
        this.userPrincipal.isAuthenticated !== principal.isAuthenticated
      ) {
        this.userPrincipal = principal;
        if (principal.isAuthenticated) {
          this.myOrgService.getMyProfileStatus().then((myProfileStatus) => {
            this.store.dispatch({
              type: "MYPROFILESTATUSCHANGED",
              payload: myProfileStatus,
            });
          });
        }
      }
    });

    this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .map(() => this.activatedRoute)
      .map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      })
      .filter((route) => route.outlet === PRIMARY_OUTLET)
      .subscribe((route) => {
        const currentRoute = route.snapshot;
        this.returnUrl =
          currentRoute.parent.url.length > 0
            ? currentRoute.parent.url[0].path + "/" + currentRoute.url[0].path
            : currentRoute.url[0].path;
      });
  }

  /*
    public onSignoutClick(event) {
        event.preventDefault();
        event.stopPropagation();
        this.authService.signout().then(() => {
            window.location.href = `${this.config.routes.account.signoutUrl}?returnUrl=/${this.returnUrl}`;
        });
    }
    */

  public deregister() {
    this.registrationService.requestDeregistration();
  }

  public activate() {
    this.registrationService.requestActivation();
  }


  get isOrganisationReviewed(): boolean {
    return this.myProfileStatus.isOrganisationReviewed;
  }

  get isDeregistered(): boolean {
    return (
      this.myProfileStatus.status == "Deregistered" ||
      this.myProfileStatus.status == "Removed"
    );
  }

  // MSAL Authentication
  public signout() {
    this.authService.logout();
  }

  public updatePassword(){
    this.authService.updatePassword();
  }
}
