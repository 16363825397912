import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './modules/identity/services/auth.service';
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  private loading = true;

  constructor(public router: Router, authService: AuthService) {

    authService.handlePageLoadEvent().then(event => {
      this.loading = false
    });

    this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag('config',
            'G-0P350G0ZT3', // Old = 'UA-41353878-44'
            {
              'page_path': event.urlAfterRedirects
            }
          );
        }
      }
    );
  }
}
