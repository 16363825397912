import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Injectable,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { FormGroup, FormArray, AbstractControl } from "@angular/forms";
import { IAttachment } from "app/modules/content/content.models";
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { Subject, combineLatest } from "rxjs";
import { GoogleAnalyticsService } from "../../../../../services/google-analytics.service";
import {
  IFormSubstep,
  RegistrationSubsteps,
} from "app/modules/organisation/models";
import {
  IPosition,
  RegistrationFormService,
  IReportingPeriodStatus,
} from "app/modules/organisation/services/registration-form.service";
import { UtilityFunctions } from "app/modules/organisation/helpers/utilFuncs";
import moment = require("moment");

// TODO: move to global scope
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = "/";

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date
      ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year
      : "";
  }
}

@Component({
  selector: "app-step-lobbyists",
  templateUrl: "./step-lobbyists.component.html",
  styleUrls: ["./step-lobbyists.component.scss"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class StepLobbyistsComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() lobbyistsAdd: VoidFunction;
  @Input() lobbyistsRemove: (lobbyistId: string) => void;
  @Input() onSaved: Subject<void>;
  @Input() isSoleTrader: boolean = false;
  @Input() positions: IPosition[] = [];

  @Input() activeSubstep: RegistrationSubsteps;
  @Input() onSubstepChange: Subject<RegistrationSubsteps>;

  @Output() lobbyistStepChanged = new EventEmitter<IFormSubstep>();

  @Input() sortList: (
    list: AbstractControl[],
    column: string,
    isAsc: boolean
  ) => void;

  public formsAttachment: IAttachment;

  public lobbyistTotal: number = -1;
  public lobbyistId: string = null;
  private lobbyistDescription: string = null;
  private unsubscribe$: Subject<void> = new Subject();
  private previousPositionLevels: IPosition[];
  private reportingPeriodStatus: IReportingPeriodStatus;

  private FOR_DEREGISTRATION = 981660001; // oh no, another hard-coded constant

  get lobbyistForm(): AbstractControl {
    var lobbyistCollection = <FormArray>this.form.get("lobbyists");

    if (this.lobbyistId === null) {
      return lobbyistCollection;
    } else {
      var lobbyist = lobbyistCollection.controls.find(
        (l) => l.get("id").value === this.lobbyistId
      );

      if (!lobbyist) {
        // id has changed because new record was saved
        // try find by description
        lobbyist = lobbyistCollection.controls.find(
          (l) => l.get("description").value === this.lobbyistDescription
        );

        // otherwise, new lobbyist should be at end of list
        if (lobbyist === null) {
          lobbyist = lobbyistCollection.controls[lobbyistCollection.length - 1];
        }

        this.lobbyistId = lobbyist.get("id").value;
      }

      this.lobbyistDescription = lobbyist.get("description").value;
      lobbyist
        .get("description")
        .valueChanges.takeUntil(this.unsubscribe$)
        .subscribe(
          () => (this.lobbyistDescription = lobbyist.get("description").value)
        );

      switch (this.activeSubstep) {
        case RegistrationSubsteps["Lobbyists list"]:
        case RegistrationSubsteps["Lobbyists overview"]:
          return lobbyist;
        case RegistrationSubsteps["Add lobbyist/s"]:
          return lobbyist.get("lobbyistDetails");
        case RegistrationSubsteps["Former details"]:
          return lobbyist.get("formerDetails");
      }
    }
  }

  public requiresLevel(lobbyist): boolean {
    const requiredOptions = [
      "EmployedorengagedbyaMinisteroraParliamentarySecretaryundertheMembersofParliament_StaffAct1984",
      "EmployedunderthePublicServiceAct1999",
      "ContractorconsultantforanagencywhosestaffareemployedunderthePublicServiceAct1999",
      "MemberoftheAustralianDefenceForce",
    ];

    const selectedPosition = lobbyist.get("formerDetails.previousPosition")
      .value;

    return requiredOptions.some((opt) => opt === selectedPosition);
  }

  public filteredLevels(lobbyist): IPosition[] {
    const prevPosition = lobbyist.get("formerDetails.previousPosition").value;
    let levels = [];
    switch (prevPosition) {
      case "EmployedorengagedbyaMinisteroraParliamentarySecretaryundertheMembersofParliament_StaffAct1984":
        // advisor or above
        // below advisor
        return this.previousPositionLevels.filter(
          (level) =>
            level.value === "Adviserlevelorabove" ||
            level.value === "BelowAdviserlevel"
        );
      case "EmployedunderthePublicServiceAct1999":
      case "ContractorconsultantforanagencywhosestaffareemployedunderthePublicServiceAct1999":
        // ses
        // below ses
        return this.previousPositionLevels.filter(
          (level) =>
            level.value === "SeniorExecutiveService_orequivalent" ||
            level.value === "BelowSeniorExecutiveService_orequivalent"
        );
      case "MemberoftheAustralianDefenceForce":
        //colonel
        // below colonel
        return this.previousPositionLevels.filter(
          (level) =>
            level.value === "Colonellevelorabove_orequivalent" ||
            level.value === "BelowColonellevel_orequivalent"
        );
      default:
        return [];
    }
  }

  get oneOrMoreStatDecsMissing(): boolean {
    var lobbyists = (<FormArray>this.form.get("lobbyists")).controls;
    var missingStatDec = [];
    var result = false;
    for (var i = 0; i < lobbyists.length; i++) {
      var lobbyist = lobbyists[i];
      if (
        lobbyist.get("statusDescription").value != "Submitted for removal" &&
        !this.hasStatDec(lobbyist) &&
        lobbyist.get("submissionReason").value != this.FOR_DEREGISTRATION
      ) {
        missingStatDec.push(lobbyist.get("description").value);
        result = true;
      }
    }

    if (result) {
      this.statDecsMissingMessage =
        "You will not be able to submit your changes without uploading a statutory declaration for each lobbyist. This has not been provided for: " +
        missingStatDec.join(", ");
    }

    return result;
  }

  statDecsMissingMessage: string;

  hasStatDec(lobbyist: AbstractControl): boolean {
    const lobbyistDetails = lobbyist.get("lobbyistDetails");
    return (
      (lobbyistDetails.get("statDecPreviouslyUploaded").value != null &&
        lobbyistDetails.get("statDecPreviouslyUploaded").value === true) ||
      (lobbyistDetails.get("statDecUpload") &&
        lobbyistDetails.get("statDecUpload").value != null &&
        lobbyistDetails.get("statDecUpload").value.length > 0)
    );
  }

  public getStatusDescription(lobbyist: AbstractControl): string {
    if (lobbyist.get("submissionReason").value === this.FOR_DEREGISTRATION) {
      return "Deregistered - Not yet submitted";
    } else {
      return lobbyist.get("statusDescription").value;
    }
  }

  public disableOther(lobbyist: AbstractControl): boolean {
    const prevPosition =
      lobbyist.get("formerDetails.previousPosition") &&
      lobbyist.get("formerDetails.previousPosition").value == "Other";
    const prevApproved =
      lobbyist.get("previouslyApproved") &&
      lobbyist.get("previouslyApproved").value == true;

    const calc = prevPosition && prevApproved;

    return calc;
  }

  public willDeregister(lobbyist: AbstractControl): boolean {
    var desc = this.getStatusDescription(lobbyist);

    return (
      desc === "Deregistered - Not yet submitted" ||
      desc === "Submitted for removal"
    );
  }

  get yesterday(): NgbDateStruct {
    const d = new Date();
    d.setDate(d.getDate() - 1);

    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }

  get jan1970(): NgbDateStruct {
    return { year: 1970, month: 1, day: 1 };
  }

  get statDecMinDate(): NgbDateStruct {
    const minDate = UtilityFunctions.minStatDecDateCalculation();
    // bootstrap doesn't use 0 indexed months
    return {
      year: minDate.year(),
      month: minDate.month() + 1,
      day: minDate.date(),
    };
  }

  get today(): NgbDateStruct {
    const today = moment();
    // bootstrap doesn't use 0 indexed months
    return { year: today.year(), month: today.month() + 1, day: today.date() };
  }

  constructor(
    private googleAnalyticsService: GoogleAnalyticsService,
    private registrationService: RegistrationFormService
  ) {
    registrationService.positionLevels$.subscribe((levels) => {
      this.previousPositionLevels = levels;
    });

    registrationService.reportingPeriod$.subscribe((val) => {
      // console.log(`DEBUGLOG: VAL ${JSON.stringify(val)}`);
      this.reportingPeriodStatus = val;
    });

    this.formsAttachment = {
      text: null,
      documents: [
        {
          type: "docx",
          id: "Statutory Declaration - Lobbyist Register",
          icon: "fa-file-word-o",
          description: "Download statutory declaration template",
        },
      ],
    };
  }

  get isInReportingPeriod(): boolean {
    const currentDate = new Date();
    return (
      this.reportingPeriodStatus != null &&
      this.reportingPeriodStatus.id != null &&
      this.reportingPeriodStatus.isStatDecMandatory &&
      currentDate.getTime() >=
        Date.parse(this.reportingPeriodStatus.startDateTime) &&
      currentDate.getTime() <=
        Date.parse(this.reportingPeriodStatus.endDateTime)
    );
  }

  setHasNoLobbyists() {
    this.lobbyistTotal = (<FormArray>(
      this.form.get("lobbyists")
    )).controls.filter((lobbyist) => {
      return (
        lobbyist.get("forDeletion").value !== true &&
        lobbyist.get("submissionReason").value !== this.FOR_DEREGISTRATION
      );
    }).length;

    if (this.lobbyistTotal === 0) {
      this.form.get("hasNoLobs").enable();
    } else {
      this.form.get("hasNoLobs").patchValue(false);
      this.form.get("hasNoLobs").disable();
    }
  }

  ngOnInit() {
    this.onSubstepChange.subscribe((newSubstep) => {
      this.activeSubstep = RegistrationSubsteps[newSubstep];
      if (this.activeSubstep === RegistrationSubsteps["Lobbyists list"]) {
        this.clearSelection();
      }
    });

    this.onSaved.subscribe(() => {
      this.sortList(
        (<FormArray>this.form.get("lobbyists")).controls,
        "statusDescription",
        true
      );
    });

    this.googleAnalyticsService.logPageVisit("step-lobbyists");

    this.setHasNoLobbyists();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectLobbyist(lobbyistId: string) {
    this.unsubscribe$.next();

    this.lobbyistId = lobbyistId;
    (<FormArray>this.form.get("lobbyists")).controls
      .find((l) => l.get("id").value === this.lobbyistId)
      .patchValue({
        status: "Draft",
        statusDescription: "Not yet submitted",
      });

    this.activeSubstep = RegistrationSubsteps["Add lobbyist/s"];
    this.lobbyistStepChanged.emit({
      name: this.activeSubstep,
      form: this.lobbyistForm,
    });
  }

  selectNewLobbyist() {
    this.unsubscribe$.next();

    var lobbyists = <FormArray>this.form.get("lobbyists");
    this.lobbyistId = lobbyists.controls[lobbyists.length - 1].get("id").value;

    this.activeSubstep = RegistrationSubsteps["Add lobbyist/s"];
    this.lobbyistStepChanged.emit({
      name: this.activeSubstep,
      form: this.lobbyistForm,
    });
  }

  clearSelection() {
    this.lobbyistId = null;
  }

  public sortColumns = {
    description: true,
    statusDescription: true,
  };

  sort(column: string) {
    this.sortColumns[column] = !this.sortColumns[column];
    this.sortList(
      (<FormArray>this.form.get("lobbyists")).controls,
      column,
      this.sortColumns[column]
    );
  }
}
