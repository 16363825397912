import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WizardNavComponent } from './components/wizard-nav/wizard-nav.component';
import { WizardActionsComponent } from './components/wizard-actions/wizard-actions.component';

import { ControlsModule } from './../controls/controls.module';

@NgModule({
    imports: [
        CommonModule,
        ControlsModule
    ],
    exports: [
        WizardActionsComponent,
        WizardNavComponent
    ],
    declarations: [WizardNavComponent, WizardActionsComponent]
})
export class WizardModule { }
