import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
} from "@angular/core";
import { Observable } from "rxjs/Rx";
import { ISearchRequest } from "../../../../organisation/services/search-request";
import { SortOrder } from "../../../../organisation/services/sort-criteria";
import {
  ReportingService,
  ISearchDownloadRequest,
} from "../../../../organisation/services/reporting.service";

import * as FileSaver from "file-saver";
import * as moment from "moment";
import { SearchService } from "../../../services/search.service";

@Component({
  selector: "app-search-bar-register",
  templateUrl: "./search-bar-register.component.html",
  styleUrls: ["./search-bar-register.component.scss"],
})
export class SearchBarRegisterComponent implements OnInit {
  public isSearching: boolean;

  constructor(
    private elementRef: ElementRef,
    private reportingService: ReportingService,
    public searchService: SearchService
  ) {
    searchService.isSearching.subscribe((event) => {
      this.isSearching = event
    });
  }

  public download(event, fileExtension) {
    event.preventDefault();

    let request: ISearchDownloadRequest = {
      fileExtension: fileExtension,
      searchCriteria: { ...this.searchService.searchRequest },
    };

    request.searchCriteria.pageNumber = 1;

    if (this.isSearching) {
      return;
    }
    this.searchService.download(request);
  }

  ngOnInit() {}

  public doSearch(event) {
    event.preventDefault();
    this.searchService.doSearch();
  }

  public reset(event) {
    event.preventDefault();
    this.searchService.reset();
  }
}
