import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  ChangeDetectorRef, 
  AfterContentChecked
} from "@angular/core";
import { Router, Event, NavigationEnd, ActivatedRoute } from "@angular/router";
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
  ValidationErrors,
  FormArray,
  AsyncValidatorFn,
  FormControl,
  RequiredValidator,
} from "@angular/forms";
import { JoyrideService } from "ngx-joyride";
import {
  Subject,
  Observable,
  Subscription,
  combineLatest,
  timer,
  of,
} from "rxjs";
import {
  tap,
  takeUntil,
  catchError,
  switchMap,
  map,
  finalize,
  takeWhile,
} from "rxjs/operators";
import { Store } from "@ngrx/store";
import { NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AbnService } from "app/modules/controls/services/abn.service";
import { OfficerService } from "app/modules/controls/services/officer.service";
import {
  RegistrationFormService,
  IStatDecFile,
  ILookup,
  IPosition,
  IRegistrationFormData,
} from "app/modules/organisation/services/registration-form.service";
import { PrintService } from "app/modules/organisation/services/print.service";
import { ContentService } from "app/modules/content/services/content.service";
import { IReportingPeriodStatus } from "app/modules/organisation/services/registration-form.service";
import { ToastService } from "../../../services/toast.service";
import { IAbnResult, IOfficerEmailResult } from "app/modules/controls/models";
import {
  IMyProfileStatus,
  IFormStep,
  IFormSubstep,
  RegistrationSteps,
  RegistrationSubsteps,
} from "app/modules/organisation/models";
import * as fromRoot from "./../../../../../reducers";
import * as fromDashboardNotifications from "app/reducers/dashboard-notifications";
import * as FileSaver from "file-saver";
import * as moment from "moment";
import { IConfiguration } from "app/modules/configuration/reducers";
import { StepOwnersComponent } from "../step-owners/step-owners.component";
import { StepClientsComponent } from "../step-clients/step-clients.component";
import { StepLobbyistsComponent } from "../step-lobbyists/step-lobbyists.component";
import { StepResponsibleOfficerComponent } from "../step-responsible-officer/step-responsible-officer.component";
import {
  TutorialIntroSteps,
  TutorialSoleTraderSteps,
  TutorialOrgDetailsSubsteps,
  TutorialResponOfficerSubsteps,
  TutorialOwnersSubsteps,
  TutorialLobbyistSubSteps,
  TutorialCleintSubSteps,
  TutorialReviewSteps,
} from "../../../helpers/tutorial-steps";
import { UtilityFunctions } from "app/modules/organisation/helpers/utilFuncs";
import { AuthService } from "app/modules/identity/services/auth.service";

function getValidAbnValidator(abnService: AbnService) {
  return (control: AbstractControl) => {
    if (
      control.value != null &&
      control.value.length > 0 &&
      !abnService.isValidAbn(control.value)
    ) {
      return { validAbn: true };
    }
    return null;
  };
}

function getUniqueResponsibleOfficerEmailValidator(
  officerService: OfficerService
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<IOfficerEmailResult | null> => {
    if (control.value != null && control.value.length > 0) {
      // send empty guid for new records
      var id = control.parent.get("id").value;
      if (id.indexOf(";") != -1) {
        id = "00000000-0000-0000-0000-000000000000";
      }

      return officerService.lookup(control.value, id).pipe(
        map((result) => {
          control.parent.get("emailIsTaken").setValue(result.emailIsTaken);
          if (result.emailIsTaken) {
            return result;
          } else {
            return null;
          }
        })
      );
    } else {
      return of(null);
    }
  };
}

function validateAtLeastOneResponsibleOfficer(
  form: FormGroup
): ValidationErrors | null {
  var officers = <FormArray>form.get("responsibleOfficers.responsibleOfficers");
  if (officers == null) {
    // being invoked from lobbyist list screen
    officers = <FormArray>form.get("responsibleOfficers");
  }

  if (
    officers.length > 0 &&
    officers.controls.every(
      (l) => l.get("forDeletion") && l.get("forDeletion").value
    ) &&
    !officers.controls.find(
      (o) =>
        !(o.get("forDeletion") && o.get("forDeletion").value) &&
        o.get("emailVerificationStatus").value == "Accepted"
    )
  ) {
    return {
      missingAtLeastOneOfficer: true,
    };
  } else {
    return null;
  }
}

function validateAtLeastOneResponsibleOfficerReceivesNotifications(
  form: FormGroup
): ValidationErrors | null {
  var officers = <FormArray>form.get("responsibleOfficers.responsibleOfficers");
  if (officers == null) {
    // being invoked from lobbyist list screen
    officers = <FormArray>form.get("responsibleOfficers");
  }

  if (
    officers.length > 0 &&
    (officers.controls.every(
      (l) => l.get("forDeletion") && l.get("forDeletion").value
    ) ||
      officers.controls
        .filter((o) => o.get("emailVerificationStatus").value == "Accepted")
        .every((o) => !o.get("sendNotifications").value))
  ) {
    return {
      noOfficersReceiveCommunications: true,
    };
  } else {
    return null;
  }
}

/**
 * Validate that either:
 * Alternate Business Number and Alternate Business Number Description fields are both filled in or both empty
 * (only validates if "I don't have an ABN" is ticked)
 */
function validateOrganisationDetailsAlternateBusinessNumberFields(
  control: FormGroup
): ValidationErrors | null {
  const hasNoAbn = control.get("hasNoAbn");
  const alternateBusinessNumberDescription = control.get(
    "alternateBusinessNumberDescription"
  );
  const alternateBusinessNumber = control.get("alternateBusinessNumber");

  const hasNoAbnValue = hasNoAbn !== null ? hasNoAbn.value == true : false;
  const abnHasValue =
    alternateBusinessNumber !== null &&
    alternateBusinessNumber.value !== null &&
    alternateBusinessNumber.value.length > 0;
  const abnDescHasValue =
    alternateBusinessNumberDescription !== null &&
    alternateBusinessNumberDescription.value !== null &&
    alternateBusinessNumberDescription.value.length > 0;

  if (hasNoAbnValue && abnHasValue != abnDescHasValue) {
    return { alternateBusinessNumberFields: true };
  }

  return null;
}

/**
 * Validate that if "Has trading name" is YES, then a trading name is entered.
 */
function validateOrganisationDetailsTradingName(
  control: FormGroup
): ValidationErrors | null {
  const tradingName = control.get("tradingName");
  const hasTradingName = control.get("hasTradingName");
  const error = { tradingName: true };

  if (hasTradingName == null || tradingName == null) {
    return error;
  }

  if (hasTradingName.value == true) {
    if (tradingName.value == null || tradingName.value.length <= 0) {
      return error;
    }
  }

  return null;
}

/**
 * If postal address is chosen to be entered manually, validate all required fields.
 */
function validateOrganisationDetailsAddressFields(
  businessEntityPostalAddress: FormGroup
): ValidationErrors | null {
  if (businessEntityPostalAddress.parent == null) {
    return null;
  }

  const postalAddressIsSameAsPhysicalAddress = businessEntityPostalAddress.parent.get(
    "postalAddressIsSameAsPhysicalAddress"
  );

  if (
    postalAddressIsSameAsPhysicalAddress !== null &&
    postalAddressIsSameAsPhysicalAddress.value == false
  ) {
    var hasErrors = false;
    var errors = {};

    ["addressLine1", "suburb", "state", "postcode", "country"].forEach(
      (fieldName) => {
        const field = businessEntityPostalAddress.get(fieldName);
        if (field == null || field.value == null || field.value.length <= 0) {
          errors[`addressError_${fieldName}`] = true;
          hasErrors = true;
        }
      }
    );

    return hasErrors ? errors : null;
  }

  return null;
}

/**
 * Validate owners. Set of required fields changes depending on whether it is a person or business.
 */
function validateOwner(control: FormGroup): ValidationErrors | null {
  const isBusiness = control.parent.get("ownerType.isBusiness");
  const isBusinessValue = isBusiness !== null ? isBusiness.value == true : null;

  let errors = {};
  let hasErrors = false;

  if (isBusinessValue == true) {
    const businessName = control.get("businessName");
    if (
      businessName == null ||
      businessName.value == null ||
      businessName.value.length <= 0
    ) {
      errors["businessName_required"] = true;
      hasErrors = true;
    }
  } else if (isBusinessValue == false) {
    const firstName = control.get("firstName");
    if (
      firstName == null ||
      firstName.value == null ||
      firstName.value.length <= 0
    ) {
      errors["firstName_required"] = true;
      hasErrors = true;
    }

    const lastName = control.get("lastName");
    if (
      lastName == null ||
      lastName.value == null ||
      lastName.value.length <= 0
    ) {
      errors["lastName_required"] = true;
      hasErrors = true;
    }
  }

  return hasErrors ? errors : null;
}

function validateLobbyist(control: FormGroup): ValidationErrors | null {
  const isFormerRep = control.get("isFormerRepresentative");
  const isFormerRepValue =
    isFormerRep !== null ? isFormerRep.value == true : null;

  const requiredLevels = [
    "EmployedorengagedbyaMinisteroraParliamentarySecretaryundertheMembersofParliament_StaffAct1984",
    "EmployedunderthePublicServiceAct1999",
    "ContractorconsultantforanagencywhosestaffareemployedunderthePublicServiceAct1999",
    "MemberoftheAustralianDefenceForce",
  ];
  const selectedPosition = control.get("previousPosition")
    ? control.get("previousPosition").value
    : undefined;

  const requiresLevel = requiredLevels.some((opt) => opt === selectedPosition);
  const levelVal = control.get("previousPositionLevel").value;

  let errors = {};
  let hasErrors = false;

  if (isFormerRepValue == true) {
    const cessationDate = control.get("ngbCessationDate");
    if (
      cessationDate == null ||
      cessationDate.value == null ||
      cessationDate.value.length <= 0
    ) {
      errors["ngbCessationDate_required"] = true;
      hasErrors = true;
    }

    // if (requiresLevel && !levelVal) {
    //   errors["previousPositionLevel_required"] = true;
    //   hasErrors = true;
    //   // control.get("previousPositionLevel").valid = false;
    // }
  }

  return hasErrors ? errors : null;
}

// if stat dec is uploaded then mandatory
// if date is less than 10 business days before the start of
// current financl year then error
function dateWitnessedValidations(
  control: FormControl
): ValidationErrors | null {
  const dateControl = control.get("ngbDateWitnessed");
  const statDecControl = control.get("statDecUpload");
  const hasStatDecs = statDecControl ? statDecControl.value.length > 0 : false;
  // ngb date format doesn't use 0 indexed months
  const dateValue =
    dateControl && dateControl.value
      ? { ...dateControl.value, month: dateControl.value.month - 1 }
      : null;
  const parsedDate = moment(dateValue);
  const minDate = UtilityFunctions.minStatDecDateCalculation();

  let errors = {};
  let hasError = false;

  if (hasStatDecs) {
    if (!parsedDate.isValid()) {
      errors["ngbDateWitnessed_required"] = true;
      hasError = true;
    } else {
      const tooEarly = parsedDate.isBefore(minDate);
      const tooLate = parsedDate.isAfter(moment());
      const noBueno = tooEarly || tooLate;
      errors["dateWitnessedError"] = noBueno;
      hasError = noBueno;
    }
  } else {
    if (dateControl) dateControl.clearValidators();
  }
  return hasError ? errors : null;
}

function validateClient(control: FormGroup): ValidationErrors | null {
  const tradingName = control.get("tradingName");
  const hasTradingName = control.get("hasTradingName");
  const error = { tradingName: true };

  if (hasTradingName == null || tradingName == null) {
    return error;
  }

  if (hasTradingName.value == true) {
    if (tradingName.value == null || tradingName.value.length <= 0) {
      return error;
    }
  }

  return null;
}

function validateHasNoClients(form: FormGroup): ValidationErrors | null {
  var FOR_DEREGISTRATION = 981660001;

  const clients = <FormArray>form.get("clients");
  const hasNoClients = form.get("hasNoClients").value;

  if (
    !hasNoClients &&
    (clients.length == 0 ||
      clients.controls.every(
        (c) =>
          c.get("submissionReason").value == FOR_DEREGISTRATION ||
          c.get("statusDescription").value == "Deregistered" ||
          c.get("forDeletion").value == true
      ))
  ) {
    return {
      mustHaveClients: true,
    };
  }

  return null;
}

function validateAllLobbyistsHaveStatDecsAttached(
  form: FormGroup
): ValidationErrors | null {
  var FOR_DEREGISTRATION = 981660001; // oh no, another hard-coded constant

  var lobbyists = <FormArray>form.get("lobbyists.lobbyists");
  if (lobbyists == null) {
    // being invoked from lobbyist list screen
    lobbyists = <FormArray>form.get("lobbyists");
  }

  var msg =
    "Please ensure that you have uploaded a Statutory Declaration for each lobbyist. This has not been provided for: ";
  var anyMissing = false;
  var names = [];

  for (let i = 0; i < lobbyists.length; ++i) {
    const lobbyist = lobbyists.at(i);

    if (
      lobbyist.get("submissionReason").value != FOR_DEREGISTRATION &&
      lobbyist.get("statusDescription").value != "Submitted for removal" &&
      lobbyist.get("lobbyistDetails.statDecPreviouslyUploaded").value !==
        true &&
      (lobbyist.get("lobbyistDetails.statDecUpload").value == null ||
        lobbyist.get("lobbyistDetails.statDecUpload").value.length == 0)
    ) {
      anyMissing = true;
      names.push(lobbyist.get("description").value);
    }
  }

  if (anyMissing) {
    msg = msg + names.join(", ");
    return { statDecMissing: { message: msg } };
  } else {
    return null;
  }
}

function validateLobbyistEmailsAreUnique(
  form: FormGroup
): ValidationErrors | null {
  var groupBy = function <AbstractControl>(
    xs: FormArray,
    key: string
  ): { [key: string]: AbstractControl[] } {
    return xs.controls.reduce(function (rv, x) {
      (rv[x.get(key).value] = rv[x.get(key).value] || []).push(x);
      return rv;
    }, {});
  };

  var lobbyists = <FormArray>form.get("lobbyists.lobbyists");
  if (lobbyists == null) {
    // being invoked from lobbyist list screen
    lobbyists = <FormArray>form.get("lobbyists");
  }

  var msg =
    "Each lobbyist must have a different email address. These lobbyists have the same email address: ";
  var anyDupes = false;
  var names = [];

  var lobbyistsByMail = groupBy(lobbyists, "lobbyistDetails.email");
  var emails = Object.keys(lobbyistsByMail);

  for (let i = 0; i < emails.length; ++i) {
    const lobbyists = <AbstractControl[]>lobbyistsByMail[emails[i]];

    if (lobbyists.length > 1) {
      var dupeNames = [];
      lobbyists.forEach((l) => {
        if (l.get("forDeletion").value == false) {
          dupeNames.push(l.get("description").value);
        }
      });

      if (dupeNames.length > 1) {
        anyDupes = true;
        names.push(dupeNames.join(", ") + " (" + emails[i] + ")");
      }
    }
  }

  if (anyDupes) {
    msg = msg + names.join("; ");
    return { duplicateEmails: { message: msg } };
  } else {
    return null;
  }
}

function validateAtLeastOneLobbyist(form: FormGroup): ValidationErrors | null {
  var FOR_DEREGISTRATION = 981660001; // oh no, another hard-coded constant

  var lobbyists = <FormArray>form.get("lobbyists.lobbyists");
  if (lobbyists == null) {
    // being invoked from lobbyist list screen
    lobbyists = <FormArray>form.get("lobbyists");
  }

  const allRecordsAreForDeletionOrDeregistration = lobbyists.controls.every(
    (l) =>
      (l.get("submissionReason") &&
        l.get("submissionReason").value == FOR_DEREGISTRATION) ||
      (l.get("forDeletion") && l.get("forDeletion").value)
  );

  const hasNoLobbyistsCheckboxTicked =
    form.get("hasNoLobs") && form.get("hasNoLobs").value;

  if (
    (lobbyists.length === 0 && !hasNoLobbyistsCheckboxTicked) ||
    (allRecordsAreForDeletionOrDeregistration && !hasNoLobbyistsCheckboxTicked)
  ) {
    return {
      missingAtLeastOneLobbyist: true,
    };
  } else {
    return null;
  }
}

function validateAtLeastOneOwner(form: FormGroup): ValidationErrors | null {
  var FOR_DEREGISTRATION = 981660001; // oh no, another hard-coded constant

  var owners = <FormArray>form.get("owners.owners");
  if (owners == null) {
    // being invoked from owner list screen
    owners = <FormArray>form.get("owners");
  }

  if (
    owners.length > 0 &&
    owners.controls.every(
      (o) =>
        (o.get("submissionReason") &&
          o.get("submissionReason").value == FOR_DEREGISTRATION) ||
        (o.get("forDeletion") && o.get("forDeletion").value)
    )
  ) {
    return {
      missingAtLeastOneOwner: true,
    };
  } else {
    return null;
  }
}

@Component({
  selector: "app-registration-form",
  templateUrl: "./registration-form.component.html",
  styleUrls: ["./registration-form.component.scss"],
})
export class RegistrationFormComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject();
  public onSaved: Subject<void> = new Subject();
  public onSubstepChange: Subject<string> = new Subject<string>();
  private cancelTimer: Subject<boolean> = new Subject<boolean>();

  private get ownersOwnerTemplate() {
    return {
      id: [null],
      ownerType: this.fb.group({
        isBusiness: [null, Validators.required],
      }),
      ownerDetails: this.fb.group({
        firstName: [null],
        lastName: [null],
        email: [null, [Validators.email]],
        businessName: [null],
      }),
      status: "Draft",
      statusCode: 0,
      statusDescription: "Not yet submitted",
      submissionReason: [null],
      publicId: [null],
      description: null,
      hasChanged: null,
      previouslyApproved: false,
      forDeletion: false,
      datePublished: [null],
      dateDeregistered: [null],
    };
  }

  private getLobbyistsLobbyistTemplate() {
    return {
      id: [null],
      lobbyistDetails: this.fb.group({
        firstName: [null, Validators.required],
        lastName: [null, Validators.required],
        position: [null, Validators.required],
        email: [null, [Validators.required, Validators.email]],
        statDecUpload: [{ value: [], disabled: true }],
        statDecPreviouslyUploaded: [null],
        ngbDateWitnessed: [null],
        dateWitnessed: [null],
      }),
      formerDetails: this.fb.group({
        isFormerRepresentative: [null, Validators.required],
        previousPosition: [null],
        previousPositionLevel: [null],
        previousPositionOther: [null],
        ngbCessationDate: [null],
        cessationDate: [null], // needed for comparison on review/submit
      }),
      status: "Draft",
      statusCode: 0,
      statusDescription: "Not yet submitted",
      submissionReason: [null],
      submissionReasonDescription: [null],
      publicId: [null],
      description: [null],
      hasChanged: [null],
      forDeletion: false,
      previouslyApproved: false,
      deregistrationReason: [null],
      deregistrationDescription: [null],
      datePublished: [null],
      dateDeregistered: [null],
    };
  }

  private get clientsClientTemplate() {
    return {
      id: [null],
      abn: [null, [getValidAbnValidator(this.abnService)]],
      alternateBusinessNumberDescription: [null],
      alternateBusinessNumber: [null],
      hasNoAbn: [false],
      businessEntityName: [null, [Validators.required]],
      hasTradingName: [null, [Validators.required]],
      tradingName: [null],
      status: "Draft",
      statusCode: 0,
      statusDescription: "Not yet submitted",
      submissionReason: [null],
      publicId: [null],
      description: [null],
      hasChanged: [null],
      previouslyApproved: false,
      forDeletion: false,
      dateDeregistered: [null],
      datePublished: [null],
    };
  }

  private get responsibleOfficerTemplate() {
    return {
      id: [null],
      title: [null, [Validators.required]],
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [
        null,
        {
          validators: [Validators.required, Validators.email],
          updateOn: "blur",
        },
      ],
      primaryPhoneNumber: [null, [Validators.required]], // TODO: Telephone number validation
      secondaryPhoneNumber: [null], // TODO: Telephone number validation,
      status: this.fb.group({
        status: [null],
        statusCode: 0,
      }),
      submissionReason: [null],
      previouslyApproved: false,
      hasChanged: false,
      isSaved: false,
      emailVerificationStatus: "Confirmationpending",
      sendNotifications: true,
      forDeletion: false,
      description: null,
      subjectId: null,
      emailIsTaken: false,
      accountActivationStatus: null,
      hasSeenTutorial: false,
      earsupn: null,
    };
  }

  steps: IFormStep[];

  failedToInitialise = false;
  failedToSave = false;
  isInitialised = false;
  isSaving = false;
  showValidationSummary = false;
  profile: IMyProfileStatus = null;
  titles: ILookup[] = [];
  positions: IPosition[] = [];

  form: FormGroup;
  originalFormData: FormGroup;

  isPrinting = false;
  returnToReview = false;

  reportingPeriodStatus: IReportingPeriodStatus;

  hasNoChanges = false;

  private logoutTimer: Subscription;
  private config: IConfiguration;

  get saveNotNeeded(): boolean {
    var filesToUpload = false;
    var lobbyistsWithFiles = <FormArray>this.form.get("lobbyists.lobbyists");
    for (let i = 0; i < lobbyistsWithFiles.length; ++i) {
      const lobbyistUpload = lobbyistsWithFiles
        .at(i)
        .get("lobbyistDetails.statDecUpload").value;
      if (lobbyistUpload != null && lobbyistUpload.length > 0) {
        filesToUpload = true;
      }
    }

    var id: string;
    var hasId = false;
    if (this.currentForm != null) {
      if (this.currentForm.get("id")) {
        id = this.currentForm.get("id").value;
        hasId = true;
      } else if (this.currentForm.parent && this.currentForm.parent.get("id")) {
        id = this.currentForm.parent.get("id").value;
        hasId = true;
      }
    }

    return (
      !filesToUpload &&
      !this.currentForm.dirty &&
      (!hasId || !id.startsWith(this.emptyGuid))
    );
  }

  get isInReportingPeriod(): boolean {
    const currentDate = new Date();

    return (
      this.reportingPeriodStatus != null &&
      this.reportingPeriodStatus.id != null &&
      this.reportingPeriodStatus.status == "NotCompliant" &&
      currentDate.getTime() >=
        Date.parse(this.reportingPeriodStatus.startDateTime) &&
      currentDate.getTime() <=
        Date.parse(this.reportingPeriodStatus.endDateTime)
    );
  }

  get isDeregistered(): boolean {
    return (
      [
        "Deregistered",
        "Removed",
        "Submittedforremoval",
        "PendingDeregistration",
      ].indexOf(this.profile.status) != -1 || this.profile.isSuspended
    );
  }

  get isNewApplication(): boolean {
    return (
      this.profile.isInitialised &&
      !(
        this.profile.status == "SubmittedforReview" ||
        this.profile.status == "PendingApproval" ||
        this.profile.isOrganisationReviewed
      )
    );
  }

  _currentStep: IFormStep;
  currentSubstep: IFormSubstep;

  get currentStep(): IFormStep {
    return this._currentStep;
  }

  set currentStep(newValue: IFormStep) {
    this._currentStep = newValue;

    if (this._currentStep.substeps.length > 0) {
      this.currentSubstep = this._currentStep.substeps[0];
    } else {
      this.currentSubstep = null;

      // no substep change event for Responsible Officer, need to stash form data here
      if (this._currentStep.title == RegistrationSteps["Responsible Officer"]) {
        this.registrationFormService.selectRecord(this._currentStep.form.value);
      } else if (this._currentStep.title == "Organisation details") {
        this.registrationFormService.selectRecord(this.currentForm.value);
      }
    }
  }

  get currentForm(): AbstractControl {
    if (this.currentSubstep == null || this.currentSubstep.form == null) {
      return this.currentStep.form;
    } else {
      switch (this.currentStep.title) {
        case RegistrationSteps["Organisation details"]:
          return this.currentSubstep.form;
        case RegistrationSteps["Owners"]:
          return this.ownersList.ownerForm;
        case RegistrationSteps["Clients"]:
          return this.clientsList.clientForm;
        case RegistrationSteps["Lobbyists"]:
          return this.lobbyistsList.lobbyistForm;
        case RegistrationSteps["Responsible officers"]:
          return this.officersList.officerForm;
        default:
          return this.currentStep.form;
      }
    }
  }

  @ViewChild("owners", { read: false, static: false })
  ownersList: StepOwnersComponent;
  @ViewChild("clients", { read: false, static: false })
  clientsList: StepClientsComponent;
  @ViewChild("lobbyists", { read: false, static: false })
  lobbyistsList: StepLobbyistsComponent;
  @ViewChild("officers", { read: false, static: false })
  officersList: StepResponsibleOfficerComponent;

  @ViewChild("modalLoading", { read: false, static: false })
  modalLoading: ElementRef;
  @ViewChild("modalSaving", { read: false, static: false })
  modalSaving: ElementRef;
  @ViewChild("modalLogout", { read: false, static: false })
  modalLogout: ElementRef;

  @ViewChild("modalDeregister", { read: false, static: false })
  modalDeregister: ElementRef;
  @ViewChild("modalDeclarations", { read: false, static: false })
  modalDeclarations: ElementRef;

  @ViewChild("modalReset", { read: false, static: false })
  modalReset: ElementRef;
  @ViewChild("modalDelete", { read: false, static: false })
  modalDelete: ElementRef;
  @ViewChild("modalBlocked", { read: false, static: false })
  modalBlocked: ElementRef;
  @ViewChild("modalCancel", { read: false, static: false })
  modalCancel: ElementRef;

  @ViewChild("modalResendError", { read: false, static: false })
  modalResendError: ElementRef;
  @ViewChild("modalActivationError", { read: false, static: false })
  modalActivationError: ElementRef;

  constructor(
    private fb: FormBuilder,
    private abnService: AbnService,
    private registrationFormService: RegistrationFormService,
    private router: Router,
    private store: Store<fromRoot.IState>,
    private printService: PrintService,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private modalService: NgbModal,
    private officerService: OfficerService,
    private joyrideService: JoyrideService,
    private authService: AuthService,
    private cdref: ChangeDetectorRef
  ) {
    this.store.select(fromRoot.getMyProfileStatus).subscribe((profile) => {
      this.profile = profile;
    });

    this.store.select(fromRoot.getReportingPeriodStatus).subscribe((status) => {
      if (status.portalMessage == "UNINITIALISED") {
        this.registrationFormService.reportingPeriod$.subscribe();
      } else {
        this.reportingPeriodStatus = status;
      }
    });

    this.store
      .select(fromRoot.getConfig)
      .subscribe((config) => (this.config = config));
  }

  launchHelp(event) {
    // this is pretty wild so bear with me here
    // the docs for the 'ngx-joyride' library are not the greatest,
    // it's actually easiest to understand how it holds together by
    // looking at the source of their demo app
    // https://github.com/tnicola/ngx-joyride/tree/master/projects/demo/src/app/components

    // tl;dr each registration form step has some step-specific tutorial steps, but
    // also some generic tutorial steps that exist outside of that step's context, so the actual
    // markup annotations live in some other component somplace else in the app

    // if you're trying to debug the steps, or change or add new order take a look at
    // tutorial-steps.ts; probably your best bet for a concise view of what's going on
    // just search for the step name and you'll find his markup

    // when this help functino is called, we'll read whatever the current
    // step or stubstep is, and assign the right combo of tutorial
    // steps, which also live outside this 3k line beast in ./tutorial-steps.ts,
    // then we'll load them up and then yeehaw into the sunset.

    event.preventDefault();

    const curStep = this.currentStep;
    const subStep = this.currentSubstep;
    let steps;

    switch (curStep.title) {
      case RegistrationSteps["Introduction"]:
        steps = TutorialIntroSteps;
        break;
      case RegistrationSteps["Sole tradership"]:
        steps = TutorialSoleTraderSteps;
        break;
      case RegistrationSteps["Organisation details"]:
        steps = TutorialOrgDetailsSubsteps[subStep.name] || [
          "noHelpAvailable@user/form",
        ];
        break;
      case RegistrationSteps["Responsible officers"]:
        steps = TutorialResponOfficerSubsteps[subStep.name] || [
          "noHelpAvailable@user/form",
        ];
        break;
      case RegistrationSteps["Owners"]:
        steps = TutorialOwnersSubsteps[subStep.name] || [
          "noHelpAvailable@user/form",
        ];
        break;
      case RegistrationSteps["Lobbyists"]:
        steps = TutorialLobbyistSubSteps[subStep.name] || [
          "noHelpAvailable@user/form",
        ];
        break;
      case RegistrationSteps["Clients"]:
        steps = TutorialCleintSubSteps[subStep.name] || [
          "noHelpAvailable@user/form",
        ];
        break;
      case RegistrationSteps["Review & submit"]:
        steps = TutorialReviewSteps;
        break;
      default:
        steps = ["noHelpAvailable@user/form"];
        break;
    }

    const options = {
      steps,
      stepDefaultPosition: "top",
      themeColor: "#50748a",
      showPrevButton: true,
      logsEnabled: false,
    };

    this.joyrideService.startTour(options).subscribe(
      (step) => {
        if (step.name === "reviewSubmitButtonStep") {
          window.scrollTo(0, document.body.scrollHeight);
        } else {
          window.scrollTo(0, 0);
        }
        console.log("Next:", step);
      },
      (e) => {
        console.log("Error", e);
      },
      () => {
        console.log("Tour finished");
      }
    );
  }

  reloadFormData(form: FormGroup, formData: IRegistrationFormData) {
    const ownersArray = <FormArray>form.get("owners.owners");
    ownersArray.clear();
    const lobbyistsArray = <FormArray>form.get("lobbyists.lobbyists");
    lobbyistsArray.clear();
    const clientsArray = <FormArray>form.get("clients.clients");
    clientsArray.clear();
    const officersArray = <FormArray>(
      form.get("responsibleOfficers.responsibleOfficers")
    );
    officersArray.clear();

    // pre-process bad data - going to sort out tomorrow what i am doing about this
    formData.owners.owners.forEach(
      (owner) =>
        (owner.isBusiness = !(
          owner.firstName != null &&
          owner.lastName != null &&
          owner.firstName.length > 0 &&
          owner.lastName.length > 0
        ))
    );
    formData.owners.owners.forEach(
      (owner) => (owner.businessName = owner.lastName)
    );
    if (formData.responsibleOfficer.status == null)
      formData.responsibleOfficer.status = {
        status: "Draft",
        statusCode: 0,
        statusReason: "Bad data",
        statusDescription: "Draft",
      };

    // Add correct number of owners/lobbyists/clients to model
    formData.owners.owners.forEach(() => this.ownersAdd(form, false));
    formData.lobbyists.lobbyists.forEach(() => this.lobbyistsAdd(form, false));
    formData.clients.clients.forEach(() => this.clientsAdd(form, false));
    formData.responsibleOfficers.responsibleOfficers.forEach(() =>
      this.officersAdd(form, false)
    );
    form.patchValue(formData);
  }

  ngOnInit() {
    this.form = this.buildOrganisationFormGroup();
    this.originalFormData = this.buildOrganisationFormGroup();

    this.steps = [
      {
        title: RegistrationSteps["Introduction"],
        form: <FormGroup>this.form.get("introduction"),
        complete: null,
        canSave: false,
        substeps: [],
      },
      {
        title: RegistrationSteps["Sole tradership"],
        form: <FormGroup>this.form.get("soleTradership"),
        complete: null,
        substeps: [],
      },
      {
        title: RegistrationSteps["Organisation details"],
        form: <FormGroup>this.form.get("organisationDetails"),
        complete: null,
        substeps: [
          {
            name: RegistrationSubsteps["Business details"],
            form: <FormGroup>(
              this.form.get("organisationDetails.businessDetails")
            ),
          },
          {
            name: RegistrationSubsteps["Contact"],
            form: <FormGroup>(
              this.form.get("organisationDetails.contactDetails")
            ),
          },
          {
            name: RegistrationSubsteps["Business address"],
            form: <FormGroup>(
              this.form.get("organisationDetails.businessEntityPhysicalAddress")
            ),
          },
          {
            name: RegistrationSubsteps["Postal address"],
            form: <FormGroup>(
              this.form.get("organisationDetails.businessEntityPostalAddress")
            ),
          },
        ],
      },
      {
        title: RegistrationSteps["Responsible officers"],
        form: <FormGroup>this.form.get("responsibleOfficers"),
        complete: null,
        substeps: [
          {
            name: RegistrationSubsteps["Officers list"],
            form: null,
          },
          {
            name: RegistrationSubsteps["Add officer/s"],
            form: <FormGroup>this.form.get("responsibleOfficers"),
          },
          {
            name: RegistrationSubsteps["Officers overview"],
            form: null,
          },
        ],
      },
      {
        title: RegistrationSteps["Owners"],
        form: <FormGroup>this.form.get("owners"),
        complete: null,
        substeps: [
          {
            name: RegistrationSubsteps["Owners list"],
            form: null,
          },
          {
            name: RegistrationSubsteps["Owner type"],
            form: <FormGroup>this.form.get("owners"),
          },
          {
            name: RegistrationSubsteps["Add owner/s"],
            form: <FormGroup>this.form.get("owners"),
          },
          {
            name: RegistrationSubsteps["Owners overview"],
            form: null,
          },
        ],
      },
      {
        title: RegistrationSteps["Lobbyists"],
        form: <FormGroup>this.form.get("lobbyists"),
        complete: null,
        substeps: [
          {
            name: RegistrationSubsteps["Lobbyists list"],
            form: null,
          },
          {
            name: RegistrationSubsteps["Add lobbyist/s"],
            form: <FormGroup>this.form.get("lobbyists"),
          },
          {
            name: RegistrationSubsteps["Former details"],
            form: <FormGroup>this.form.get("lobbyists"),
          },
          {
            name: RegistrationSubsteps["Lobbyists overview"],
            form: null,
          },
        ],
      },
      {
        title: RegistrationSteps["Clients"],
        form: <FormGroup>this.form.get("clients"),
        complete: null,
        substeps: [
          {
            name: RegistrationSubsteps["Clients list"],
            form: null,
          },
          {
            name: RegistrationSubsteps["Add client/s"],
            form: <FormGroup>this.form.get("clients"),
          },
          {
            name: RegistrationSubsteps["Clients overview"],
            form: null,
          },
        ],
      },
      {
        title: RegistrationSteps["Review & submit"],
        form: this.form,
        complete: null,
        substeps: [],
      },
    ];

    var selectedStep = 0;

    if (this.route.snapshot.queryParamMap.has("step")) {
      var stepName = this.route.snapshot.queryParamMap.get("step");

      switch (stepName) {
        case "organisation":
          selectedStep = 0;
          break;
        case "officer":
          selectedStep = 1;
          break;
        case "owner":
          selectedStep = 2;
          break;
        case "lobbyists":
          selectedStep = 3;
          break;
        case "clients":
          selectedStep = 4;
          break;
        case "review":
          selectedStep = 5;
          break;
        default:
          selectedStep = 0;
          break;
      }
    }

    // default, change after profile loads if necessary
    this.currentStep = this.steps[0];

    // Load lookups from CRM
    var loadTitles$ = this.registrationFormService.titles$.pipe(
      tap((data) => (this.titles = data))
    );

    var loadPositions$ = this.registrationFormService.positions$.pipe(
      tap((data) => (this.positions = data))
    );

    // Load data from API into form (e.g. draft data)
    var loadFormData$ = this.registrationFormService.formData$.pipe(
      takeUntil(this.unsubscribe$),
      tap((formData) => {
        const form = this.form;
        console.log(`loadingFormData ${JSON.stringify(formData)}`);

        this.reloadFormData(form, formData);

        form
          .get("organisationDetails.businessDetails.hasNoAbn")
          .valueChanges.subscribe(() => {
            const hasNoAbn = <boolean>(
              form.get("organisationDetails.businessDetails.hasNoAbn").value
            );

            if (hasNoAbn) {
              form
                .get("organisationDetails.businessDetails.abn")
                .setValue(null);
            } else {
              form
                .get("organisationDetails.businessDetails.alternateBusinessNumber")
                .setValue(null);
              form
                .get("organisationDetails.businessDetails.alternateBusinessNumberDescription")
                .setValue(null);
            }
          });

        this.sortList(
          (<FormArray>form.get("owners.owners")).controls,
          "statusDescription",
          true
        );
        this.sortList(
          (<FormArray>form.get("lobbyists.lobbyists")).controls,
          "statusDescription",
          true
        );
        this.sortList(
          (<FormArray>form.get("clients.clients")).controls,
          "statusDescription",
          true
        );

        // TODO - sort this out
        //form.setValue(formData);

        this.updateStepsValidityStatus();

        // Configure for either new registration or update existing
        if (this.isDeregistered) {
          // review step only
          this.steps = this.steps.slice(7);
          this.currentStep = this.steps[0];
        } else if (
          this.profile.isInitialised &&
          !this.isNewApplication &&
          this.steps.length == 8
        ) {
          // drop intro and sole trader steps
          this.steps = this.steps.slice(2);
          this.currentStep = this.steps[selectedStep];
        } else {
          // set to last incomplete step
          var lastActiveTab = <string>(
            form.get("organisationDetails.lastActiveTab").value
          );
          if (lastActiveTab != null && lastActiveTab != "N/A") {
            var activeStep: IFormStep = this.steps.find(
              (s) => s.title == lastActiveTab
            );
            if (activeStep) {
              this.currentStep = activeStep;
            }
          }
        }

        var lobbyistValidators = [
          validateLobbyistEmailsAreUnique,
          validateAtLeastOneLobbyist,
        ];

        // Add stat dec validator to lobbyist step if this is June reporting period
        if (
          this.reportingPeriodStatus &&
          this.reportingPeriodStatus.isStatDecMandatory &&
          this.isInReportingPeriod
        ) {
          lobbyistValidators.push(validateAllLobbyistsHaveStatDecsAttached);
        }

        this.form.get("lobbyists").setValidators(lobbyistValidators);
        this.form.get("lobbyists").updateValueAndValidity();

        this.form.get("owners").setValidators(validateAtLeastOneOwner);
        this.form.get("owners").updateValueAndValidity();

        this.modalService.dismissAll();
      })
    );

    var publicFormData$ = this.registrationFormService.originalData$.pipe(
      takeUntil(this.unsubscribe$),
      tap((origFormData) => {
        if (origFormData.organisationDetails.id != null) {
          const origForm = this.originalFormData;
          this.reloadFormData(origForm, origFormData);
        }
      })
    );

    combineLatest(
      loadTitles$,
      loadPositions$,
      loadFormData$,
      publicFormData$,
      (titles, positions, data) => ({ titles, positions, data })
    )
      .pipe(
        tap((_) => {
          this.isInitialised = true;
        }),
        catchError((err, caught) => {
          this.failedToInitialise = true;
          console.error(err, caught);
          return Observable.of({});
        })
      )
      .subscribe();

    this.printService.printRequested$.subscribe((msg) => {
      this.print();
    });

    this.initOrganisationDetailsAbnLookup(
      this.form.get("organisationDetails.businessDetails")
    );

    this.logoutTimer = this.resetTimer();
  }

  ngAfterViewInit() {
    this.modalService.open(this.modalLoading, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
    catchError((err, caught) => caught)
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.onSaved.next();
    this.onSaved.complete();

    this.onSubstepChange.next();
    this.onSubstepChange.complete();

    this.cancelTimer.next(true);
  }

  resetTimer(prevTimer?: Subscription): Subscription {
    if (prevTimer != null) {
      this.cancelTimer.next(true);
      prevTimer.unsubscribe();
    }

    return timer(30 * 60 * 1000)
      .pipe(takeUntil(this.cancelTimer))
      .subscribe((x) => {
        this.modalService.open(this.modalLogout, {
          centered: true,
          backdrop: "static",
          keyboard: false,
        });
      }); // 30 minutes
  }

  buildOrganisationFormGroup(): FormGroup {
    return this.fb.group(
      {
        introduction: this.fb.group({}),
        fitsConfirmed: [null],
        soleTradership: this.fb.group({
          isSoleTrader: [null, [Validators.required]],
          hasChanged: [null],
        }),
        organisationDetails: this.fb.group({
          id: [null],
          businessDetails: this.fb.group(
            {
              abn: [null, [getValidAbnValidator(this.abnService)]],
              alternateBusinessNumberDescription: [null],
              alternateBusinessNumber: [null],
              hasNoAbn: [false],
              businessEntityName: [null, [Validators.required]],
              hasTradingName: [null, [Validators.required]],
              tradingName: [null],
            },
            {
              validators: [
                validateOrganisationDetailsTradingName,
                validateOrganisationDetailsAlternateBusinessNumberFields,
              ],
              updateOn: "change",
            }
          ),
          contactDetails: this.fb.group({
            primaryPhoneNumber: [null, [Validators.required]], // TODO: Telephone number validation
            secondaryPhoneNumber: [null], // TODO: Telephone number validation,
            website: [null],
          }),
          businessEntityPhysicalAddress: this.fb.group({
            addressLine1: [null, [Validators.required]],
            addressLine2: [null],
            addressLine3: [null],
            suburb: [null, [Validators.required]],
            state: [null, [Validators.required]],
            postcode: [null, [Validators.required]],
            country: [null, [Validators.required]],
          }),
          businessEntityPostalAddress: this.fb.group(
            {
              addressLine1: [null],
              addressLine2: [null],
              addressLine3: [null],
              suburb: [null],
              state: [null],
              postcode: [null],
              country: [null],
            },
            {
              validators: [validateOrganisationDetailsAddressFields],
              updateOn: "change",
            }
          ),
          postalAddressIsSameAsPhysicalAddress: [false, [Validators.required]],
          status: this.fb.group({
            status: [null],
            statusCode: [null],
          }),
          submissionReason: [null],
          previouslyApproved: [null],
          lastActiveTab: [null],
          hasChanged: [null],
          isSuspended: [null],
        }),
        responsibleOfficer: this.fb.group(this.responsibleOfficerTemplate),
        owners: this.fb.group({
          owners: this.fb.array([], Validators.required),
        }),
        lobbyists: this.fb.group(
          {
            hasNoLobs: [false, [Validators.required]],
            lobbyists: this.fb.array([]),
          },
          {
            validators: [validateAtLeastOneLobbyist],
            updateOn: "change",
          }
        ),
        clients: this.fb.group(
          {
            hasNoClients: [false, [Validators.required]],
            clients: this.fb.array([])
          },
          {
            validators: [validateHasNoClients],
            updateOn: "change",
          }
        ),
        responsibleOfficers: this.fb.group(
          {
            responsibleOfficers: this.fb.array([]),
          },
          {
            validators: [
              validateAtLeastOneResponsibleOfficer,
              validateAtLeastOneResponsibleOfficerReceivesNotifications,
            ],
          }
        ),
      },
      {
        validators: [validateAllLobbyistsHaveStatDecsAttached],
      }
    );
  }

  initOrganisationDetailsAbnLookup(formGroup: AbstractControl) {
    // Organisation details page - wire up interactivity for ABN field

    const abnChanges$ = formGroup.get("abn").valueChanges;
    const hasNoAbnChanges$ = formGroup.get("hasNoAbn").valueChanges;

    const updates$ = combineLatest(abnChanges$, hasNoAbnChanges$);

    updates$
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap(
          ([abn, hasNoAbn]): Observable<{
            lockFields: boolean;
            abnDetails?: IAbnResult;
          }> => {
            if (hasNoAbn == true) {
              // make fields editable
              return Observable.of({ lockFields: false });
            } else if (hasNoAbn !== true && this.abnService.isValidAbn(abn)) {
              // make fields locked and wait for lookup
              console.log("abn lookup start");
              return Observable.concat(
                Observable.of({ lockFields: true }),
                this.abnService.lookup(abn).pipe(
                  tap((result) => console.log("ABN lookup returned", result)),
                  map((result) => ({ lockFields: false, abnDetails: result })),
                  catchError((_) => {
                    console.error("Error in ABN lookup!");
                    return Observable.of({ lockFields: false });
                  })
                )
              );
            } else {
              // make fields editable
              return Observable.of({ lockFields: false });
            }
          }
        )
      )
      .subscribe((result) => {
        if (result.lockFields) {
          formGroup.get("businessEntityName").disable();
          formGroup.get("hasTradingName").disable();
          formGroup.get("tradingName").disable();
        } else {
          formGroup.get("businessEntityName").enable();
          formGroup.get("hasTradingName").enable();
          formGroup.get("tradingName").enable();
        }

        if (result.abnDetails != null) {
          formGroup
            .get("businessEntityName")
            .setValue(result.abnDetails.legalName);
          formGroup.get("tradingName").setValue(result.abnDetails.tradingName);

          const hasTradingName =
            result.abnDetails.tradingName != null &&
            result.abnDetails.tradingName != "";
          formGroup.get("hasTradingName").setValue(hasTradingName);
        }
      });

    formGroup.get("abn").updateValueAndValidity();
    formGroup.get("hasNoAbn").updateValueAndValidity();
  }

  navigateToDashboard() {
    this.router.navigateByUrl("/user/dashboard");
  }

  saveFormData(): Observable<{}> {
    if (this.isSaving) {
      return Observable.of({});
    }

    if (this.currentStep.canSave == false) {
      return Observable.of({});
    }

    if (this.saveNotNeeded) {
      // return Observable.of({});
    }

    this.isSaving = true;
    this.failedToSave = false;

    this.modalService.open(this.modalSaving, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });

    // flag changed records for API

    var checkDirty = (record: AbstractControl) => {
      if (record.dirty) {
        if (
          Object.keys((<FormGroup>record).controls)
            .filter((k) => k != "status" && k != "statusDescription")
            .some((k) => record.get(k).dirty)
        ) {
          record.get("hasChanged").setValue(true);

          if (record.get("id")) {
            // ensure new records have empty guid for id
            var id: string = record.get("id").value;
            if (id.indexOf(";") != -1) {
              record.get("id").setValue(this.emptyGuid);
            }
          }
        }
      }
    };

    // sole trader

    checkDirty(this.form.get("soleTradership"));

    // organisation

    checkDirty(this.form.get("organisationDetails"));

    // officers

    (<FormArray>(
      this.form.get("responsibleOfficers.responsibleOfficers")
    )).controls.forEach((o) => {
      // ensure enabled for save even if only officer
      o.get("sendNotifications").enable({ onlySelf: true });
      o.get("hasSeenTutorial").enable({ onlySelf: true });
      checkDirty(o);
    });

    // owners

    (<FormArray>this.form.get("owners.owners")).controls.forEach((o) =>
      checkDirty(o)
    );

    // lobbyists

    (<FormArray>this.form.get("lobbyists.lobbyists")).controls.forEach((l) =>
      checkDirty(l)
    );

    // clients

    (<FormArray>this.form.get("clients.clients")).controls.forEach((c) =>
      checkDirty(c)
    );

    // Get stat decs for upload
    const statDecs: IStatDecFile[] = [];
    const lobbyists = <FormArray>this.form.get("lobbyists.lobbyists");
    for (let i = 0; i < lobbyists.length; ++i) {
      const lobbyist = lobbyists.at(i);
      for (let document of lobbyist.get("lobbyistDetails.statDecUpload")
        .value) {
        statDecs.push({
          lobbyistIndex: i,
          blob: document,
          fileName: document.name,
        });
        lobbyist.get("hasChanged").setValue(true);
      }
    }

    let formData = this.form.value;
    this.form.disable();

    formData.currentWizardStep = this.currentStep.title;

    return this.registrationFormService.saveFormData(formData, statDecs).pipe(
      tap((orgData) => {
        this.modalService.dismissAll();

        const lobbyistsTemp = <FormArray>this.form.get("lobbyists.lobbyists");

        for (var i = lobbyistsTemp.length; i-- > 0; ) {
          const lobbyist = lobbyistsTemp.at(i);
          if (lobbyist.get("forDeletion").value == true) {
            lobbyistsTemp.removeAt(i);
          }
        }

        this.isSaving = false;

        // ensure IDs for new records are applied
        this.reloadFormData(this.form, orgData);

        this.form.enable();
        this.form.markAsPristine({ onlySelf: true });

        // After uploading all the stat decs, we mark them as uploaded and clear the file upload components
        // This way we don't re-upload the same file all the time
        const lobbyists = <FormArray>this.form.get("lobbyists.lobbyists");
        for (let i = 0; i < lobbyists.length; ++i) {
          const lobbyist = lobbyists.at(i);
          if (lobbyist.get("lobbyistDetails.statDecUpload").value.length > 0) {
            lobbyist.get("lobbyistDetails.statDecUpload").setValue([]);
            lobbyist
              .get("lobbyistDetails.statDecPreviouslyUploaded")
              .setValue(true);
          } else if (
            lobbyist.get("lobbyistDetails.statDecPreviouslyUploaded").value !==
            true
          ) {
            lobbyist
              .get("lobbyistDetails.statDecPreviouslyUploaded")
              .setValue(false);
          }
        }

        this.form.get("soleTradership.hasChanged").setValue(false);
        this.form.get("organisationDetails.hasChanged").setValue(false);
        (<FormArray>(
          this.form.get("responsibleOfficers.responsibleOfficers")
        )).controls.forEach((r) => {
          r.get("hasChanged").setValue(false);
        });
        (<FormArray>this.form.get("owners.owners")).controls.forEach((o) => {
          o.get("hasChanged").setValue(false);
        });
        (<FormArray>this.form.get("lobbyists.lobbyists")).controls.forEach(
          (l) => {
            l.get("hasChanged").setValue(false);
          }
        );
        (<FormArray>this.form.get("clients.clients")).controls.forEach((c) => {
          c.get("hasChanged").setValue(false);
        });

        this.toastService.show(
          "Your updates have been saved but not yet submitted.",
          { classname: "bg-warning text-dark", delay: 10000 }
        );

        this.resetTimer(this.logoutTimer);

        if (
          this.officersWithAccountTrouble &&
          this.officersWithAccountTrouble.length > 0
        ) {
          this.modalService.open(this.modalActivationError, {
            centered: true,
            backdrop: "static",
            keyboard: false,
          });
        }
      }),
      catchError((err) => {
        this.modalService.dismissAll();

        console.error(err);

        this.isSaving = false;
        this.form.enable();

        if (err.status == 403) {
          this.modalService.open(this.modalBlocked, {
            centered: true,
            backdrop: "static",
            keyboard: false,
          });
        } else {
          return Observable.throwError(err);
        }
      })
    );
  }

  public get switchFirstStep(): string {
    // template interpolation conditional are a bit limited
    // so pulled out this function to here.

    // existing orgs editing details do not have an introduction step
    // so we need this switch to modify where the greenTickStep appears
    return this.steps[0].title === RegistrationSteps.Introduction
      ? RegistrationSteps["Introduction"]
      : RegistrationSteps["Organisation details"];
  }

  public get officersWithAccountTrouble(): AbstractControl[] {
    return (<FormArray>(
      this.form.get("responsibleOfficers.responsibleOfficers")
    )).controls.filter((o) => {
      return (
        o.get("accountActivationStatus") &&
        o.get("accountActivationStatus").value
      );
    });
  }

  onWizardNavigateToStep(step: number) {
    this.checkValidity();

    if (step != -1 && !this.canNavigateToStep(step)) {
      return;
    }

    /*
     * Next step only permitted if
     *  - already on the Review & submit step, or
     *  - new application and navigating backwards, or
     *  - this step is valid, or
     *  - navigating forwards and all the steps between this step and destination are valid
     */

    var idxCurrentStep = this.steps.findIndex(
      (s) => s.title == this.currentStep.title
    );
    var navigatingBackward = idxCurrentStep > step;
    var allStepsComplete = this.steps
      .filter(
        (s) =>
          this.steps.indexOf(s) > idxCurrentStep && this.steps.indexOf(s) < step
      )
      .every((s) => s.complete);

    var nextStepAllowed =
      this.currentStep.title == "Review & submit" ||
      (this.isNewApplication && navigatingBackward) ||
      (this.checkValidity() && this.currentStep.form.valid);

    if (!navigatingBackward) {
      nextStepAllowed = nextStepAllowed && allStepsComplete;
    }

    var cancelNewRecord: Promise<boolean> = new Promise<boolean>(
      (resolve, reject) => {
        resolve(true);
      }
    );

    if (this.isNewRecord(this.currentForm)) {
      cancelNewRecord = this.modalService
        .open(this.modalCancel, { centered: true, backdrop: "static" })
        .result.then(
          () => {
            this.deleteNewRecord(this.currentForm);
            return true;
          },
          () => {
            return false; // don't save changes or navigate away
          }
        );
    }

    cancelNewRecord.then((proceeding) => {
      if (proceeding && nextStepAllowed) {
        this.form
          .get("organisationDetails.lastActiveTab")
          .setValue(step == -1 ? "Introduction" : this.steps[step].title);
        this.saveFormData()
          .pipe(
            tap(() => {
              if (step == -1) {
                this.navigateToDashboard();
              } else {
                this.stepsNavigate(step);
              }
            }),
            catchError((err) => {
              this.failedToSave = true;
              return Observable.of({});
            })
          )
          .subscribe();
      }
    });
  }

  // Mark fields as touched (causes validation messages to appear)

  checkValidity(): boolean {
    console.log(`DEBUGLOG: validating`);
    var isValid = true;

    if (this.currentSubstep == null) {
      this.currentStep.form.updateValueAndValidity();
      this.currentStep.form.markAllAsTouched();
      isValid = this.currentStep.form.valid;
    } else if (this.currentForm != null) {
      this.currentForm.updateValueAndValidity({ onlySelf: true });
      this.currentForm.markAllAsTouched();
      isValid = this.currentForm.valid;
    }

    this.showValidationSummary = !isValid;
    this.updateStepsValidityStatus();
    console.log(`DEBUGLOG: validated ${isValid}`);

    return isValid;
  }

  onSave() {
    this.form
      .get("organisationDetails.lastActiveTab")
      .setValue(this.currentStep.title);
    this.saveFormData()
      .pipe(
        catchError((err) => {
          this.failedToSave = true;
          return Observable.of({});
        })
      )
      .subscribe(() => {
        this.registrationFormService.selectRecord(this.currentForm.value);
        this.onSaved.next();
      });
  }

  onSaveAndClose() {
    this.form
      .get("organisationDetails.lastActiveTab")
      .setValue(this.currentStep.title);
    this.saveFormData()
      .pipe(
        tap(() => this.navigateToDashboard()),
        catchError((err) => {
          this.failedToSave = true;
          return Observable.of({});
        })
      )
      .subscribe();
  }

  onSaveAndReview() {
    this.checkValidity();

    if (this.currentStep.form.valid) {
      this.saveFormData()
        .pipe(
          tap(() => this.stepsNavigate(this.steps.length - 1)),
          catchError((err) => {
            console.log(`DEBUGLOG: no savo ${JSON.stringify(err)}`);
            this.failedToSave = true;
            return Observable.of({});
          })
        )
        .subscribe(() => (this.returnToReview = false));
    } else {
      // Form is invalid
      // Scroll to validation summary (TODO) and flash it

      // TODO: Alternatively, scroll to first error in form?
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  onFormSubmit() {
    if (
      this.canNavigateToStep(this.findStepForForm(this.currentStep.form) + 1)
    ) {
      this.onSaveAndContinue();
    } else if (
      !this.canNavigateToStep(this.findStepForForm(this.currentStep.form) + 1)
    ) {
      this.onFinaliseSubmission();
    } else {
      throw "Cannot submit form";
    }
  }

  onFinaliseSubmission() {
    this.checkValidity();

    if (this.currentStep.form.valid) {
      if (!this.isInReportingPeriod || !this.hasNoChanges) {
        // If not in reporting period or if changes have been made,
        // remove the 'confirm no changes' requirement
        const noChanges = this.declarationsForm.get("noChangesDeclaration");
        noChanges.setValidators(null);
        noChanges.updateValueAndValidity();
      }

      this.modalReference = this.modalService.open(this.modalDeclarations, {
        centered: true,
        backdrop: "static",
        keyboard: false,
      });
    } else {
      // Form is invalid
      // Scroll to validation summary (TODO) and flash it

      // TODO: Alternatively, scroll to first error in form?
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  get declarationsFormControls() {
    return this.declarationsForm.controls;
  }

  declarationsSubmitted = false;
  declarationsForm = this.fb.group({
    informationIsCorrectDeclaration: [null, [Validators.requiredTrue]],
    allLobbyistsHaveReadCodeDeclaration: [null, [Validators.requiredTrue]],
    noChangesDeclaration: [ null, [Validators.requiredTrue]],
    fitsDeclaration: [null, [Validators.requiredTrue]],
  });

  onSubmit() {
    this.declarationsSubmitted = true;

    if (this.declarationsForm.invalid) {
      return;
    }

    this.modalReference.close("Foo");

    const newRegistrationMessage =
      "Thank you for submitting your application to the Australia Government Register of Lobbyists. You will be notified once your registration has been published.";
    const existingRegistrationMessage =
      "Thank you for submitting your changes. You will be notified once your changes have been actioned.";

    const reportingPeriodMessage =
      "Thank you for participating in the mandatory reporting period. You will be notified once your changes have been actioned or contacted if we require further information.";

    this.isSaving = true;
    this.failedToSave = false;

    // do a pre-submit check of "previous position other" values
    // to avoid polluting database if user flicks between 'yes' and 'no'

    this.checkPreviousPositionOther();

    let formData = this.form.value;
    formData.declarations = this.declarationsForm.value;

    //this.form.disable();
    this.modalService.open(this.modalSaving, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });

    // Submit fitsConfirmed at root object
    if (this.declarationsForm && this.declarationsForm.get("fitsDeclaration").value) {
      const fitsVal = this.declarationsForm.get("fitsDeclaration").value;
      formData.fitsConfirmed = fitsVal;
    }

    this.registrationFormService
      .finaliseApplicationForRegistration(formData)
      .pipe(
        tap(() => {
          this.modalService.dismissAll();
          this.isSaving = false;
          this.form.enable();

          //TODO: set message to the new reporting period option
          let message;

          if (this.isInReportingPeriod) {
            message = reportingPeriodMessage;
          } else {
            message = this.profile.isOrganisationReviewed
              ? existingRegistrationMessage
              : newRegistrationMessage;
          }

          // Store success message for dashboard to show
          this.store.dispatch({
            type: fromDashboardNotifications.SUCCESS_NOTIFICATION,
            payload: {
              message,
            },
          });

          // Navigate user
          this.navigateToDashboard();
        }),
        catchError((err) => {
          this.modalService.dismissAll();
          this.failedToSave = true;
          return Observable.of({});
        })
      )
      .subscribe();
  }

  onSaveAndContinue() {
    if (this.checkValidity()) {
      this.form
        .get("organisationDetails.lastActiveTab")
        .setValue(this.steps[this.stepsGetCurrent() + 1].title);

      this.saveFormData()
        .pipe(
          tap(() => this.stepsNavigate(this.stepsGetCurrent() + 1)),
          catchError((err) => {
            this.failedToSave = true;
            return Observable.of({});
          })
        )
        .subscribe();
    } else {
      // Form is invalid
      // Scroll to validation summary (TODO) and flash it

      // TODO: Alternatively, scroll to first error in form?
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  checkPreviousPositionOther() {
    const lobbyists = <FormArray>this.form.get("lobbyists.lobbyists");

    lobbyists.controls.forEach((lob) => {
      const ppo = lob.get("formerDetails.previousPositionOther");

      if (
        lob.get("formerDetails.isFormerRepresentative").value &&
        lob.get("formerDetails.previousPosition").value == "Other"
      ) {
        ppo.setValidators(Validators.required);
      } else {
        ppo.setValue(null);
      }

      const requiredLevels = [
        "EmployedorengagedbyaMinisteroraParliamentarySecretaryundertheMembersofParliament_StaffAct1984",
        "EmployedunderthePublicServiceAct1999",
        "ContractorconsultantforanagencywhosestaffareemployedunderthePublicServiceAct1999",
        "MemberoftheAustralianDefenceForce",
      ];
      const selectedPosition = lob.get("formerDetails.previousPosition").value;

      const requiresLevel = requiredLevels.some(
        (opt) => opt === selectedPosition
      );

      if (requiresLevel) {
        lob
          .get("formerDetails.previousPositionLevel")
          .setValidators(Validators.required);
      } else {
        lob.get("formerDetails.previousPositionLevel").setValue(null);
        lob.get("formerDetails.previousPositionLevel").clearValidators();
      }
    });
  }

  updateStepsValidityStatus() {
    for (let step of this.steps) {
      step.complete = step.form.valid;
    }
  }

  _recordToDelete: string;
  get recordToDelete(): string {
    return this._recordToDelete;
  }

  set recordToDelete(val: string) {
    this._recordToDelete = val;
  }

  private emptyGuid = "00000000-0000-0000-0000-000000000000";

  ownersAdd = (form: FormGroup = this.form, generateId: boolean = true) => {
    const owners = <FormArray>form.get("owners.owners");

    const ownerForm: FormGroup = this.fb.group(this.ownersOwnerTemplate, {
      updateOn: "change",
    });
    const detailForm = <FormGroup>ownerForm.get("ownerDetails");
    detailForm.setValidators(validateOwner);
    detailForm.updateValueAndValidity();

    if (generateId) {
      // ensure unique id so multiple new records works
      ownerForm.get("id").setValue(this.emptyGuid + ";" + (owners.length + 1));
      ownerForm.markAsDirty();
    }

    ownerForm.get("description").setValue("New owner");

    const onNameChanged = () => {
      var desc = "";
      if (ownerForm.get("ownerType.isBusiness").value == true) {
        desc = ownerForm.get("ownerDetails.businessName").value;
      } else {
        desc = (
          ownerForm.get("ownerDetails.firstName").value +
          " " +
          ownerForm.get("ownerDetails.lastName").value
        ).replace("null", "");
      }
      ownerForm.get("description").setValue(desc);
    };

    ownerForm
      .get("ownerDetails.businessName")
      .valueChanges.subscribe(onNameChanged);
    ownerForm
      .get("ownerDetails.firstName")
      .valueChanges.subscribe(onNameChanged);
    ownerForm
      .get("ownerDetails.lastName")
      .valueChanges.subscribe(onNameChanged);

    owners.push(ownerForm);
  };

  ownersRemove = (ownerId: string) => {
    const owners = <FormArray>this.form.get("owners.owners");

    this.recordRemove(owners, ownerId).then((removed) => {});
  };

  modalReference: any;
  closeResult: string;
  deregister: boolean;
  action = "";
  deregistrationReasons = [
    {
      id: "Ceasedemploymentwiththeorganisation",
      text: "Ceased employment with the organisation",
    },
    { id: "Extendedleave", text: "Extended leave" },
    {
      id: "Nolongerparticipatinginlobbyingactivity",
      text: "No longer participating in lobbying activity",
    },
    { id: "Other", text: "Other" },
  ];

  public activeLobbyist = {
    id: "",
    status: "",
    previouslyApproved: false,
    name: "",
    email: "",
    deregistrationReason: "",
    deregistrationDescription: "",
  };

  lobbyistsAdd = (form: FormGroup = this.form, generateId: boolean = true) => {
    const lobbyists = <FormArray>form.get("lobbyists.lobbyists");
    const lobbyistForm: FormGroup = this.fb.group(
      this.getLobbyistsLobbyistTemplate(),
      { updateOn: "change" }
    );

    const formerDetails = lobbyistForm.get("formerDetails");
    formerDetails.setValidators(validateLobbyist);
    formerDetails.updateValueAndValidity();

    if (generateId) {
      lobbyistForm
        .get("id")
        .setValue(this.emptyGuid + ";" + (lobbyists.length + 1));
      lobbyistForm.markAsDirty();
    }
    lobbyistForm.get("description").setValue("New lobbyist");

    const onNameChanged = () => {
      lobbyistForm
        .get("description")
        .setValue(
          (
            lobbyistForm.get("lobbyistDetails.firstName").value +
            " " +
            lobbyistForm.get("lobbyistDetails.lastName").value
          ).replace("null", "")
        );
    };

    lobbyistForm
      .get("lobbyistDetails.firstName")
      .valueChanges.subscribe(onNameChanged);
    lobbyistForm
      .get("lobbyistDetails.lastName")
      .valueChanges.subscribe(onNameChanged);

    lobbyistForm.get("lobbyistDetails").setValidators(dateWitnessedValidations);
    lobbyistForm.get("lobbyistDetails").updateValueAndValidity();

    lobbyistForm
      .get("lobbyistDetails.ngbDateWitnessed")
      .valueChanges.subscribe(() => {
        const ncd: NgbDateStruct = lobbyistForm.get(
          "lobbyistDetails.ngbDateWitnessed"
        ).value;
        if (ncd != null) {
          var mcd = moment(
            ncd.day + "/" + ncd.month + "/" + ncd.year,
            "DD/MM/YYYY"
          );
          lobbyistForm
            .get("lobbyistDetails.dateWitnessed")
            .setValue(mcd.toDate());
        }
        lobbyistForm.get("lobbyistDetails").updateValueAndValidity();
      });

    lobbyistForm
      .get("formerDetails.ngbCessationDate")
      .valueChanges.subscribe(() => {
        const ncd: NgbDateStruct = lobbyistForm.get(
          "formerDetails.ngbCessationDate"
        ).value;
        if (ncd != null) {
          var mcd = moment(
            ncd.day + "/" + ncd.month + "/" + ncd.year,
            "DD/MM/YY"
          );
          lobbyistForm
            .get("formerDetails.cessationDate")
            .setValue(mcd.toDate());
        }
      });

    var validatePpo = () => {
      const ppo = lobbyistForm.get("formerDetails.previousPositionOther");
      ppo.clearValidators();
      if (
        lobbyistForm.get("formerDetails.isFormerRepresentative").value &&
        lobbyistForm.get("formerDetails.previousPosition").value == "Other"
      ) {
        ppo.setValidators(Validators.required);
      } else {
        //ppo.setValue(null);
      }
      ppo.updateValueAndValidity();
      validatePPL();
    };

    var validatePPL = () => {
      const requiredLevels = [
        "EmployedorengagedbyaMinisteroraParliamentarySecretaryundertheMembersofParliament_StaffAct1984",
        "EmployedunderthePublicServiceAct1999",
        "ContractorconsultantforanagencywhosestaffareemployedunderthePublicServiceAct1999",
        "MemberoftheAustralianDefenceForce",
      ];
      const selectedPosition = lobbyistForm.get(
        "formerDetails.previousPosition"
      ).value;

      const requiresLevel = requiredLevels.some(
        (opt) => opt === selectedPosition
      );
      const ppl = lobbyistForm.get("formerDetails.previousPositionLevel");
      // idiot
      // ppl.setValue(null);
      ppl.clearValidators();

      if (requiresLevel) {
        ppl.setValidators(Validators.required);
      } else {
        ppl.clearValidators();
      }
      ppl.updateValueAndValidity();
    };

    lobbyistForm
      .get("formerDetails.isFormerRepresentative")
      .valueChanges.subscribe(() => {
        const isFormerRepresentative = <Boolean>(
          lobbyistForm.get("formerDetails.isFormerRepresentative").value
        );
        const ncd = lobbyistForm.get("formerDetails.ngbCessationDate");
        ncd.clearValidators();
        if (isFormerRepresentative) {
          ncd.setValidators(Validators.required);
        } else {
          ncd.setValue(null);
        }
        ncd.updateValueAndValidity();
        const pp = lobbyistForm.get("formerDetails.previousPosition");
        pp.clearValidators();
        if (isFormerRepresentative) {
          pp.setValidators(Validators.required);
        } else {
          pp.setValue(null);
          lobbyistForm
            .get("formerDetails.previousPositionLevel")
            .setValue(null);
        }
        pp.updateValueAndValidity();

        validatePpo();
      });

    lobbyistForm
      .get("formerDetails.previousPosition")
      .valueChanges.subscribe(validatePpo);

    lobbyists.push(lobbyistForm);
  };

  private lobbyistDel: Subject<string> = new Subject<string>();

  private FOR_DEREGISTRATION = 981660001; // oh no, another hard-coded constant
  private FOR_REMOVAL = 981660002;

  public lobbyistsRemove = (lobbyistId: string) => {
    const lobbyists = <FormArray>this.form.get("lobbyists.lobbyists");
    const lobbyist = lobbyists.controls.find(
      (l) => l.get("id").value == lobbyistId
    );

    this.activeLobbyist.id = lobbyist.get("id").value;
    this.activeLobbyist.name = lobbyist.get("description").value;
    this.activeLobbyist.email = lobbyist.get("lobbyistDetails.email").value;
    this.activeLobbyist.status = lobbyist.get("status").value;
    this.activeLobbyist.previouslyApproved = lobbyist.get(
      "previouslyApproved"
    ).value;
    this.activeLobbyist.deregistrationReason = "";
    this.activeLobbyist.deregistrationDescription = "";

    if (this.activeLobbyist.previouslyApproved == true) {
      this.deregister = true;
      this.action = "deregister";
    } else {
      this.deregister = false;
      this.action = "delete";
    }

    this.modalReference = this.modalService.open(this.modalDeregister, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
  };

  removeValidators(frm: FormGroup) {
    frm.clearValidators();
    frm.clearAsyncValidators();
    frm.updateValueAndValidity();

    Object.keys(frm.controls).forEach((c) => {
      frm.controls[c].clearValidators();
      frm.controls[c].updateValueAndValidity();
    });
  }

  onlobbyistsRemove() {
    const lobbyists = <FormArray>this.form.get("lobbyists.lobbyists");

    const lobbyist = <FormGroup>(
      lobbyists.controls.find(
        (l) => l.get("id").value == this.activeLobbyist.id
      )
    );

    if (this.action == "deregister") {
      lobbyist.get("submissionReason").setValue(this.FOR_DEREGISTRATION);
      lobbyist
        .get("deregistrationReason")
        .setValue(this.activeLobbyist.deregistrationReason);
      if (this.activeLobbyist.deregistrationReason == "Other") {
        lobbyist
          .get("deregistrationDescription")
          .setValue(this.activeLobbyist.deregistrationDescription);
      }
      lobbyist.get("lobbyistDetails.email").setValue(this.activeLobbyist.email);
    }

    if (lobbyist.get("id").value.startsWith(this.emptyGuid)) {
      lobbyists.removeAt(
        lobbyists.controls.findIndex(
          (l) => l.get("id").value == lobbyist.get("id").value
        )
      );
    } else {
      lobbyists.markAsDirty(); // make sure this gets saved
      lobbyist.get("forDeletion").setValue(true);

      this.removeValidators(lobbyist);

      ["lobbyistDetails", "formerDetails"].forEach((fg) => {
        this.removeValidators(<FormGroup>lobbyist.get(fg));
      });
    }
    this.setHasNoLobbyists();

    this.modalReference.close("Foo");
  }

  clientsAdd = (form: FormGroup = this.form, generateId: boolean = true) => {
    const clients = <FormArray>form.get("clients.clients");
    const newGroup = this.fb.group(this.clientsClientTemplate, {
      validators: [
        validateClient,
        validateOrganisationDetailsAlternateBusinessNumberFields,
      ],
      updateOn: "change",
    });

    if (generateId) {
      newGroup.get("id").setValue(this.emptyGuid + ";" + (clients.length + 1));
      newGroup.markAsDirty();
    }

    newGroup.get("businessEntityName").valueChanges.subscribe(() => {
      newGroup
        .get("description")
        .setValue(newGroup.get("businessEntityName").value);
    });

    newGroup.get("hasNoAbn").valueChanges.subscribe(() => {
      const hasNoAbn = <boolean>newGroup.get("hasNoAbn").value;

      if (hasNoAbn) {
        newGroup.get("abn").setValue(null);
      } else {
        newGroup.get("alternateBusinessNumber").setValue(null);
        newGroup.get("alternateBusinessNumberDescription").setValue(null);
      }
    });

    clients.push(newGroup);
    this.initOrganisationDetailsAbnLookup(newGroup);
  };

  clientsRemove = (clientId: string) => {
    const clients = <FormArray>this.form.get("clients.clients");
    this.recordRemove(clients, clientId).then((removed) => {
      if (removed) {
        this.setHasNoClients();
      }
    });
  };

  officersAdd = (form: FormGroup = this.form, generateId: boolean = true) => {
    const officers = <FormArray>(
      form.get("responsibleOfficers.responsibleOfficers")
    );
    const newGroup = this.fb.group(this.responsibleOfficerTemplate);

    if (generateId) {
      newGroup.get("id").setValue(this.emptyGuid + ";" + (officers.length + 1));
      newGroup.markAsDirty();
    }

    var nameChange = () => {
      newGroup
        .get("description")
        .setValue(
          newGroup.get("firstName").value + " " + newGroup.get("lastName").value
        );
    };

    newGroup.get("firstName").valueChanges.subscribe(nameChange);
    newGroup.get("lastName").valueChanges.subscribe(nameChange);

    newGroup
      .get("email")
      .setAsyncValidators(
        getUniqueResponsibleOfficerEmailValidator(this.officerService)
      );
    newGroup.get("email").updateValueAndValidity();

    officers.push(newGroup);
  };

  officersRemove = (officerId: string) => {
    const officers = <FormArray>(
      this.form.get("responsibleOfficers.responsibleOfficers")
    );
    this.recordRemove(officers, officerId).then((removed) => {});
  };

  recordRemove(
    recordCollection: FormArray,
    idToRemove: string
  ): Promise<boolean> {
    const record = <FormGroup>(
      recordCollection.controls.find((c) => c.get("id").value == idToRemove)
    );

    if (record.get("previouslyApproved").value == true) {
      this.deregister = true;
      this.action = "deregister";
    } else {
      this.deregister = false;
      this.action = "delete";
    }

    this.recordToDelete = record.get("description").value;

    var result = this.modalService
      .open(this.modalDelete, { centered: true, backdrop: "static" })
      .result.then(
        () => {
          if (this.action == "deregister") {
            record.get("submissionReason").setValue(this.FOR_DEREGISTRATION);
          } else if (this.action == "delete") {
            record.get("submissionReason").setValue(this.FOR_REMOVAL);
          }

          if (record.get("id").value.startsWith(this.emptyGuid)) {
            recordCollection.removeAt(
              recordCollection.controls.findIndex(
                (l) => l.get("id").value == record.get("id").value
              )
            );
          } else {
            record.markAsDirty();
            recordCollection.markAsDirty(); // make sure this gets saved
            record.get("forDeletion").setValue(true);

            this.removeValidators(record);

            if (record.get("ownerDetails")) {
              ["ownerDetails", "ownerType"].forEach((fg) => {
                this.removeValidators(<FormGroup>record.get(fg));
              });
            }
          }

          return true;
        },
        () => {
          return false;
        }
      ); // do nothing on 'take me back'

    return result;
  }

  soleTraderChange = (isSoleTrader: boolean) => {
    const lobbyists = <FormArray>this.form.get("lobbyists.lobbyists");
    const owners = <FormArray>this.form.get("owners.owners");

    if (isSoleTrader) {
      // Fill in lobbyist and owner records with copy of profile details
      var officer = <FormGroup>this.form.get("responsibleOfficer");

      var newLobbyist = this.getLobbyistsLobbyistTemplate();
      newLobbyist.lobbyistDetails.get("firstName").setValue(officer.get('firstName').value);
      newLobbyist.lobbyistDetails.get("lastName").setValue(officer.get('lastName').value);
      newLobbyist.lobbyistDetails.get("email").setValue(officer.get('email').value);
      newLobbyist.status = "Draft";
      newLobbyist.statusDescription = "Not yet submitted";
      newLobbyist.description = [officer.get('firstName').value + ' ' + officer.get('lastName').value];
      newLobbyist.hasChanged = [true];
      newLobbyist.forDeletion = false;
      newLobbyist.previouslyApproved = false;

      var newOwner = this.ownersOwnerTemplate;
      newOwner.ownerType.get("isBusiness").setValue(false);
      newOwner.description = this.fb.control(officer.get("firstName").value + " " + officer.get("lastName").value);
      newOwner.ownerDetails = this.fb.group({
        firstName: officer.get("firstName").value,
        lastName: officer.get("lastName").value,
        email: [officer.get("email").value, Validators.email],
        businessName: null,
        hasChanged: true
      });

      newOwner.hasChanged = this.fb.control(false);

      const lb = this.fb.group(newLobbyist, {
        updateOn: "change",
      });

      lb.get("hasChanged").setValue(true);

      lb.get("formerDetails").setValidators(validateLobbyist);

      lb.get("formerDetails.ngbCessationDate").valueChanges.subscribe(() => {
        const ncd: NgbDateStruct = lb.get("formerDetails.ngbCessationDate")
          .value;
        const cd: Date = new Date();
        if (ncd != null) {
          cd.setDate(ncd.day);
          cd.setMonth(ncd.month - 1);
          cd.setFullYear(ncd.year);
          lb.get("formerDetails.cessationDate").setValue(cd);
        }
      });

      lb.get("lobbyistDetails.ngbDateWitnessed").valueChanges.subscribe(() => {
        const ndw: NgbDateStruct = lb.get("lobbyistDetails.ngbDateWitnessed")
          .value;

        const cd: Date = new Date();
        if (ndw != null) {
          cd.setDate(ndw.day);
          cd.setMonth(ndw.month - 1);
          cd.setFullYear(ndw.year);
          lb.get("lobbyistDetails.dateWitnessed").setValue(cd);
        }
      });

      lb.get("lobbyistDetails.statDecUpload").valueChanges.subscribe(() => {
        const statDecUploaded = <Boolean>(
          (lb.get("lobbyistDetails.statDecUpload").value.length > 0)
        );
        lb.get("lobbyistDetails.ngbDateWitnessed").clearValidators();
        if (statDecUploaded) {
          lb.get("lobbyistDetails.ngbDateWitnessed").setValidators(
            Validators.required
          );
        }

        lb.get("lobbyistDetails.ngbDateWitnessed").updateValueAndValidity();
      });

      lb.get("formerDetails.isFormerRepresentative").valueChanges.subscribe(
        () => {
          const isFormerRepresentative = <Boolean>(
            lb.get("formerDetails.isFormerRepresentative").value
          );
          lb.get("formerDetails.ngbCessationDate").clearValidators();
          if (isFormerRepresentative) {
            lb.get("formerDetails.ngbCessationDate").setValidators(
              Validators.required
            );
          }
          lb.get("formerDetails.ngbCessationDate").updateValueAndValidity();
        }
      );

      lb.get("formerDetails.previousPosition").valueChanges.subscribe(() => {
        const prevPosition = lb.get("formerDetails.previousPosition").value;
        switch (prevPosition) {
          case "EmployedorengagedbyaMinisteroraParliamentarySecretaryundertheMembersofParliament_StaffAct1984":
          case "EmployedunderthePublicServiceAct1999":
          case "ContractorconsultantforanagencywhosestaffareemployedunderthePublicServiceAct1999":
          case "MemberoftheAustralianDefenceForce":
            lb.get("formerDetails.previousPositionLevel").setValidators(
              Validators.required
            );
            break;
          default:
            lb.get("formerDetails.previousPositionLevel").clearValidators();
            lb.get("formerDetails.previousPositionLevel").setValue(null);
            break;
        }
        lb.get("formerDetails.previousPositionLevel").updateValueAndValidity();
      });

      lobbyists.push(lb);

      const ownerForm = this.fb.group(newOwner, { updateOn: "change" });
      const detailForm = <FormGroup>ownerForm.get("ownerDetails");
      detailForm.setValidators(validateOwner);
      detailForm.updateValueAndValidity();

      owners.push(ownerForm);
    } else if (lobbyists.length == 1 && owners.length == 1) {
      // Remove lobbyist and owner records, will fill in later in wizard
      lobbyists.clear();
      owners.clear();
    }
  };

  navigateToStep = (form: FormGroup) => {
    this.stepsNavigate(this.findStepForForm(form));
  };

  navigateToStepAndReturn = (form: FormGroup) => {
    this.returnToReview = true;
    this.stepsNavigate(this.findStepForForm(form));
  };

  findStepForForm(form: FormGroup): number {
    for (let i = 0; i < this.steps.length; ++i) {
      const step = this.steps[i];
      if (step.form == form) {
        return i;
      }
    }

    return 0;
  }

  // Is user registering for the first time, or updating an existing record?
  isUpdatingRegistration() {
    return this.profile.isInitialised && this.profile.isOrganisationReviewed;
  }

  canNavigateToStep(step: number) {
    if (this.findStepForForm(this.currentStep.form) == step) {
      return false;
    }

    /*
    if (this.isSaving) {
      return false;
    }
    */

    if (!this.isInitialised) {
      return false;
    }

    if (step < 0) {
      return false;
    }

    if (step >= this.steps.length) {
      return false;
    }

    // TODO: Validate the step we are navigating to (i.e. are the previous steps all validated and completed?)

    return true;
  }

  stepsNavigate(step: number) {
    if (step < 0) {
      step = 0;
    }

    if (step >= this.steps.length) {
      step = this.steps.length - 1;
    }

    if (!this.canNavigateToStep(step)) {
      return;
    }

    this.showValidationSummary = false;

    this.currentStep = this.steps[step];

    setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }));
  }

  stepsGetCurrent() {
    return this.steps.indexOf(this.currentStep);
  }

  updateControlAndParentsValidity(control: AbstractControl) {
    control.updateValueAndValidity();
    if (control.parent) {
      this.updateControlAndParentsValidity(control.parent);
    }
  }

  print = () => {
    this.isPrinting = true;
    setTimeout(() => window.print());
    setTimeout(() => (this.isPrinting = false));
  };

  download(event) {
    event.preventDefault();

    this.contentService
      .initDocumentDownload("Statutory Declaration - Lobbyist Register", "docx")
      .subscribe((blob) =>
        FileSaver.saveAs(blob, "Statutory Declaration - Lobbyist Register.docx")
      );
  }

  onSubstepClick(event, substep: IFormSubstep) {
    this.checkValidity();

    // Don't allow direct navigation to
    // owner, lobbyist, officer or client substeps
    var blocked = [
      RegistrationSubsteps["Owner type"],
      RegistrationSubsteps["Add owner/s"],
      RegistrationSubsteps["Add lobbyist/s"],
      RegistrationSubsteps["Former details"],
      RegistrationSubsteps["Add client/s"],
      RegistrationSubsteps["Add officer/s"],
    ];
    if (blocked.indexOf(substep.name as RegistrationSubsteps) != -1) {
      return;
    }

    /*
     * Next substep only permitted if:
     *  - new application and navigating backwards, or
     *  - this substep is valid, or
     *  - navigating forwards and all the substeps between this substep and destination are valid
     */

    var idxCurrentSubstep = this.currentStep.substeps.indexOf(
      this.currentSubstep
    );
    var idxStep = this.currentStep.substeps.indexOf(substep);

    var navigatingBackward = idxCurrentSubstep > idxStep;
    var allStepsComplete = this.currentStep.substeps
      .filter(
        (s) =>
          this.currentStep.substeps.indexOf(s) > idxCurrentSubstep &&
          this.currentStep.substeps.indexOf(s) < idxStep
      )
      .every((s) => !s.form || s.form.valid);
    // console.log(`DEBUGLOG: navigatingBackward ${navigatingBackward}`);
    // console.log(`DEBUGLOG: allStepsComplete ${allStepsComplete}`);

    var nextSubstepAllowed =
      (this.isNewApplication && navigatingBackward) ||
      (this.checkValidity() &&
        (!this.currentSubstep.form || this.currentSubstep.form.valid));
    if (!navigatingBackward) {
      nextSubstepAllowed = nextSubstepAllowed && allStepsComplete;
    }
    // console.log(`DEBUGLOG: nextSubstepAllowed ${nextSubstepAllowed}`);

    if (nextSubstepAllowed) {
      event.preventDefault();
      this.setSubstep(substep);
    }
  }

  goToNextSubstep() {
    this.checkValidity();
    if (this.currentForm == null || this.currentForm.valid) {
      var idxSubstep = this.currentStep.substeps.indexOf(
        this.currentStep.substeps.find(
          (s) => s.name == this.currentSubstep.name
        )
      );
      var substep = this.currentStep.substeps[idxSubstep + 1];
      this.setSubstep(substep);
      this.registrationFormService.selectRecord(this.currentForm.value);
    }
  }

  isNewRecord(form: AbstractControl): boolean {
    var id = form.get("id");
    if (id == null && form.parent != null) {
      id = form.parent.get("id");
    }

    if (id != null) {
      return id.value.startsWith(this.emptyGuid);
    } else {
      return false;
    }
  }

  goToPrevSubstep() {
    var isNewRecord = this.isNewRecord(this.currentForm);
    var idxSubstep = this.currentStep.substeps.indexOf(
      this.currentStep.substeps.find((s) => s.name == this.currentSubstep.name)
    );

    if (isNewRecord && idxSubstep == 1) {
      this.modalService
        .open(this.modalCancel, { centered: true, backdrop: "static" })
        .result.then(
          () => {
            this.deleteNewRecord(this.currentForm);
          },
          () => {}
        ); // do nothing on 'take me back'
    } else if (
      isNewRecord ||
      this.isNewApplication ||
      this.currentForm == null ||
      this.checkValidity()
    ) {
      this.setSubstep(this.currentStep.substeps[idxSubstep - 1]);
      this.registrationFormService.selectRecord(this.currentForm.value);
    }
  }

  setSubstepByName(substep: string) {
    var newSubstep = this.currentStep.substeps.find(
      (s) => s.name.toString() == substep
    );
    this.setSubstep(newSubstep);
  }

  setSubstep(substep: IFormSubstep) {
    this.currentSubstep = substep;
    this.onSubstepChange.next(substep.name.toString());
  }

  get moreSubsteps(): boolean {
    return (
      this.currentStep.substeps.length > 0 &&
      this.currentStep.substeps.indexOf(
        this.currentStep.substeps.find(
          (s) => s.name == this.currentSubstep.name
        )
      ) <
        this.currentStep.substeps.length - 1 &&
      this.currentSubstep.name.toString().indexOf("list") == -1
    ); // no next button for list
  }

  isListOrOverviewSubstep(substep: IFormSubstep): boolean {
    const name = substep.name.toString();
    return name.indexOf("list") != -1 || name.indexOf("overview") != -1;
  }

  isSubstepSelectable(substep: IFormSubstep): boolean {
    return (
      this.isListOrOverviewSubstep(substep) ||
      this.currentStep.title == "Organisation details"
    );
  }

  onChildSubstepChanged(substep: IFormSubstep) {
    this.setSubstep(substep);
    this.registrationFormService.selectRecord(substep.form.value);
  }

  onReviewHasNoChanges(hasNoChanges) {
    this.hasNoChanges = hasNoChanges;
  }

  goBack() {
    if (
      this.currentStep.substeps.findIndex(
        (s) => s.name == this.currentSubstep.name
      ) >= 1
    ) {
      // special case for overview screen (last step)
      if (
        ["Owners", "Lobbyists", "Clients", "Responsible officers"].indexOf(
          this.currentStep.title
        ) != -1 &&
        this.currentStep.substeps.indexOf(this.currentSubstep) ===
          this.currentStep.substeps.length - 1
      ) {
        var nextSubstep = "";
        switch (this.currentSubstep.name) {
          case RegistrationSubsteps["Owners overview"]:
            nextSubstep = RegistrationSubsteps["Owners list"];
            break;
          case RegistrationSubsteps["Lobbyists overview"]:
            nextSubstep = RegistrationSubsteps["Lobbyists list"];
            break;
          case RegistrationSubsteps["Clients overview"]:
            nextSubstep = RegistrationSubsteps["Foreign Influence Transparency Scheme Obligation"];
            break;
          case RegistrationSubsteps["Officers overview"]:
            nextSubstep = RegistrationSubsteps["Officers list"];
            break;
        }

        this.setSubstepByName(nextSubstep);
      } else {
        this.goToPrevSubstep();
      }
    } else {
      this.onWizardNavigateToStep(
        this.findStepForForm(this.currentStep.form) - 1
      );
    }
  }

  goBackToManageYourRegistration() {
    if (this.currentStep.title == "Review & submit" || this.checkValidity()) {
      this.form
        .get("organisationDetails.lastActiveTab")
        .setValue(this.currentStep.title);
      this.saveFormData()
        .pipe(
          tap(() => this.navigateToDashboard()),
          catchError((err) => {
            this.failedToSave = true;
            return Observable.of({});
          })
        )
        .subscribe();
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  sortList(list: AbstractControl[], column: string, isAsc: boolean) {
    if (column == "statusDescription") {
      list.sort((a, b) => {
        var sortOrder = {
          "Approval rejected": 1,
          "Removal rejected": 2,
          "Not yet submitted": 3,
          "Submitted for review": 4,
          "Submitted for removal": 5,
          Approved: 6,
        };

        var s1: string;
        var s2: string;

        if (isAsc) {
          s1 = <string>a.get("statusDescription").value;
          s2 = <string>b.get("statusDescription").value;
        } else {
          s1 = <string>b.get("statusDescription").value;
          s2 = <string>a.get("statusDescription").value;
        }

        var o1 = sortOrder[s1];
        var o2 = sortOrder[s2];

        if (o1 < o2) {
          return -1;
        }

        if (o1 > o2) {
          return 1;
        }

        return 0;
      });
    } else {
      list.sort((a, b) => {
        var s1: string;
        var s2: string;

        if (isAsc) {
          s1 = <string>a.get(column).value;
          s2 = <string>b.get(column).value;
        } else {
          s1 = <string>b.get(column).value;
          s2 = <string>a.get(column).value;
        }

        if (s1 < s2) {
          return -1;
        }

        if (s1 > s2) {
          return 1;
        }

        return 0;
      });
    }
  }

  setHasNoClients() {
    var clientTotal = (<FormArray>(
      this.form.get("clients.clients")
    )).controls.filter((client) => {
      return (
        client.get("forDeletion").value !== true &&
        client.get("submissionReason").value !== this.FOR_DEREGISTRATION
      );
    }).length;

    if (clientTotal == 0) {
      this.form.get("clients.hasNoClients").enable();
    } else {
      this.form.get("clients.hasNoClients").patchValue(false);
      this.form.get("clients.hasNoClients").disable();
    }
  }

  setHasNoLobbyists() {
    let lobbyistTotal = (<FormArray>(
      this.form.get("lobbyists.lobbyists")
    )).controls.filter((lobbyist) => {
      return (
        lobbyist.get("forDeletion").value !== true &&
        lobbyist.get("submissionReason").value !== this.FOR_DEREGISTRATION
      );
    }).length;

    if (lobbyistTotal === 0) {
      this.form.get("lobbyists.hasNoLobs").enable();
    } else {
      this.form.get("lobbyists.hasNoLobs").patchValue(false);
      this.form.get("lobbyists.hasNoLobs").disable();
    }
  }

  deleteNewRecord(currentForm: AbstractControl) {
    var id: string;
    var itemCollection: FormArray;
    if (currentForm.get("id")) {
      id = currentForm.get("id").value;
      itemCollection = <FormArray>currentForm.parent;
    } else {
      id = currentForm.parent.get("id").value;
      itemCollection = <FormArray>currentForm.parent.parent;
    }

    if (id.startsWith(this.emptyGuid)) {
      // unsaved record, remove from collection
      itemCollection.removeAt(
        itemCollection.controls.findIndex((i) => i.get("id").value == id)
      );
      this.setSubstep(this.currentStep.substeps[0]);
    }
  }

  onResetForm() {
    this.modalService
      .open(this.modalReset, {
        centered: true,
        backdrop: "static",
        keyboard: false,
      })
      .result.then(
        (currentForm: FormGroup) => {
          this.deleteNewRecord(currentForm);
        },
        () => {
          // do nothing on 'take me back'
        }
      );
  }

  isEditableScreen() {
    return (
      this.moreSubsteps || this.currentStep.title == "Organisation details"
    );
  }

  isFirstStep(): boolean {
    var result =
      ["Review & submit"].indexOf(this.currentStep.title) != -1 ||
      (this.currentSubstep &&
        [
          RegistrationSubsteps["Business details"],
          RegistrationSubsteps["Owners list"],
          RegistrationSubsteps["Lobbyists list"],
          RegistrationSubsteps["Clients list"],
          RegistrationSubsteps["Officers list"],
        ].indexOf(this.currentSubstep.name as RegistrationSubsteps) != -1);

    return result;
  }

  resendActivationEmail(responsibleOfficer: FormGroup) {
    this.modalService.open(this.modalSaving, {
      centered: true,
      backdrop: "static",
      keyboard: false,
    });

    let officerData = responsibleOfficer.value;

    this.registrationFormService
      .resendActivationEmail(officerData)
      .pipe(
        tap((officer) => {
          this.modalService.dismissAll();

          if (officer.accountActivationStatus == null) {
            var officerForm = (<FormArray>(
              this.form.get("responsibleOfficers.responsibleOfficers")
            )).controls.find((r) => r.get("id").value == officer.id);
            officerForm.disable();
            officerForm.patchValue(officer);
            officerForm.enable();
            officerForm.markAsPristine({ onlySelf: true });
          } else {
            this.modalService.open(this.modalResendError, {
              centered: true,
              backdrop: "static",
              keyboard: false,
            });
          }
        }),
        catchError((err) => {
          console.log(`DEBUGLOG: Error when resending email activation - ${err}`)
          this.modalService.dismissAll();

          this.isSaving = false;
          this.form.enable();

          return Observable.throwError(err);
        })
      )
      .subscribe();
  }

  // MSAL - Logout User
  public logoutUser() {
    this.authService.logout();
  }
}

//  Test
