import { ActionReducer, Action } from "@ngrx/store";
import { IMyProfileStatus } from "./../models";

const initialState: IMyProfileStatus = {
  isOrganisationSetup: true,
  isOrganisationReviewed: true,
  isInitialised: false,
  companyName: "",
  tradingName: "",
  abn: "",
  altAbnType: null,
  altAbn: null,
  registeredOn: null,
  status: "",
  isSuspended: false,
};

export const MYPROFILESTATUSCHANGED = "MYPROFILESTATUSCHANGED";

export function reducer(
  state: IMyProfileStatus = initialState,
  action: UnsafeAction
) {
  switch (action.type) {
    case MYPROFILESTATUSCHANGED:
      state = <IMyProfileStatus>action.payload;
      return state;
    default:
      return state;
  }
}

export class UnsafeAction implements Action {
  payload?: any;
  type: string;
}
