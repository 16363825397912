import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService } from './services/auth.service'

import { AuthGuard } from './gaurds/auth.gaurd'

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        AuthService,
        AuthGuard
    ],
    declarations: [
    ]
})
export class IdentityModule { }
