import { Component, OnInit, Input, Output, ElementRef, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs/Rx';

@Component({
    selector: 'app-text-input',
    templateUrl: './text-input.component.html',
    styleUrls: ['./text-input.component.scss']
})
export class TextInputComponent implements OnInit {
    @Input() placeholder: string;

    @Input() delay: number = 300;

    @Output() value: EventEmitter<string> = new EventEmitter<string>();

    public inputValue: string;

    constructor(private elementRef: ElementRef) {
        const eventStream = Observable.fromEvent(elementRef.nativeElement, 'keyup')
            .map(() => this.inputValue)
            .debounceTime(this.delay)
            .distinctUntilChanged();

        eventStream.subscribe(input => this.value.emit(input));
    }

    ngOnInit() {}
}
